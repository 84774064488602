//styles
import styles from "./feature-store.module.css"

//react
import { useState } from "react"

//redux
import { useAppDispatch } from "../../redux-store/hooks"
import { setShowBook } from "../../redux-store/feature-store/feature-store-slice"

//antd
import { Card, Col, Row, Spin } from "antd"

//components
import BookListDrawer from "../../components/feature-store/book-list-drawer"

import { providerList } from "./providers-data"

//services

const { Meta } = Card

const FeatureStore = () => {
	const dispatch = useAppDispatch()

	//states
	const [loading, setLoading] = useState<boolean>(false)

	const cardCover = ({
		providerName,
		providerImg,
		providerActive,
	}: {
		providerName: string
		providerImg: string | null
		providerActive: boolean
	}) => {
		let opacity: number = 1.0
		let color: string = ""
		if (!providerActive) {
			opacity = 0.5
			color = "#666"
		}
		if (providerImg) {
			return (
				<div
					className={styles.card_cover_img}
					style={{ opacity: opacity, backgroundColor: color }}
				>
					<img alt="" src={providerImg} />
				</div>
			)
		} else {
			return (
				<div
					className={styles.card_cover_no_img}
					style={{ opacity: opacity, backgroundColor: color }}
				>
					{providerName}
				</div>
			)
		}
	}

	return (
		<div className={`container-page container-column ${styles.container}`}>
			<Spin tip="Loading..." spinning={loading} size="large">
				<h5>Feature Store</h5>
				<p>
					Explore the different data providers, select your favorites features
					and start create amazing policy rules!{" "}
				</p>

				<Row gutter={[50, 50]}>
					{providerList?.map((prov) => {
						let opacity: number = 1.0
						if (!prov.active) opacity = 0.7
						return (
							<Col key={prov.providerId}>
								<Card
									style={{ opacity: opacity }}
									onClick={() =>
										dispatch(
											setShowBook({
												show: true,
												providerId: prov.providerId,
												providerName: prov.providerName,
												providerDescription: prov.providerLongDescription,
												providerActive: prov.active,
											})
										)
									}
									hoverable={true}
									size="small"
									cover={cardCover({
										providerName: prov.providerName,
										providerImg: prov.img,
										providerActive: prov.active,
									})}
								>
									<Meta description={prov.providerDescription} />
								</Card>
							</Col>
						)
					})}
				</Row>
			</Spin>
			<>
				<BookListDrawer />
			</>
		</div>
	)
}

export default FeatureStore
