//css
import styles from "./policy-group.module.css"

//react router dom
import { useNavigate } from "react-router-dom"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setActiveStep } from "../../redux-store/new-policy-reducer/active-step-slice"
import {
  submmitNewPolicyInitialData,
  submmitGroupName,
  submmitGroupDescription,
} from "../../redux-store/new-policy-reducer/new-policy-slice"

//antd
import { Input, Button, Form, Space, Select } from "antd"

const selectItemsFinalNode: { value: string; label: string }[] = [
  { value: "status", label: "Status" },
  { value: "value", label: "Value" },
]

type Props = {
  componentId: number
}
const { TextArea } = Input

const uuid = require("uuid")

const PolicyGroup = ({ componentId }: Props) => {
  const dispatch = useAppDispatch()
  const {
    policyName,
    description,
    policyGroupName,
    policyGroupDescription,
    policyGroupFinalType,
    policy_record_id,
    policy_id,
    created_by,
    created_at,
    policy_status,
  } = useAppSelector((state) => state.newPolicy)
  const { user } = useAppSelector((state) => state.auth)

  let navigate = useNavigate()

  const onFinish = ({
    policyGroupName,
    policyGroupDescription,
    policyGroupFinalType,
  }: {
    policyGroupName: string
    policyGroupDescription: string
    policyGroupFinalType: string
  }) => {
    dispatch(setActiveStep(componentId + 1))
    dispatch(
      submmitNewPolicyInitialData({
        policyName: policyName,
        description: description,
        policyRecordId: policy_record_id ?? String(uuid.v1()),
        policyId: policy_id ?? String(uuid.v1()),
        createdBy: created_by ?? user?.email!,
        createdAt: created_at ??  new Date().toLocaleString(),
        policyStatus: policy_status ?? "pending approval",
        policyGroupName: policyGroupName,
        policyGroupDescription: policyGroupDescription,
        policyGroupFinalType: policyGroupFinalType,
      })
    )
  }

  return (
    <div className={styles.container_form}>

      <Form
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        initialValues={{
          policyGroupName: policyGroupName,
          policyGroupDescription: policyGroupDescription,
          policyGroupFinalType: policyGroupFinalType,
        }}
      >
        <div className={styles.divider}>
          <Form.Item
            label="Group name"
            name="policyGroupName"
            tooltip="Name used to identify your policy group in the endpoint url. Please use a short name without spaces and special characters."
            rules={[
              {
                required: true,
                message: "Please insert a policy group name",
              },
            ]}
          >
            <Input
              placeholder="Insert a short name for your policy group here"
              size="large"
              onChange={(e) => {
                dispatch(submmitGroupName({ policyGroupName: e.target.value }))
              }}
            />
          </Form.Item>

          <Form.Item
            label="Group description"
            name="policyGroupDescription"
            rules={[
              {
                required: true,
                message: "Please input a policy group description",
              },
            ]}
          >
            <TextArea
              size="large"
              autoSize={true}
              placeholder="Insert a description for your policy group here"
              onChange={(e) => {
                dispatch(
                  submmitGroupDescription({
                    policyGroupDescription: e.target.value,
                  })
                )
              }}
            />
          </Form.Item>

          <Form.Item
            label="Decision type"
            name="policyGroupFinalType"
            rules={[
              {
                required: true,
                message: "Please select an decision type",
              },
            ]}
          >
            <Select
              className="nodrag"
              placeholder="Select"
              size="large"
              options={selectItemsFinalNode}
            />
          </Form.Item>
        </div>

        <div className={styles.container_button}>
          <Space size="large">
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit">
                Continue
              </Button>
            </Form.Item>

            <Form.Item>
              <Button size="large" onClick={() => navigate("/policies")}>
                Back to Policies
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default PolicyGroup
