import { INodeProps, INodeRule } from "../../types"

type Props = {
  nodesProps: INodeProps
  rules: { [nodeId: string]: INodeRule }
}
export const checkPolicyOnProcess = ({ nodesProps, rules }: Props) => {
  //ckeck the existence of all final nodes
  let qtdError: number = 0

  for (let key in nodesProps) {
    if (
      !nodesProps[key].children1Id &&
      !rules[nodesProps[key].arrayId].isfinalNode
    ) {
      qtdError += 1
    }
  }
  if (qtdError === 0) {
    return { status: true, qtdError: 0 }
  } else {
    return { status: false, qtdError: qtdError }
  }
}
