import { Storage } from "aws-amplify"

export async function putFile(
  filename: string,
  file: any,
  setProgress: React.Dispatch<React.SetStateAction<any>>
) {
  const result = await Storage.put(filename, file, {
    contentType: file.type,
    progressCallback(progress) {
      const perc: number = Math.floor((progress.loaded / progress.total) * 100)
      setProgress(perc)
    },
  })

  return result
}

export async function removeFile(filename: string) {
  const result = await Storage.remove(filename)
  return result
}
