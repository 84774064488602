//amplify
import { API } from "aws-amplify"

export const getDatasetById = async (id: string) => {
  try {
    const response = await API.get("datasetAPI", `/dataset/object/${id}`, {})
    const responseBody = JSON.parse(response.body)
    return responseBody
  } catch (error: any) {
    return error.message
  }
}
