import { Storage } from "aws-amplify"
import { storagePath } from "."

export const getFile = async (filePathFull: string) => {
  const filePath: string = filePathFull.replace(storagePath, "")

  try {
    const result = await Storage.get(filePath)
    return result
  } catch (error: unknown) {
    if (error instanceof Error) {
      const saveError = new Error(error.message)
      console.log("Fail to create download url:", saveError)
    }
  }
}
