//css
import styles from "./progress-panel.module.css"

//antd
import { Button, Space, Progress } from "antd"

type Props = {
	title: string
	content: string
	percent: number
	action: React.MouseEventHandler
}

const ProgressPanel = ({ title, content, percent, action }: Props) => {
	return (
		<Space>
			<h6>{title}</h6>
			<Button
				type="primary"
				ghost
				size="large"
				className={styles.progress_btn_content}
				onClick={action}
			>
				<Space>
					<Progress type="circle" percent={percent} size={19} strokeWidth={8} />
					<p>{content}</p>
				</Space>
			</Button>
		</Space>
	)
}

export default ProgressPanel
