import Lambda from "aws-sdk/clients/lambda"
import { authServer } from "../../authentication"
import { bucketName } from "../storage-service"
import { API } from "aws-amplify"
import { featuresDataType } from "../../types"

export const getDatasetPropsFromS3 = async ({
  fileName,
}: {
  fileName: string
}) => {
  try {
    const payload = { fileName: fileName, bucketName: bucketName }

    const result = await API.post("datasetAPI", "/getDatasetProperties", {
      body: payload,
    })

    const featureList: featuresDataType[] = []

    for (let i = 0; i < result.features.length; i++) {
      featureList.push({
        key: i,
        feature: result.features[i].feature,
        provider: result.features[i].provider,
        providerLabel: result.features[i].provider,
        sourceType: null,
        dataType: null,
        allowedValues: null,
      })
    }

    return {
      size: result.size,
      records: result.records,
      sample: result.sample,
      features: featureList,
      error: null,
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      const saveError = new Error(error.message)
      console.log(saveError)
      return {
        size: null,
        records: null,
        sample: null,
        features: [
          {
            key: 0,
            feature: null,
            provider: null,
          },
        ],
        error: saveError,
      }
    }
  }
}
