//reactflow
import { Edge, Node } from "reactflow"

//types
import { IAuthModel } from "../authentication/authentication-types"
import { INodeRule } from "./"

// auth reducer
export interface IAuthState {
  isAuthenticated: boolean
  message: string | null
  status: StoreStatus
  user: IAuthModel | null
}

//store status
export enum StoreStatus {
  Idle = "Idle",
  Loading = "Loading",
  Failed = "Failed",
  Succeeded = "Succeeded",
}

//active step
export type IActiveStep = {
  activeStep: {
    id: number
  }
  isPolicySaved: boolean
}

export type IDataSelected = {
  [sourceId: string]: {
    sourceStatus: boolean
    featureStatus: boolean
    type: string
  }
}

export type ICascaderFeatureList = {
  key: string
  label: string
  value: string
  children: {
    key: string
    value: string
    label: string
    providerValue: string | null
  }[]
}[]

export type ISelectedFeature = {
  name: string | null
  dataType: "STRING" | "NUMBER" | null
  allowedValues: string | null
  source: string | null
}

export type IDataSource = [
  { datasetSourceCheck: boolean; datasetFeatureCheck: boolean },
  { providerSourceCheck: boolean; providerFeatureCheck: boolean },
  { databaseSourceCheck: boolean; databaseFeatureCheck: boolean },
  { customSourceCheck: boolean; customFeatureCheck: boolean },
  { payloadSourceCheck: boolean; payloadFeatureCheck: boolean },
  string[]
]

export type IPayloadIn = {
  cascaderPayloadList: {
    key: string
    label: string
    value: string
    children: {
      key: string
      value: string
      label: string
      providerValue: string | null
    }[]
  }[]
  requestPayload: {
    field: string | null
    description: string | null
    dataType: string | null
    allowedValues: string
  }[]
}

export type IPayloadOut = string[]

export type INewPolicy = {
  policy_id: string | null
  created_at: string | null
  created_by: string | null
  policy_status: string | null
  policy_record_id: string | null
  policyName: string | null
  policyGroupName: string | null
  policyGroupDescription: string | null
  policyGroupFinalType: "status" | "value" | null
  payloadIn: IPayloadIn
  payloadOut: IPayloadOut
  description: string | null
  datasetName: string | null
  datasetId: string | null
  datasetPath: string | null
  metabasePath: string | null
  dataSource: IDataSource
  dataSelected: IDataSelected
  datasetDecisionPath: string | null
  cascaderFeatureList: ICascaderFeatureList
  sample: [string[]] | null
  selectedFeature: ISelectedFeature
  rules: { [nodeId: string]: INodeRule }
}

export interface INodeProps {
  [id: string]: {
    arrayId: string
    parentId: string | null
    children1Id: string | null
    children2Id: string | null
    positionX: number | null
    positionY: number | null
    nodeSide: number
    nodeLevel: number
  }
}

//nodes
export interface INodes {
  nodes: Node[]
  edges: Edge[]
  nodesProps: INodeProps
}

//feature store
export interface IFeatureStoreState {
  showBook: {
    show: boolean
    providerId: string | null
    providerName: string | null
    providerDescription: string | null
    providerActive: boolean
  }
  showFeature: {
    show: boolean
    bookId: string | null
    bookName: string | null
    bookActive: boolean
  }
  userFeatureList: {
    key: string
    label: string
    name: string
    description: string
    favorite: boolean
  }[]
}
