//css
import styles from "./choose-features.module.css"

//react
import { useEffect, useState } from "react"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setActiveStep } from "../../redux-store/new-policy-reducer/active-step-slice"
import { submitDataset } from "../../redux-store/new-policy-reducer/new-policy-slice"

//antd
import { Button, Divider, Space, Spin } from "antd"

//components
import ChooseCustomFeature from "./choose-custom-features"
import ChooseProvider from "./choose-providers"
import ChooseDatabase from "./choose-database"

//types
import { ouzeProvider } from "../../Ouze/provider-features"

type Props = {
  componentId: number
}

const ChooseFeatures = ({ componentId }: Props) => {
  const dispatch = useAppDispatch()

  const { dataSource, dataSelected, payloadIn } = useAppSelector(
    (state) => state.newPolicy
  )

  const [loading, setLoading] = useState<boolean>(false)

  const [databaseList, setDatabaseList] = useState<any[]>([])
  const [customFeatureList, setCustomFeatureList] = useState<any[]>([])
  const [providerList, setProviderList] = useState<any[]>([])

  //get dataset list
  const getData = async () => {
    setLoading(true)
    try {
      for (let i in ouzeProvider) {
        if (ouzeProvider[i].dataType === "provider") {
          setProviderList(ouzeProvider[i].data)
        } else if (ouzeProvider[i].dataType === "custom") {
          setCustomFeatureList(ouzeProvider[i].data)
        } else if (ouzeProvider[i].dataType === "database") {
          setDatabaseList(ouzeProvider[i].data)
        }
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const createFeatureList = () => {
    const tmpFeatureList = []

    if (dataSource[4].payloadSourceCheck) {
      tmpFeatureList.push(payloadIn.cascaderPayloadList[0])
    }
    //console.log(payloadIn.cascaderPayloadList)

    for (let i in dataSelected) {
      if (dataSelected[i].sourceStatus) {
        let index = providerList.findIndex((object: any) => {
          return object.Id === i
        })
        if (providerList[index])
          tmpFeatureList.push({
            key: providerList[index].Id,
            label: providerList[index].description.label,
            value: providerList[index].description.value,
            children: providerList[index].description.children,
          })

        index = databaseList.findIndex((object: any) => {
          return object.Id === i
        })
        if (databaseList[index])
          tmpFeatureList.push({
            key: databaseList[index].Id,
            label: databaseList[index].description.label,
            value: databaseList[index].description.value,
            children: databaseList[index].description.children,
          })

        index = customFeatureList.findIndex((object: any) => {
          return object.Id === i
        })
        if (customFeatureList[index])
          tmpFeatureList.push({
            key: customFeatureList[index].Id,
            label: customFeatureList[index].description.label,
            value: customFeatureList[index].description.value,
            children: customFeatureList[index].description.children,
          })
      }
    }

    dispatch(
      submitDataset({
        datasetName: null,
        datasetId: null,
        datasetPath: null,
        cascaderFeatureList: tmpFeatureList,
        sample: null,
      })
    )
    dispatch(setActiveStep(componentId + 1))
  }

  return (
    <div className={styles.container_form}>
      <Spin size="large" tip="Loading..." spinning={loading}>
        {dataSource[1].providerSourceCheck && (
          <div className={styles.divider}>
            <Divider orientation="left" orientationMargin="0">
              <h6>Data Connections</h6>
            </Divider>
            <ChooseProvider data={providerList} />
          </div>
        )}
        {dataSource[2].databaseSourceCheck && (
          <div className={styles.divider}>
            <Divider orientation="left" orientationMargin="0">
              <h6>Database</h6>
            </Divider>
            <ChooseDatabase data={databaseList} />
          </div>
        )}
        {dataSource[3].customSourceCheck && (
          <div className={styles.divider}>
            <Divider orientation="left" orientationMargin="0">
              <h6>Custom Features</h6>
            </Divider>
            <ChooseCustomFeature data={customFeatureList} />
          </div>
        )}

        <div className={styles.container_button}>
          <Space size="large">
            <Button size="large" type="primary" onClick={createFeatureList}>
              Continue
            </Button>

            <Button
              size="large"
              onClick={() => dispatch(setActiveStep(componentId - 1))}
            >
              Back
            </Button>
          </Space>
        </div>
      </Spin>
    </div>
  )
}

export default ChooseFeatures
