//css
import styles from "./favorite-feature-table.module.css"

//react
import { useState } from "react"

//redux
import { setShowFeature } from "../../redux-store/feature-store/feature-store-slice"
import { useAppDispatch } from "../../redux-store/hooks"

//antd
import { Button, Col, Input, Row, Table, Tag } from "antd"
import type { ColumnsType } from "antd/es/table"

//types

//components
import FeatureListDrawer from "../feature-store/feature-list-drawer"

export type featureList = {
  key: string
  name: string
  description: string
}

type Props = {
  scrollBody: any
  styleProps: { searchWidth: string }
  featureList: featureList[]
  bookId: string
  bookName: string
}
const { Search } = Input

const FavoriteFeatureTable = ({
  scrollBody,
  styleProps,
  featureList,
  bookId,
  bookName,
}: Props) => {
  let dataSource = featureList

  const dispatch = useAppDispatch()

  const [searchedDescription, setSearchedDescription] = useState<string>("")

  const columns: ColumnsType<featureList> = [
    {
      title: "Favorite Features",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      filteredValue: [searchedDescription],
      onFilter: (value: string | number | boolean, record: featureList) =>
        String(record.description)
          .toLowerCase()
          .includes(String(value).toLowerCase()),
    },
  ]

  const editFavorites = () => {
    //dispatch(setUserFeatureList(featureList))

    dispatch(
      setShowFeature({
        show: true,
        bookId: bookId,
        bookName: bookName,
        bookActive: true,
      })
    )
  }

  return (
    <div className={styles.feature_table_container}>
      <div className={styles.button_container}>
        <Row>
          <Col flex={3} className={styles.container_normal}>
            <Search
              className={styles.feature_search_bar}
              placeholder="Search feature description"
              allowClear
              onSearch={(value) => setSearchedDescription(value)}
              //size="large"
              style={{ width: styleProps.searchWidth }}
            />
          </Col>
          <Col flex={1} className={styles.container_reverse}>
            <Button type="primary" onClick={editFavorites}>
              {" "}
              Edit Favorites
            </Button>
          </Col>
        </Row>
      </div>

      <Table
        dataSource={dataSource}
        columns={columns}
        bordered
        size="small"
        scroll={scrollBody}
        pagination={
          dataSource.length > 10
            ? {
                pageSize: 10,
              }
            : false
        }
      />
      <>
        <FeatureListDrawer />
      </>
    </div>
  )
}

export default FavoriteFeatureTable
