//styles
import styles from "./navbar.module.css"

//react
import { useState } from "react"

//router
import { useNavigate } from "react-router-dom"

//antdesign
import { Button, Menu } from "antd"
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import { getItem } from "../../utils/create-menu-items"

type Props = {
	logo: string
	logoIcon: String
	navItems: { label: string; key: string; icon: JSX.Element }[]
}

const Navbar = ({ navItems, logo, logoIcon }: Props) => {
	let navigate = useNavigate()

	const items = [
		navItems.map((it) =>
			getItem(it.label, it.key, it.icon, () => {
				navigate(`${it.key}`)
			})
		),
	]

	const [collapsed, setCollapsed] = useState(false)

	const toggleCollapsed = () => {
		setCollapsed(!collapsed)
	}

	return (
		<div className={styles.navbar}>
			{/* Collapsed button */}
			<Button
				type="primary"
				onClick={toggleCollapsed}
				style={{
					borderRadius: "2px",
					height: "35px",
					width: "39px",
					padding: 0,
					margin: "15px",
				}}
			>
				{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
			</Button>

			{/*Logo */}
			<div
				className={
					collapsed ? styles.navbar_logo_collapsed : styles.navbar_logo
				}
			>
				{collapsed ? (
					<img src={require(`../../${logoIcon}`)} alt="logo_white_icon" />
				) : (
					<>
						<img src={require(`../../${logo}`)} alt="logo_white" />
					</>
				)}
			</div>
			<Menu
				className={
					collapsed ? styles.navbar_menu_collapsed : styles.navbar_menu
				}
				theme="dark"
				defaultSelectedKeys={["1"]}
				mode="inline"
				inlineCollapsed={collapsed}
				items={items[0]}
			/>
		</div>
	)
}

export default Navbar
