//react
import React from "react"

//react hooks
import { useEffect } from "react"

//router-dom
import { Route, Routes } from "react-router-dom"

//Amplify
import { withAuthenticator } from "@aws-amplify/ui-react"

//redux
import type {} from "redux-thunk/extend-redux"
import { useAppDispatch } from "./redux-store/hooks"
import { refreshToken } from "./redux-store/auth-reducer/authentication-thunks"

//antd
import { Layout } from "antd"
import {
	HomeOutlined,
	ApartmentOutlined,
	FolderOpenOutlined,
	ShareAltOutlined,
	ShopOutlined,
} from "@ant-design/icons"

//components
import Navbar from "./components/navbar/navbar"
import PageHeader from "./components/page-header/page-header"

//pages
import Home from "./pages/home/home"
import Policy from "./pages/policy/policy"
import NewPolicy from "./pages/new-policy/new-policy"
import Dataset from "./pages/datasets/dataset"
import DatasetSetup from "./pages/datasets/dataset-setup"
import DatasetPreview from "./pages/datasets/dataset-preview"
import Connection from "./pages/connections/connection"

//product specification
import {
	productName,
	logoWhite,
	logoWhiteIcon,
} from "./product-definition/product-detais"
import FeatureStore from "./pages/feature-store/feature-store"
import PolicyEdit from "./pages/policy/policy-edit"
import PolicyBacktest from "./pages/policy/policy-backtest"

const { Content, Footer } = Layout

const navItems: { label: string; key: string; icon: JSX.Element }[] = [
	{ label: "Home", key: "/", icon: <HomeOutlined /> },
	{ label: "Datasets", key: "/datasets", icon: <FolderOpenOutlined /> },
	{ label: "Policies", key: "/policies", icon: <ApartmentOutlined /> },
	{ label: "Connections", key: "/connections", icon: <ShareAltOutlined /> },
	{ label: "Feature Store", key: "/feature-store", icon: <ShopOutlined /> },
]

function App() {
	const dispatch = useAppDispatch()

	// Refresh authenticated user session
	useEffect(() => {
		dispatch(refreshToken())
	}, [dispatch])

	return (
		<div className="App">
			<div>
				<Navbar navItems={navItems} logo={logoWhite} logoIcon={logoWhiteIcon} />
			</div>

			<div className="container">
				<Layout className="layout">
					<PageHeader />

					{/* Pages */}
					<Content>
						<Routes>
							{/* Pages that can only be show afert user authentication */}
							<Route path="/" element={<Home />} />

							<Route path="/policies" element={<Policy />} />
							<Route path="/policy-edit/:id" element={<PolicyEdit />} />
							<Route path="/new-policy" element={<NewPolicy />} />
							<Route path="/datasets" element={<Dataset />} />
							<Route path="/dataset-setup/:id" element={<DatasetSetup />} />
							<Route path="/dataset-preview/:id" element={<DatasetPreview />} />
							<Route path="/connections" element={<Connection />} />
							<Route path="/feature-store" element={<FeatureStore />} />
							<Route path="/policy-backtest/:id" element={<PolicyBacktest />} />
						</Routes>
					</Content>

					{/* Footer - Specific style for login page */}
					<Footer className="footer">
						&copy;
						{`${productName} - All rights reserved - 2023 - POWER OF DATA`}
					</Footer>
				</Layout>
			</div>
		</div>
	)
}

export default withAuthenticator(App)
