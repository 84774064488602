// cognito
import { Auth } from "aws-amplify"

//types
import { ICognitoUserSession } from "./authentication-server-types"
import { IAccessCredentialsModel, IAuthModel } from "./authentication-types"

//logout user
const logout = async () => {
	return Auth.signOut()
}

// get current authenticated user
const currentAuthUser = async () => {
	const cognitoUser: ICognitoUserSession = await Auth.currentAuthenticatedUser()
		
	//adjust response to authentication model
	const response: IAuthModel = {
		email: cognitoUser?.attributes.email,
		userName: cognitoUser?.username,
		userGroup: null,
		userToken: cognitoUser?.signInUserSession?.idToken.jwtToken,
	}

	return response
}

// get credentials
const getCurrentCredentials = async () => {
		
	// access credentials
	const credentials = await Auth.currentCredentials()
	const essentialCredentials = await Auth.essentialCredentials(credentials)

	//adjust response to authentication model
	const response: IAccessCredentialsModel = essentialCredentials

	return response
}


export const authServer = {
	logout,
	currentAuthUser,
	getCurrentCredentials,
}

