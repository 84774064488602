//css
import styles from "./choose-features.module.css"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { selectedDataSource } from "../../redux-store/new-policy-reducer/new-policy-slice"

//antd
import { Button, Col, Row, Tooltip } from "antd"

const ChooseProvider = ({ data }: { data: any[] }) => {
  const dispatch = useAppDispatch()

  const { dataSelected } = useAppSelector(
    (state) => state.newPolicy
  )

  return (
    <Row gutter={[25, 25]} className={styles.card_container}>
      {data?.map((prov) => {
        return (
          <Col key={prov.Id}>
            <Tooltip title={prov.description.description}>
              <Button
                className={styles.card}
                onClick={() => {
                  let sourceStatus = true
                  let featureStatus = false
                  if (dataSelected[prov.Id]) {
                    sourceStatus = !dataSelected[prov.Id].sourceStatus
                    featureStatus = dataSelected[prov.Id].featureStatus
                  }
                  dispatch(
                    selectedDataSource({
                      id: prov.Id,
                      selected: {
                        sourceStatus: sourceStatus,
                        featureStatus: featureStatus,
                        type: prov.type,
                      },
                    })
                  )
                }}
                size="large"
                type={
                  dataSelected[prov.Id] && dataSelected[prov.Id].sourceStatus
                    ? "primary"
                    : "default"
                }
                disabled={(dataSelected[prov.Id] && dataSelected[prov.Id].featureStatus) ? true : false}
              >
                {prov.description.label}
              </Button>
            </Tooltip>
          </Col>
        )
      })}
    </Row>
  )
}

export default ChooseProvider
