//types
import { providerFeatureList } from "../../types"

export const checkDatasetFeatureList = ({
  providerFeatureListSelected,
  providerFeatureListAvailable,
}: {
  providerFeatureListSelected: providerFeatureList[]
  providerFeatureListAvailable: providerFeatureList[]
}) => {
  let colSelected: string[] = []
  let colAvailable: string[] = []

  for (let i in providerFeatureListSelected) {
    for (let j in providerFeatureListSelected[i].children) {
      colSelected.push(
        providerFeatureListSelected[i].value +
          " " +
          providerFeatureListSelected[i].children[j].value
      )
    }
  }

  for (let i in providerFeatureListAvailable) {
    for (let j in providerFeatureListAvailable[i].children) {
      colAvailable.push(
        providerFeatureListAvailable[i].value +
          " " +
          providerFeatureListAvailable[i].children[j].value
      )
    }
  }

  const qtdFeatures: number = colSelected.length
  let qtdAvailableFeatures: number = 0

  for (let key in colSelected) {
    if (colAvailable.includes(colSelected[key])) qtdAvailableFeatures += 1
  }

  if (qtdAvailableFeatures === qtdFeatures) {
    return true
  } else {
    return false
  }
}
