//antd
import { Table, Tag } from "antd"
import type { ColumnsType } from "antd/es/table"

type Props = {
  scrollBody: any
  featureProviderList: any
}

const FeatureProviderTable = ({ scrollBody, featureProviderList }: Props) => {
  let dataSource = featureProviderList

  const columns: ColumnsType<{
    name: string | null
    features: string[]
  }> = [
    {
      title: "Provider",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Features",
      key: "features",
      dataIndex: "features",
      render: (_, { features }) => (
        <>
          {features?.map((feat) => {
            let color = "geekblue"
            if (feat) {
              return (
                <Tag
                  style={{ marginTop: "2px", marginBottom: "2px" }}
                  color={color}
                  key={feat}
                >
                  {feat.toUpperCase()}
                </Tag>
              )
            }
          })}
        </>
      ),
    },
  ]

  return (
    <>
      {dataSource.length > 0 && (
        <Table
          dataSource={dataSource}
          columns={columns}
          bordered
          size="small"
          scroll={scrollBody}
          pagination={
            dataSource.length > 5
              ? {
                  pageSize: 5,
                }
              : false
          }
          rowKey={(record) => record.name!}
        />
      )}
    </>
  )
}

export default FeatureProviderTable
