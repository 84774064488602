//css
import styles from "./user-drop-down.module.css"

//product specification
import { productName } from "../../../product-definition/product-detais"

//redux - auth reducer
import { useAppDispatch, useAppSelector } from "../../../redux-store/hooks"
import { logout } from "../../../redux-store/auth-reducer/authentication-thunks"

//ant
import { Button, Dropdown, Space, Modal, message } from "antd"
import {
	DownOutlined,
	LogoutOutlined,
	ExclamationCircleFilled,
	LockOutlined,
	UserOutlined,
} from "@ant-design/icons"

import type { MenuProps } from "antd"

const UserDropDown = () => {
	//user information
	const { user } = useAppSelector((state) => state.auth)

	const index: number | null | undefined = user?.email?.indexOf("@")
	const username: string | null | undefined = user?.email?.substring(0, index)
	const initialLetter: string | null | undefined = user?.email
		?.substring(0, 1)
		.toUpperCase()

	//sigout - Modal
	const dispatch = useAppDispatch()
	const signOut = () => {
		Modal.confirm({
			title: `You are about to leave ${productName}`,
			icon: <ExclamationCircleFilled />,
			content: (
				<p>
					{" "}
					By choosing <span>Yes</span>, you will leave the system. <br /> <br />{" "}
					Do you want to proceede?
				</p>
			),
			okText: "Yes",
			cancelText: "No",
			onOk: () => dispatch(logout()),
			//signOut,
			onCancel: () => console.log("Cancel"),
			cancelButtonProps: { className: "modal-cancelBtn" },
			okButtonProps: { className: "modal-okBtn" },
		})
	}

	// menu items
	const items: MenuProps["items"] = [
		{
			label: "Profile",
			key: "1",
			icon: <UserOutlined />,
			onClick: (e) => message.warning("Feature not available yet"),
		},
		{
			label: "Change Password",
			key: "2",
			icon: <LockOutlined />,
			onClick: (e) => message.warning("Feature not available yet"),
		},
		{
			label: "Log Out",
			key: "3",
			icon: <LogoutOutlined />,
			onClick: signOut,
		},
	]

	return (
		<div>
			<Dropdown
				className={styles.button_container}
				menu={{ items }}
				trigger={["click"]}
			>
				<Button>
					<Space>
						<div className={styles.button_content}>
							<p>
								Welcome, <br />
								<span>{username}</span>
							</p>
						</div>
						<div className={styles.circle}>
							<p> {initialLetter} </p>
						</div>
						<DownOutlined />
					</Space>
				</Button>
			</Dropdown>
		</div>
	)
}

export default UserDropDown
