//Amplify
import { API } from "aws-amplify"

//type
import {
  policyRuleBody,
  dataProviders,
  policyJson,
  INodeProps,
  IDataSelected,
  IPayloadIn,
  IDataSource,
  IPayloadOut,
  INodeRule,
} from "../../types"

//utils & functions
import { checkPolicyOnSave } from "."
import {
  getDatasetById,
  putDataset,
} from "../../services/dataset-database-service"

let alertType:
  | "errorDefault"
  | "errorIncomplete"
  | "errorFinalNode"
  | "successSave"
  | "successProccess" = "errorDefault"
let attribute: string | null = null
let isSuccess: boolean = false

type Props = {
  user: string
  policyId: string
  policyName: string
  description: string
  datasetName: string
  datasetPath: string
  datasetId: string
  createdAt: string
  createdBy: string
  policyStatus: string
  policyRecordId: string
  metabasePath: string
  datasetDecisionPath: string
  nodesProps: INodeProps
  rules: { [nodeId: string]: INodeRule }
  policyGroupName: string
  policyGroupDescription: string
  policyGroupFinalType: "status" | "value" | null
  payloadIn: IPayloadIn
  payloadOut: IPayloadOut
  dataSource: IDataSource
  dataSelected: IDataSelected
}

export const savePolicy = async ({
  user,
  policyId,
  policyName,
  description,
  datasetName,
  datasetPath,
  datasetId,
  createdAt,
  createdBy,
  policyStatus,
  policyRecordId,
  metabasePath,
  datasetDecisionPath,
  nodesProps,
  rules,
  policyGroupName,
  policyGroupDescription,
  policyGroupFinalType,
  payloadIn,
  payloadOut,
  dataSource,
  dataSelected,
}: Props) => {
  // check policy info
  const resultCheckPolicy: {
    checkInitialProps: boolean
    checkNodes: boolean
    checkDataSource: boolean
  } = checkPolicyOnSave({
    policyName: policyName,
    policyGroupName: policyGroupName,
    datasetId: datasetId,
    nodesProps: nodesProps,
    dataSelected: dataSelected,
  })

  if (
    resultCheckPolicy.checkInitialProps &&
    resultCheckPolicy.checkNodes &&
    resultCheckPolicy.checkDataSource
  ) {
    // get unique provider list
    const auxProvider: string[] = []
    const auxProviderLabel: string[] = []

    for (let key in rules) {
      if (
        rules[key].feature[0] &&
        !auxProvider.includes(rules[key].feature[0])
      ) {
        auxProvider.push(rules[key].feature[0])
        auxProviderLabel.push(rules[key].featureLabel[0])
      }
    }
    //build data providers:
    let providerType: string | null = null
    let providersFeatureList: dataProviders = []
    for (let iProvider in auxProvider) {
      let featureList: string[] = []
      for (let key in rules) {
        let feat: string = rules[key].feature[1]
        if (rules[key].dataSourceType === "provider") {
          feat = rules[key].feature[2]
        }

        if (
          rules[key].feature &&
          rules[key].feature[0] === auxProvider[iProvider] &&
          !featureList.includes(feat)
        ) {
          featureList.push(feat)
          providerType = rules[key].dataSourceType
        }
      }
      if (featureList.length > 0) {
        providersFeatureList.push({
          name: auxProvider[iProvider],
          type: providerType,
          label: auxProviderLabel[iProvider],
          features: featureList,
        })
      }
    }

    //get max level:
    let maxLevel: number = 0

    for (let key in nodesProps) {
      if (nodesProps[key].nodeLevel >= maxLevel) {
        maxLevel = nodesProps[key].nodeLevel
      }
    }

    //brothers per level:
    let parentLevelList: { [level: number]: string[] } = {}
    for (let iLevel = 1; iLevel <= maxLevel; iLevel++) {
      let nodesLevelList: string[] = []
      for (let key in nodesProps) {
        if (
          nodesProps[key].nodeLevel === iLevel &&
          key === nodesProps[nodesProps[key].parentId!].children1Id! &&
          !nodesLevelList.includes(key)
        ) {
          nodesLevelList.push(nodesProps[key].arrayId) //nodes per level
        }
      }
      parentLevelList[iLevel] = nodesLevelList
    }

    //write props for children pair:
    let childrenPairJson: { [parentId: string]: policyRuleBody[] } = {}

    for (let iLevel = maxLevel; iLevel > 0; iLevel--) {
      for (let ikey in parentLevelList[iLevel]) {
        const key: string = parentLevelList[iLevel][ikey]

        //pair of nodes with same parent
        //children1 props
        const keyChildren1: string = key
        let nodeType1: "final" | "normal" | "dummy" = "normal"
        if (rules[keyChildren1].isfinalNode) {
          nodeType1 = "final"
        } else if (!rules[keyChildren1].feature[0]) {
          nodeType1 = "dummy"
        }
        const finalValue1: string | null =
          rules[keyChildren1]?.decisionStatus ??
          rules[keyChildren1].decisionValue ??
          null

        //source type, provider, book, feature
        const dataSourceType1: string | null =
          rules[keyChildren1].dataSourceType
        const provider1: string | null = rules[keyChildren1].feature[0] ?? null
        let book1: string | null = null
        let feature1: string = rules[keyChildren1].feature[1] ?? null

        if (dataSourceType1 === "provider") {
          book1 = rules[keyChildren1].feature[1] ?? null
          feature1 = rules[keyChildren1].feature[2] ?? null
        }

        let outputNodes1: policyRuleBody[] = []
        if (childrenPairJson[keyChildren1]) {
          outputNodes1 = [
            childrenPairJson[keyChildren1]["0"],
            childrenPairJson[keyChildren1]["1"],
          ]
        }

        //children 2 props
        const keyChildren2: string =
          nodesProps[nodesProps[key].parentId!].children2Id! ?? null
        let nodeType2: "final" | "normal" | "dummy" = "normal"
        if (rules[keyChildren2].isfinalNode) {
          nodeType2 = "final"
        } else if (!rules[keyChildren2].feature[0]) {
          nodeType2 = "dummy"
        }
        const finalValue2: string | null =
          rules[keyChildren2]?.decisionStatus ??
          rules[keyChildren2].decisionValue ??
          null

        //source type, provider, book, feature
        const dataSourceType2: string | null =
          rules[keyChildren2].dataSourceType
        const provider2: string | null = rules[keyChildren2].feature[0] ?? null
        let book2: string | null = null
        let feature2: string = rules[keyChildren2].feature[1] ?? null

        if (dataSourceType2 === "provider") {
          book2 = rules[keyChildren2].feature[1] ?? null
          feature2 = rules[keyChildren2].feature[2] ?? null
        }

        let outputNodes2: policyRuleBody[] = []
        if (childrenPairJson[keyChildren2]) {
          outputNodes2 = [
            childrenPairJson[keyChildren2]["0"],
            childrenPairJson[keyChildren2]["1"],
          ]
        }

        childrenPairJson[nodesProps[key].parentId!] = [
          {
            //children1
            provider: provider1,
            book: book1,
            feature: feature1,
            dataSourceType: dataSourceType1,
            operator: rules[keyChildren1].operator,
            threshold: rules[keyChildren1].threshold,
            nodeType: nodeType1,
            nodeId: nodesProps[keyChildren1].arrayId,
            finalType: rules[keyChildren1].decisionType ?? null,
            finalValue: finalValue1,
            output: outputNodes1,
          },
          {
            //children2
            provider: provider2,
            book: book2,
            feature: feature2,
            dataSourceType: dataSourceType2,
            operator: rules[keyChildren2].operator,
            threshold: rules[keyChildren2].threshold,
            nodeType: nodeType2,
            nodeId: nodesProps[keyChildren2].arrayId,
            finalType: rules[keyChildren2].decisionType ?? null,
            finalValue: finalValue2,
            output: outputNodes2,
          },
        ]
      }
    }

    let nodeType0: "final" | "normal" | "dummy" = "normal"
    if (rules["N0"].isfinalNode) {
      nodeType0 = "final"
    } else if (!rules["N0"].feature[0]) {
      nodeType0 = "dummy"
    }
    const finalValue0: string | null =
      rules["N0"]?.decisionStatus ?? rules["N0"].decisionValue ?? null

    let outputNodes0: policyRuleBody[] = []
    if (childrenPairJson["N0"]) {
      outputNodes0 = [childrenPairJson["N0"]["0"], childrenPairJson["N0"]["1"]]
    }

    let writeNodeRules: { [nodeId: string]: INodeRule } = {}

    for (let i in rules) {
      if (!rules[i].isDeleted) writeNodeRules[i] = rules[i]
    }

    //source type, provider, book, feature
    const dataSourceType0: string | null = rules["N0"].dataSourceType
    const provider0: string | null = rules["N0"].feature[0] ?? null
    let book0: string | null = null
    let feature0: string = rules["N0"].feature[1] ?? null

    if (dataSourceType0 === "provider") {
      book0 = rules["N0"].feature[1] ?? null
      feature0 = rules["N0"].feature[2] ?? null
    }

    const savePolicyJson: policyJson = {
      policy_record_id: policyRecordId,
      policy_id: policyId,
      policy_group_name: policyGroupName,
      policy_group_description: policyGroupDescription,
      policy_group_final_type: policyGroupFinalType,
      created_by: createdBy,
      name: policyName,
      description: description,
      dataset: datasetName,
      datasetId: datasetId,
      datasetPath: datasetPath,
      payload: {
        input: payloadIn,
        output: payloadOut,
      },
      dataSource: {
        source: dataSource,
        selected: dataSelected,
      },
      metabasePath: metabasePath,
      datasetDecisionPath: datasetDecisionPath,
      dataProviders: providersFeatureList,
      created_at: createdAt,
      updated_at: new Date().toLocaleString(),
      updated_by: user,
      policy_status: policyStatus,
      rule: {
        provider: provider0,
        book: book0,
        feature: feature0,
        dataSourceType: dataSourceType0,
        operator: rules["N0"].operator,
        threshold: rules["N0"].threshold,
        nodeType: nodeType0,
        nodeId: "N0",
        finalType: rules["N0"].decisionType ?? null,
        finalValue: finalValue0,
        output: outputNodes0,
      },
      nodes: {
        nodeProps: nodesProps,
        nodeRules: writeNodeRules,
      },
    }
    try {
      await API.post("policiesAPI", "/policies", {
        body: savePolicyJson,
      })

      if (datasetId) {
        //get dataset data
        const datasetData = await getDatasetById(datasetId)

        //update policy list
        const policyList: { id: string; name: string }[] =
          datasetData.policies ?? []

        const policyIndex = policyList.findIndex((object: any) => {
          return object.id === policyId
        })

        if (policyIndex > -1) {
          policyList[policyIndex] = { name: policyName, id: policyId }
        } else {
          policyList.push({ name: policyName, id: policyId })
        }

        const updateDataset = {
          datasetId: datasetData.datasetId,
          datasetName: datasetData.datasetName,
          description: datasetData.description,
          featureList: datasetData.featureList,
          fileName: datasetData.fileName,
          policies: policyList,
          providerBookList: datasetData.providerBookList,
          providerFeatureList: datasetData.providerFeatureList,
          qtdRows: datasetData.qtdRows,
          sample: datasetData.sample,
          size: datasetData.size,
          storagePath: datasetData.storagePath,
          updatedAt: new Date().toLocaleString(),
          updatedBy: user,
          uploadAt: datasetData.uploadAt,
          uploadBy: datasetData.uploadBy,
        }

        await putDataset(updateDataset)
      }

      alertType = "successSave"
      attribute = policyName
      isSuccess = true
    } catch (error: unknown) {
      if (error instanceof Error) {
        const saveError = new Error(error.message)
        console.log(saveError)
      }
      alertType = "errorDefault"
      attribute = null
      isSuccess = false
    }
  } else {
    alertType = "errorIncomplete"
    attribute = null
    isSuccess = false
  }

  return {
    alertType: alertType,
    attribute: attribute,
    success: isSuccess,
  }
}
