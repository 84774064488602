//styles
import styles from "./policy.module.css"

//react
import React, { useState } from "react"

//Amplify
import { API } from "aws-amplify"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setAlert } from "../../redux-store/upload-dataset/upload-dataset-slice"

//antd
import { Button, Input, message, Form, Alert, Modal, Space } from "antd"

//services
import {
  removeFile,
  storageBacktestInputDir,
  storagePath,
} from "../../services/storage-service"
import { BacktestPolicy } from "../../services/policy-database-service/backtest-policy"

//components
import UploadDatasetBtn from "../../components/dataset/upload-btn"

//types
import { policyJson } from "../../types"

const uuid = require("uuid")
const backtestId: string = String(uuid.v1())

const { TextArea } = Input

type Props = {
  policyId: string
  setStateEdit: React.Dispatch<React.SetStateAction<any>>
}

const NewBacktest = ({ policyId, setStateEdit }: Props) => {
  const dispatch = useAppDispatch()

  const { user } = useAppSelector((state) => state.auth)

  const { alert } = useAppSelector((state) => state.uploadModal)

  const [backtestName, setBacktestName] = useState<string>("")
  const [backtestDescription, setBacktestDescription] = useState<string>("")
  const [fileName, setFileName] = useState<string>("")
  const [savedFileName, setSavedFileName] = useState<string>("")

  const [loadingFile, setLoadingFile] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const saveBacktest = async () => {
    setLoading(true)

    if (!backtestName || !backtestDescription || !savedFileName) {
      dispatch(
        setAlert({
          alertMessage: (
            <p>Please insert name, description and file before continue.</p>
          ),
          alertType: "error",
        })
      )
    } else {
      try {
        await clonePolicy()

        //process policy
        const response = await BacktestPolicy({
          policyId: backtestId,
        })

        //dispatch(updateAllNodeProps(response.nodes.nodeProps));
        setStateEdit(false)
        message.success(
          `Backtest ${fileName} was successfully saved and processed.`,
          3
        )
      } catch (error) {
        Modal.error({
          title: "Oops! Something went wrong.",
          content: (
            <p>
              Please try again later. If the error persists contact the support
              for more help.
            </p>
          ),
          okButtonProps: {
            type: "default",
            style: { borderColor: "#ff4d4f", color: "#ff4d4f" },
          },
        })
        console.log(error)
      }
    }
    setLoading(false)
    dispatch(setAlert({ alertMessage: null, alertType: undefined }))
  }

  /*
  //upload props:
  const Uploadprops: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    async customRequest(options: UploadRequestOption) {
      setLoadingFile(true)

      const file = options.file as File
      const saveFileName: string =
        storageBacktestInputDir + backtestId + "_" + file.name

      let isFileChange: boolean = false

      //check file type
      if (file.type.includes("csv")) {
        isFileChange = true
      } else {
        const uploadError = new Error("File format not supported.")
        options.onError!(uploadError)
        setAlert({
          alertMessage: "File format not supported.",
          alertType: "error",
        })
        isFileChange = false
      }

      // Upload new file
      if (isFileChange) {
        try {
          const response = await putFile(saveFileName, file, setProgress)

          options.onSuccess!(response)
          isFileChange = true
        } catch (error: unknown) {
          isFileChange = false
          setAlert({
            alertMessage: (
              <p>
                Oops! Something went wrong. Please try again later. If the error
                persists contact the support for more help.
              </p>
            ),
            alertType: "error",
          })

          if (error instanceof Error) {
            const uploadError = new Error(error.message)
            options.onError!(uploadError)
          }
        }
      }

      //credentials
      let credentials = null
      if (isFileChange) {
        credentials = //{accessKeyId: null}
          await authServer.getCurrentCredentials()

        if (credentials.accessKeyId) {
          isFileChange = true
        } else {
          isFileChange = false
          const responseRemove = await removeFile(saveFileName)

          Modal.error({
            title: "Oops! Something went wrong.",
            content: (
              <p>
                There was a problem to get your access credentials. <br />
                Please try to log out and sign in again. <br />
                If the error persists contact the support for more help.
              </p>
            ),
            okText: "Log Out",
            okButtonProps: {
              type: "default",
              style: { borderColor: "#ff4d4f", color: "#ff4d4f" },
            },
            onOk: () => {
              dispatch(logout())
              return false
            },
          })
        }
      }

      //  get file props
      let fileSample = []
      if (isFileChange) {
        try {
          const response = await getDatasetPropsFromS3({
            fileName: saveFileName!,
            credentials: credentials,
          })
          fileSample = response!.sample
          isFileChange = true
        } catch (error) {
          const responseRemove = await removeFile(saveFileName)
          isFileChange = false
          setAlert({
            alertMessage: (
              <p>
                Oops! Something went wrong. Please try again later. If the error
                persists contact the support for more help.
              </p>
            ),
            alertType: "error",
          })

          if (error instanceof Error) {
            console.log(error.message)
            options.onError!(error)
          }
        }
      }

      // get file props ok then check file features
      if (isFileChange) {
        const checkDataset: boolean = checkDatasetLayout({
          sampleSelected: datasetProps.sample,
          sampleAvailable: fileSample,
        })

        if (checkDataset) {
          setAlert({
            alertMessage: `File ${file.name} was successfully uploaded.`,
            alertType: "success",
          })

          isFileChange = true
          setFileName(file.name)
          setSavedFileName(saveFileName)
        } else {
          const responseRemove = await removeFile(saveFileName)
          console.log("remove", saveFileName, responseRemove)

          const uploadError = new Error(
            "File is not compatible with the dataset used to create the policy"
          )
          options.onError!(uploadError)

          setAlert({
            alertMessage: `Oops! The selected dataset is not compatible with your policy.
              Please upload a dataset that contains all features available in the dataset used to create the policy. You can explore dataset detais at the Policy detais area.`,
            alertType: "error",
          })
        }
      }
      isFileChange = false
      setLoadingFile(false)
    },
  }
*/

  const clonePolicy = async () => {
    let originPolicy: policyJson = {
      policy_record_id: null,
      policy_id: null,
      policy_group_name: null,
      policy_group_description: null,
      policy_group_final_type: null,
      created_by: null,
      name: null,
      description: null,
      dataset: null,
      datasetPath: null,
      payload: {
        input: { cascaderPayloadList: [], requestPayload: [] },
        output: [],
      },
      dataSource: {
        source: [
          { datasetSourceCheck: false, datasetFeatureCheck: false },
          { providerSourceCheck: false, providerFeatureCheck: false },
          { databaseSourceCheck: false, databaseFeatureCheck: false },
          { customSourceCheck: false, customFeatureCheck: false },
          { payloadSourceCheck: false, payloadFeatureCheck: false },
          [],
        ],
        selected: {},
      },
      metabasePath: null,
      datasetDecisionPath: null,
      datasetId: null,
      dataProviders: [],
      created_at: null,
      updated_at: null,
      updated_by: null,
      policy_status: null,
      rule: {
        provider: null,
        book: null, 
        feature: null,
        dataSourceType: null,
        operator: null,
        threshold: null,
        nodeType: null,
        nodeId: null,
        finalType: null,
        finalValue: null,
        output: [],
      },
      nodes: {
        nodeProps: {},
        nodeRules: {},
      },
    }

    //get policy data from database
    try {
      const respGetPolicy = await API.get(
        "policiesAPI",
        `/policies/${policyId}`,
        {}
      )
      originPolicy = JSON.parse(respGetPolicy.body)[0]
    } catch (error: unknown) {
      if (error instanceof Error) {
        const saveError = new Error(error.message)
        console.log(saveError)
      }
    }

    //update new info
    const clonePolicy: policyJson = {
      policy_record_id: String(uuid.v1()),
      policy_id: backtestId,
      created_by: user?.email!,
      policy_group_name: backtestName,
      policy_group_description: backtestDescription,
      policy_group_final_type: null,
      name: backtestName,
      description: backtestDescription,
      dataset: originPolicy.dataset,
      datasetPath: storagePath + savedFileName,
      metabasePath: null,
      datasetDecisionPath: null,
      datasetId: originPolicy.datasetId,
      dataProviders: originPolicy.dataProviders,
      created_at: new Date().toLocaleString(),
      updated_at: new Date().toLocaleString(),
      updated_by: user?.email!,
      policy_status: originPolicy.policy_status,
      rule: originPolicy.rule,
      nodes: originPolicy.nodes,
      payload: {
        input: { cascaderPayloadList: [], requestPayload: [] },
        output: [],
      },
      dataSource: {
        source: [
          { datasetSourceCheck: false, datasetFeatureCheck: false },
          { providerSourceCheck: false, providerFeatureCheck: false },
          { databaseSourceCheck: false, databaseFeatureCheck: false },
          { customSourceCheck: false, customFeatureCheck: false },
          { payloadSourceCheck: false, payloadFeatureCheck: false },
          [],
        ],
        selected: {},
      }
    }

    try {
      await API.post("policiesAPI", "/policies", {
        body: clonePolicy,
      })
    } catch (error: unknown) {
      if (error instanceof Error) {
        const saveError = new Error(error.message)
        console.log(saveError)
        Modal.error({
          title: "Oops! Something went wrong.",
          content: (
            <p>
              Please try to save it again later. <br />
              If the error persists contact the support for more help.
            </p>
          ),
          okButtonProps: {
            type: "default",
            style: { borderColor: "#ff4d4f", color: "#ff4d4f" },
          },
        })
      }
    }
  }

  return (
    <div>
      {alert.alertMessage && (
        <Alert
          closable
          message={alert.alertMessage}
          showIcon
          type={alert.alertType}
          className={styles.error_alert}
          onClose={() =>
            dispatch(setAlert({ alertMessage: null, alertType: undefined }))
          }
        />
      )}

      <Form layout="vertical" requiredMark={false}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please insert a name for your backtest",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Insert a name for your backtest here"
            onChange={(e) =>
              setBacktestName("backtest_" + policyId + ":" + e.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please insert a description for your backtest",
            },
          ]}
        >
          <TextArea
            size="large"
            placeholder="Insert a description for your backtest here"
            autoSize={true}
            onChange={(e) => setBacktestDescription(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="Dataset file" name="upload">
          {!fileName ? (
            <UploadDatasetBtn
              id={backtestId}
              type="backtest"
              bucketPath={storageBacktestInputDir}
              closeModal={() => {}}
              setLoading={setLoadingFile}
              setSuccess={null}
              setShowDialog={null}
              setFileName={setFileName}
              setSavedFileName={setSavedFileName}
            />
          ) : (
            <p className={styles.file_name_p}>{fileName}</p>
          )}
        </Form.Item>
      </Form>
      <div className={`container-row-reverse ${styles.save_btn_container}`}>
        <Space size="large">
          <Button
            size="large"
            onClick={async () => {
              const response = await removeFile(savedFileName)
              console.log("remove", response)
              dispatch(setAlert({ alertMessage: null, alertType: undefined }))
              setStateEdit(false)
            }}
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            loading={loading}
            onClick={saveBacktest}
          >
            Save & Process
          </Button>
        </Space>
      </div>
    </div>
  )
}

export default NewBacktest
