//router
import { useNavigate } from "react-router-dom"

//antd
import { Button, Result } from "antd"

type Props = { buttonText: string; buttonUrl: string }

const NotFound = ({ buttonText, buttonUrl }: Props) => {
	let navigate = useNavigate()

	return (
		<Result
			className="container-page"
			status="404"
			title="404"
			subTitle="Sorry, the page you visited does not exist."
			extra={
				<Button key="notfound-btn" type="primary" onClick={() => navigate(`${buttonUrl}`)}>
					{buttonText}
				</Button>
			}
		/>
	)
}

export default NotFound
