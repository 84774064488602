//reactflow
import { Node } from "reactflow"

// redux
import { createSlice } from "@reduxjs/toolkit"

//types
import { INodeProps, INodes } from "../../types"

// Initial State
const initialState: INodes = {
  nodes: [
    {
      id: "N0",
      type: "custom",
      position: { x: 500, y: 0 },
      data: {
        label: "Dataset",
      },
    },
  ],
  edges: [],
  nodesProps: {
    N0: {
      arrayId: "N0",
      parentId: null,
      children1Id: null,
      children2Id: null,
      positionX: 500,
      positionY: 0,
      nodeSide: 0,
      nodeLevel: 0,
    },
  },
}

// Reducer
export const nodeSlice = createSlice({
  name: "node",
  initialState,
  reducers: {
    resetNode: () => initialState,
    addNewNode: (state, action) => {
      return {
        ...state,
        nodes: action.payload.newNode,
        edges: action.payload.newEdge,
      }
    },
    updateSourceNode: (state, action) => {
      let stringstate = JSON.stringify(state.nodes)
      let oldState: Node[] = JSON.parse(stringstate)

      let stringProps = JSON.stringify(state.nodesProps)
      let oldProps = JSON.parse(stringProps)

      //new nodes props
      const deltaMin: number = 125
      const nodeWidth: number = 230

      const newNode1Id: string = oldProps[action.payload.id].children1Id
      const indexNewNode1Id: number = oldState.findIndex((object) => {
        return object.id === newNode1Id
      })
      const newNode2Id: string = oldProps[action.payload.id].children2Id
      const indexNewNode2Id: number = oldState.findIndex((object) => {
        return object.id === newNode2Id
      })

      const newLevel: number = oldProps[newNode1Id].nodeLevel
      const newSide: number = oldProps[newNode1Id].nodeSide

      let posNew1: number = oldState[indexNewNode1Id].position.x
      let x0New1: number = posNew1 - nodeWidth / 2
      let xfNew1: number = posNew1 + nodeWidth / 2

      let posNew2: number = oldState[indexNewNode2Id].position.x
      let x0New2: number = posNew2 - nodeWidth / 2
      let xfNew2: number = posNew2 + nodeWidth / 2

      /*
			//correct position to avoid overlap at center right side
			if (
				oldState[Number(newNode2Id)].position.x - nodeWidth / 2 <=
					oldState[0].position.x &&
				oldProps[action.payload].nodeSide > 0
			) {
				let deltaX: number =
					oldState[0].position.x -
					oldState[Number(newNode2Id)].position.x +
					deltaMin / 2
				for (let key in oldProps) {
					if (oldProps[key].nodeSide > 0) {
						oldState[Number(oldProps[key].arrayId)].position.x =
							oldState[Number(oldProps[key].arrayId)].position.x + deltaX
					}
				}
				oldState[0].position.x =
					oldState[2].position.x +
					(oldState[1].position.x - oldState[2].position.x) / 2
			}

			//correct position to avoid overlap at center left side
			if (
				oldState[Number(newNode1Id)].position.x + nodeWidth / 2 >=
					oldState[0].position.x &&
				oldProps[action.payload].nodeSide < 0
			) {
				let deltaX: number =
					oldState[Number(newNode1Id)].position.x -
					oldState[0].position.x +
					deltaMin / 2
				for (let key in oldProps) {
					if (oldProps[key].nodeSide < 0) {
						oldState[Number(oldProps[key].arrayId)].position.x =
							oldState[Number(oldProps[key].arrayId)].position.x - deltaX
					}
				}
				
			}
*/
      //search for overlap
      let overlap1: boolean = false
      let deltaX1: number = 0
      let overlap1Id: string = "N0"
      let move1: boolean = false

      let overlap2: boolean = false
      let deltaX2: number = 0
      let overlap2Id: string = "N0"
      let move2: boolean = false

      posNew1 = oldState[indexNewNode1Id].position.x
      x0New1 = posNew1 - nodeWidth / 2
      xfNew1 = posNew1 + nodeWidth / 2

      posNew2 = oldState[indexNewNode2Id].position.x
      x0New2 = posNew2 - nodeWidth / 2
      xfNew2 = posNew2 + nodeWidth / 2

      //OVERLAP
      for (let key in oldProps) {
        const indexKey: number = oldState.findIndex((object) => {
          return object.id === oldProps[key].arrayId
        })

        if (
          oldProps[key].nodeLevel === newLevel &&
          key !== newNode1Id &&
          key !== newNode2Id
        ) {
          //node1  space
          let x1: number = oldState[indexKey].position.x - nodeWidth / 2
          let x2: number = oldState[indexKey].position.x + nodeWidth / 2

          if (
            (x0New1 >= x1 && x0New1 <= x2) ||
            (xfNew1 >= x1 && xfNew1 <= x2)
          ) {
            overlap1 = true
            overlap1Id = key
            if (newSide > 0) {
              deltaX1 = x2 - xfNew1 + 10
              if (x0New1 === x1 && xfNew1 === x2) {
                deltaX1 = 2 * deltaMin
              }
              move1 = false
            } else {
              move1 = true
              deltaX1 = xfNew1 - x1
              if (x0New1 === x1 && xfNew1 === x2) {
                deltaX1 = 2 * deltaMin
              }
            }
          }

          if (
            (x0New2 >= x1 && x0New2 <= x2) ||
            (xfNew2 >= x1 && xfNew2 <= x2)
          ) {
            overlap2 = true
            overlap2Id = key
            if (newSide > 0) {
              deltaX2 = x2 - xfNew2
              if (x0New2 === x1 && xfNew2 === x2) {
                deltaX2 = 2 * deltaMin
              }
              move2 = true
            } else {
              deltaX2 = xfNew2 - x1
              if (x0New2 === x1 && xfNew2 === x2) {
                deltaX2 = 2 * deltaMin
              }
              move2 = false
            }
          }
        }
      }

      const indexOverlap1Id: number = oldState.findIndex((object) => {
        return object.id === oldProps[overlap1Id].arrayId
      })

      const indexOverlap2Id: number = oldState.findIndex((object) => {
        return object.id === oldProps[overlap2Id].arrayId
      })

      if (overlap1) {
        for (let key in oldProps) {
          const indexKey: number = oldState.findIndex((object) => {
            return object.id === oldProps[key].arrayId
          })

          if (
            oldProps[key].nodeSide === newSide &&
            key !== newNode1Id &&
            key !== overlap1Id &&
            newSide * oldState[indexKey].position.x >= newSide * posNew1
          ) {
            oldState[indexKey].position.x =
              oldState[indexKey].position.x + newSide * deltaX1
          }
        }

        if (move1) {
          oldState[indexNewNode1Id].position.x =
            oldState[indexNewNode1Id].position.x + newSide * deltaX1
        } else {
          oldState[indexOverlap1Id].position.x =
            oldState[indexOverlap1Id].position.x + newSide * deltaX1
        }
      }

      if (overlap2) {
        for (let key in oldProps) {
          const indexKey: number = oldState.findIndex((object) => {
            return object.id === oldProps[key].arrayId
          })
          if (
            oldProps[key].nodeSide === newSide &&
            key !== newNode2Id &&
            key !== overlap2Id &&
            newSide * oldState[indexKey].position.x >= newSide * posNew2
          ) {
            oldState[indexKey].position.x =
              oldState[indexKey].position.x + newSide * deltaX2
          }
        }

        if (move2) {
          oldState[indexNewNode2Id].position.x =
            oldState[indexNewNode2Id].position.x + newSide * deltaX2
        } else {
          oldState[indexOverlap2Id].position.x =
            oldState[indexOverlap2Id].position.x + newSide * deltaX2
        }
      }

      //correct position to avoid overlap at center right side
      let minX: number = oldState[0].position.x
      let maxX: number = oldState[0].position.x

      for (let key in oldProps) {
        const indexKey: number = oldState.findIndex((object) => {
          return object.id === oldProps[key].arrayId
        })
        if (
          key !== "N0" &&
          oldProps[key].nodeSide > 0 && //Right
          oldState[indexKey].position.x - nodeWidth / 2 < minX
        ) {
          minX = oldState[indexKey].position.x - nodeWidth / 2
        }

        if (
          key !== "N0" &&
          oldProps[key].nodeSide < 0 && //Left
          oldState[indexKey].position.x + nodeWidth / 2 > maxX
        ) {
          maxX = oldState[indexKey].position.x + nodeWidth / 2
        }
      }

      if (minX < oldState[0].position.x) {
        //correct Right
        let deltaX: number = oldState[0].position.x - minX + 1.3 * deltaMin
        for (let key in oldProps) {
          const indexKey: number = oldState.findIndex((object) => {
            return object.id === oldProps[key].arrayId
          })

          if (key !== "N0" && oldProps[key].nodeSide > 0) {
            oldState[indexKey].position.x =
              oldState[indexKey].position.x + deltaX
          }
        }
      }

      if (maxX > oldState[0].position.x) {
        //correct Left
        let deltaX: number = maxX - oldState[0].position.x + 1.3 * deltaMin
        for (let key in oldProps) {
          const indexKey: number = oldState.findIndex((object) => {
            return object.id === oldProps[key].arrayId
          })

          if (key !== "N0" && oldProps[key].nodeSide < 0) {
            oldState[indexKey].position.x =
              oldState[indexKey].position.x - deltaX
          }
        }
      }

      //update node props
      for (let key in oldProps) {
        const indexKey: number = oldState.findIndex((object) => {
          return object.id === oldProps[key].arrayId
        })

        oldProps[key].positionX = oldState[indexKey].position.x
        oldProps[key].positionY = oldState[indexKey].position.y
      }

      return {
        ...state,
        nodes: oldState,
        nodesProps: oldProps,
      }
    },
    updateNodePosition: (state, action) => {
      let stringstate = JSON.stringify(state.nodesProps)
      let oldState = JSON.parse(stringstate)

      oldState[action.payload.id].positionX = action.payload.positionX
      oldState[action.payload.id].positionY = action.payload.positionY

      return {
        ...state,
        nodesProps: oldState,
      }
    },
    updateNodeProps: (state, action) => {
      let stringstate = JSON.stringify(state.nodesProps)
      let oldState = JSON.parse(stringstate)
      oldState[action.payload.id] = action.payload.nodeProps
      return {
        ...state,
        nodesProps: oldState,
      }
    },

    updateAllNodeProps: (state, action) => {
      return {
        ...state,
        nodesProps: action.payload,
      }
    },
    updateNodes: (state, action) => {
      return {
        ...state,
        nodes: action.payload,
      }
    },
    deleteNodeProps: (state, action) => {
      let stringstate = JSON.stringify(state.nodesProps)
      let oldState = JSON.parse(stringstate)

      let newStateNodesProps: INodeProps = {}
      for (let i in oldState) {
        if (i !== action.payload.id1 && i !== action.payload.id2) {
          newStateNodesProps[i] = oldState[i]
        }
      }

      newStateNodesProps[action.payload.parentId].children1Id = null
      newStateNodesProps[action.payload.parentId].children2Id = null

      return {
        ...state,
        nodesProps: newStateNodesProps,
      }
    },
  },
})

export const {
  resetNode,
  addNewNode,
  updateSourceNode,
  updateNodeProps,
  updateNodes,
  updateNodePosition,
  updateAllNodeProps,
  deleteNodeProps,
} = nodeSlice.actions

export default nodeSlice.reducer
