//types
import { featuresDataType, providerBookListType } from "../../types"

const uuid = require("uuid")

// Get dataset list
export const createProviderList = (featureList: featuresDataType[]) => {
  // get distinct provider list
  let providerList: providerBookListType[] = []
  let providerBookList: {
    provider: string | null
    book: string | null
  }[] = []
  let bookList: string[] = []
  let auxList: string[] = []
  let auxProvider: string[] = []
  let auxProviderLabel: string[] = []

  let auxFeature: {
    key: string
    value: string
    label: string
    providerValue: string | null
    dataType: string | null
    allowedValues: string | null
  }[] = []

  let providerFeatureList: {
    key: string
    label: string
    value: string
    type: string
    children: {
      key: string
      value: string
      label: string
      providerValue: string | null
    }[]
  }[] = []

  for (let j in featureList) {
    //loop over features
    let count: number = 0
    if (
      featureList[j].provider &&
      featureList[j].sourceType === "provider" &&
      auxList.includes(
        featureList[j].provider![0] + featureList[j].provider![1]
      )
    ) {
      count++
    }

    if (
      count === 0 &&
      featureList[j].provider &&
      featureList[j].sourceType === "provider"
    ) {
      auxList.push(featureList[j].provider![0] + featureList[j].provider![1])
      providerBookList.push({
        provider: featureList[j].provider![0],
        book: featureList[j].provider![1],
      })
    }

    //provider list
    if (
      featureList[j].provider &&
      !auxProvider.includes(featureList[j].provider![0])
    ) {
      auxProvider.push(featureList[j].provider![0])
      auxProviderLabel.push(featureList[j].providerLabel![0])
    }
  }

  auxList = []
  for (let k1 in providerBookList) {
    bookList = []
    if (
      providerBookList[k1].provider &&
      !auxList.includes(providerBookList[k1].provider!)
    ) {
      for (let k2 in providerBookList) {
        if (providerBookList[k1].provider === providerBookList[k2].provider)
          bookList.push(providerBookList[k2].book ?? "")
        auxList.push(providerBookList[k1].provider ?? "")
      }
      providerList.push({
        key: uuid.v1(),
        provider: providerBookList[k1].provider,
        bookList: bookList,
      })
    }
  }

  for (let k1 in auxProvider) {
    auxFeature = []

    for (let k2 in featureList) {
      let providerValue: string | null = null
      let providerName: string | null = null

      if (featureList[k2].provider) {
        providerName = featureList[k2].provider![0]
        providerValue =
          featureList[k2].provider![2] ?? featureList[k2].provider![1]
      }

      if (providerName === auxProvider[k1])
        auxFeature.push({
          key: String(featureList[k2].key),
          value: featureList[k2].feature!,
          label: featureList[k2].feature!,
          providerValue: providerValue,
          dataType: featureList[k2].dataType,
          allowedValues: featureList[k2].allowedValues,
        })
    }

    providerFeatureList.push({
      key: uuid.v1(),
      label: auxProviderLabel[k1],
      value: auxProvider[k1],
      type: "",
      children: auxFeature,
    })
  }

  return {
    providerBookList: providerList,
    providerFeatureList: providerFeatureList,
  }
}
