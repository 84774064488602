//amplify
import { API } from "aws-amplify"
import { ouzeProvider } from "../../Ouze/provider-features"

export const getProviderList = async () => {
  try {
    let providerList: any[] = []
    let customFeatureList: any[] = []
    let databaseList: any[] = []

    for (let i in ouzeProvider) {
      if (ouzeProvider[i].dataType === "provider") {
        providerList = ouzeProvider[i].data
      } else if (ouzeProvider[i].dataType === "custom") {
        customFeatureList = ouzeProvider[i].data
      } else if (ouzeProvider[i].dataType === "database") {
        databaseList = ouzeProvider[i].data
      }
    }

    return {
      providerList: providerList,
      databaseList: databaseList,
      customFeatureList: customFeatureList,
    }
  } catch (error) {
    console.log(error)
    return error
  }
}
