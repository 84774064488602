// redux
import { createSlice } from "@reduxjs/toolkit"

//types
import { INewPolicy } from "../../types"

// Initial State
const initialState: INewPolicy = {
  policy_id: null,
  created_at: null,
  created_by: null,
  policy_status: null,
  policy_record_id: null,
  policyName: null,
  policyGroupName: null,
  policyGroupDescription: null,
  policyGroupFinalType: null,
  payloadIn: {
    requestPayload: [
      {
        field: "cpf",
        description: "Brazillian document (CPF)",
        dataType: "STRING",
        allowedValues: "format 00000000000 ",
      },
    ],
    cascaderPayloadList: [],
  },
  payloadOut: ["decision"],
  description: null,
  datasetName: null,
  datasetId: null,
  datasetPath: null,
  metabasePath: null,
  datasetDecisionPath: null,
  dataSource: [
    { datasetSourceCheck: false, datasetFeatureCheck: false },
    { providerSourceCheck: false, providerFeatureCheck: false },
    { databaseSourceCheck: false, databaseFeatureCheck: false },
    { customSourceCheck: false, customFeatureCheck: false },
    { payloadSourceCheck: false, payloadFeatureCheck: false },
    [],
  ],
  dataSelected: {},
  cascaderFeatureList: [],
  sample: null,
  selectedFeature: {
    name: null,
    dataType: null,
    allowedValues: null,
    source: null,
  },
  rules: {
    N0: {
      feature: [],
      featureLabel: [],
      operator: null,
      threshold: null,
      dataSourceType: null,
      isfinalNode: false,
      decisionType: null,
      decisionStatus: null,
      decisionValue: null,
      isDecisionStatus: false,
      isDecisionValue: false,
      isDeleted: false,
      volumetry: null,
      decisionReason: null,
    },
  },
}

// Reducer
export const newPolicySlice = createSlice({
  name: "newPolicy",
  initialState,
  reducers: {
    resetNewPolicy: () => initialState,

    resetRules: (state) => {
      let stringstate = JSON.stringify(state.rules)
      let oldState = JSON.parse(stringstate)

      const newInitialValue = {
        feature: [],
        featureLabel: [],
        operator: null,
        threshold: null,
        dataSourceType: null,
        isfinalNode: false,
        decisionType: null,
        decisionStatus: null,
        decisionValue: null,
        isDecisionStatus: false,
        isDecisionValue: false,
        isDeleted: false,
        volumetry: null,
      }

      for (let i in oldState) {
        oldState[i] = newInitialValue
      }

      return {
        ...state,
        rules: oldState,
      }
    },

    submmitId: (state, action) => {
      return {
        ...state,
        policy_id: action.payload.policyId,
      }
    },

    submmitName: (state, action) => {
      return {
        ...state,
        policyName: action.payload.policyName,
      }
    },
    submmitDescription: (state, action) => {
      return {
        ...state,
        description: action.payload.description,
      }
    },

    submmitNewPolicyGroup: (state, action) => {
      return {
        ...state,
        policyGroupName: action.payload.policyGroupName,
        policyGroupDescription: action.payload.policyGroupDescription,
        policyGroupFinalType: action.payload.policyGroupFinalType,
      }
    },
    submmitGroupName: (state, action) => {
      return {
        ...state,
        policyGroupName: action.payload.policyGroupName,
      }
    },
    submmitGroupDescription: (state, action) => {
      return {
        ...state,
        policyGroupDescription: action.payload.policyGroupDescription,
      }
    },
    submmitGroupFinalType: (state, action) => {
      return {
        ...state,
        policyGroupFinalType: action.payload.policyGroupFinalType,
      }
    },

    submmitNewPolicyInitialData: (state, action) => {
      return {
        ...state,
        policy_id: action.payload.policyId,
        policy_record_id: action.payload.policyRecordId,
        created_at: action.payload.createdAt,
        created_by: action.payload.createdBy,
        policy_status: action.payload.policyStatus,
        policyName: action.payload.policyName,
        description: action.payload.description,
        policyGroupName: action.payload.policyGroupName,
        policyGroupDescription: action.payload.policyGroupDescription,
        policyGroupFinalType: action.payload.policyGroupFinalType,
      }
    },

    submmitInitalData: (state, action) => {
      return {
        ...state,
        policyName: action.payload.policyName,
        description: action.payload.description,
        policy_id: action.payload.policy_id,
        created_at: action.payload.created_at,
        created_by: action.payload.created_by,
        policy_status: action.payload.policy_status,
        policy_record_id: action.payload.policy_record_id,
        datasetDecisionPath: action.payload.datasetDecisionPath,
        metabasePath: action.payload.metabasePath,
        policyGroupName: action.payload.policyGroupName,
        policyGroupDescription: action.payload.policyGroupDescription,
        policyGroupFinalType: action.payload.policyGroupFinalType,
        payloadIn: action.payload.payloadIn,
        payloadOut: action.payload.payloadOut,
        dataSource: action.payload.dataSource.source,
        dataSelected: action.payload.dataSource.selected,
      }
    },
    submmitPayloadIn: (state, action) => {
      return {
        ...state,
        payloadIn: action.payload,
      }
    },
    submmitPayloadOut: (state, action) => {
      return {
        ...state,
        payloadOut: action.payload,
      }
    },
    submitDataset: (state, action) => {
      return {
        ...state,
        datasetName: action.payload.datasetName,
        datasetId: action.payload.datasetId,
        datasetPath: action.payload.datasetPath,
        cascaderFeatureList: action.payload.cascaderFeatureList,
        sample: action.payload.sample,
      }
    },
    submitDataSource: (state, action) => {
      return {
        ...state,
        dataSource: action.payload,
      }
    },
    selectedDataSource: (state, action) => {
      let stringstate = JSON.stringify(state.dataSelected)
      let oldstate = JSON.parse(stringstate)
      oldstate[action.payload.id] = action.payload.selected

      return {
        ...state,
        dataSelected: oldstate,
      }
    },
    resetDataSource: (state, action) => {
      let resetState = {}

      if (action.payload !== "all") {
        let stringstate = JSON.stringify(state.dataSelected)
        let oldstate = JSON.parse(stringstate)
        for (let i in oldstate) {
          if (oldstate[i].type === action.payload) {
            oldstate[i].sourceStatus = false
            oldstate[i].featureStatus = false
          }
        }
        resetState = oldstate
      }

      return {
        ...state,
        dataSelected: resetState,
      }
    },

    submitSelectedFeature: (state, action) => {
      return {
        ...state,
        selectedFeature: action.payload,
      }
    },

    submitRules: (state, action) => {
      let stringstate = JSON.stringify(state.rules)
      let oldstate = JSON.parse(stringstate)
      oldstate[action.payload.id] = action.payload.nodeRule
      return {
        ...state,
        rules: oldstate,
      }
    },

    submitFeatureLabel: (state, action) => {
      let stringstate = JSON.stringify(state.rules)
      let oldstate = JSON.parse(stringstate)
      oldstate[action.payload.id].featureLabel = action.payload.featureLabel
      return {
        ...state,
        rules: oldstate,
      }
    },
    changeDeleteState: (state, action) => {
      let stringstate = JSON.stringify(state.rules)
      let oldstate = JSON.parse(stringstate)
      oldstate[action.payload.id].isDeleted = action.payload.isDeleted
      return {
        ...state,
        rules: oldstate,
      }
    },
    updateRules: (state, action) => {
      return {
        ...state,
        rules: action.payload,
      }
    },
    deleteRules: (state, action) => {
      let stringstate = JSON.stringify(state.rules)
      let oldState = JSON.parse(stringstate)

      //parent: reset rules
      let newInitialValue = {
        feature: [],
        featureLabel: [],
        operator: null,
        threshold: null,
        dataSourceType: null,
        isfinalNode: false,
        decisionType: null,
        decisionStatus: null,
        decisionValue: null,
        isDecisionStatus: false,
        isDecisionValue: false,
        isDeleted: false,
        volumetry: null,
      }
      oldState[action.payload.parentId] = newInitialValue

      //to be deleted
      newInitialValue = {
        feature: [],
        featureLabel: [],
        operator: null,
        threshold: null,
        dataSourceType: null,
        isfinalNode: false,
        decisionType: null,
        decisionStatus: null,
        decisionValue: null,
        isDecisionStatus: false,
        isDecisionValue: false,
        isDeleted: true,
        volumetry: null,
      }
      oldState[action.payload.id1] = newInitialValue
      oldState[action.payload.id2] = newInitialValue

      return {
        ...state,
        rules: oldState,
      }
    },
    setPolicyVolumetry: (state, action) => {
      let stringstate = JSON.stringify(state.rules)
      let oldstate = JSON.parse(stringstate)

      oldstate[action.payload.id].volumetry = action.payload.volumetry

      return {
        ...state,
        rules: oldstate,
      }
    },

    setRuleFeatureSource: (state, action) => {
      let stringstate = JSON.stringify(state.rules)
      let oldstate = JSON.parse(stringstate)

      oldstate[action.payload.id].dataSourceType = action.payload.type

      return {
        ...state,
        rules: oldstate,
      }
    },

    resetPolicyVolumetry: (state) => {
      let stringstate = JSON.stringify(state.rules)
      let oldstate = JSON.parse(stringstate)

      for (let i in oldstate) {
        oldstate[i].volumetry = null
      }

      return {
        ...state,
        rules: oldstate,
      }
    },
  },
})

export const {
  resetNewPolicy,
  resetPolicyVolumetry,
  resetRules,
  submmitId,
  submmitName,
  submmitDescription,
  submmitNewPolicyInitialData,
  submmitInitalData,
  submitDataset,
  submitDataSource,
  submitRules,
  updateRules,
  changeDeleteState,
  deleteRules,
  setPolicyVolumetry,
  selectedDataSource,
  resetDataSource,
  submitSelectedFeature,
  submitFeatureLabel,
  submmitNewPolicyGroup,
  submmitGroupName,
  submmitGroupDescription,
  submmitGroupFinalType,
  submmitPayloadIn,
  setRuleFeatureSource,
  submmitPayloadOut,
} = newPolicySlice.actions

export default newPolicySlice.reducer
