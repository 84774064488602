/** Function used to select distinct values for a select input options list */
export function distinctOptionsList(data: any[]) {
	let dataDistinct: any[] = []

	for (let i in data) {
		let count: number = 0

		for (let j in dataDistinct) {
			if (dataDistinct[j].value === data[i].value) {
				count++
			}
		}

		if (count === 0) {
			dataDistinct.push({
				label: data[i].label,
				value: data[i].value,
			})
		}
	}

	return dataDistinct
}
