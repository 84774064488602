// redux
import { createSlice } from "@reduxjs/toolkit"

// Initial State
const initialState: {
  showModal: boolean
  alert: {
    alertMessage: string | null
    alertType: "error" | "info" | "success" | "warning" | undefined
  }
} = {
  showModal: false,
  alert: { alertMessage: null, alertType: undefined },
}

// Reducer
export const uploadSlice = createSlice({
  name: "uploadModal",
  initialState,
  reducers: {
    reset: () => initialState,
    setShowModal: (state, action) => {
      return {
        ...state,
        showModal: action.payload,
      }
    },
    setAlert: (state, action) => {
      return {
        ...state,
        alert: action.payload,
      }
    },
  },
})

export const { reset, setShowModal, setAlert } = uploadSlice.actions

export default uploadSlice.reducer
