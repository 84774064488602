//css
import styles from "./choose-data-source.module.css"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import {
  resetDataSource,
  selectedDataSource,
  submitDataset,
  submitDataSource,
} from "../../redux-store/new-policy-reducer/new-policy-slice"

//antd
import { Checkbox, Form, Tooltip, Button } from "antd"
import { CheckboxValueType } from "antd/es/checkbox/Group"

//components
import ChooseDataset from "./choose-dataset"
import ChooseFeatures from "./choose-features"
import { setActiveStep } from "../../redux-store/new-policy-reducer/active-step-slice"

type Props = {
  componentId: number
}

const ChooseDataSource = ({ componentId }: Props) => {
  const dispatch = useAppDispatch()

  const { dataSource, payloadIn } = useAppSelector((state) => state.newPolicy)

  const onChangeDataSource = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues.length > 0) {
      if (checkedValues.includes("sourceDataset")) {
        dispatch(
          submitDataSource([
            { datasetSourceCheck: true, datasetFeatureCheck: false },
            { providerSourceCheck: false, providerFeatureCheck: false },
            { databaseSourceCheck: false, databaseFeatureCheck: false },
            { customSourceCheck: false, customFeatureCheck: false },
            { payloadSourceCheck: false, payloadFeatureCheck: false },
            checkedValues,
          ])
        )
      } else {
        dispatch(
          submitDataset({
            datasetName: null,
            datasetId: null,
            datasetPath: null,
            cascaderFeatureList: [],
            sample: null,
          })
        )

        const checkProvider: boolean = checkedValues.includes("sourceProvider")
        const checkCustomFeatures: boolean = checkedValues.includes(
          "sourceCustomFeatures"
        )
        const checkDatabase: boolean = checkedValues.includes("sourceDatabase")
        const checkPayload: boolean = checkedValues.includes("sourcePayload")

        if (checkPayload) {
          dispatch(
            selectedDataSource({
              id: payloadIn.cascaderPayloadList[0].key,
              selected: {
                sourceStatus: true,
                featureStatus: dataSource[4].payloadFeatureCheck,
                type: "payload",
              },
            })
          )
        }

        dispatch(
          submitDataSource([
            { datasetSourceCheck: false, datasetFeatureCheck: false },
            {
              providerSourceCheck: checkProvider,
              providerFeatureCheck: dataSource[1].providerFeatureCheck,
            },
            {
              databaseSourceCheck: checkDatabase,
              databaseFeatureCheck: dataSource[2].databaseFeatureCheck,
            },
            {
              customSourceCheck: checkCustomFeatures,
              customFeatureCheck: dataSource[3].customFeatureCheck,
            },
            {
              payloadSourceCheck: checkPayload,
              payloadFeatureCheck: dataSource[4].payloadFeatureCheck,
            },

            checkedValues,
          ])
        )

        //remove selected items when disable data source
        if (!checkProvider) dispatch(resetDataSource("provider"))
        if (!checkCustomFeatures) dispatch(resetDataSource("custom"))
        if (!checkDatabase) dispatch(resetDataSource("database"))
        if (!checkPayload) dispatch(resetDataSource("payload"))
      }
    } else {
      //clean all
      dispatch(
        submitDataset({
          datasetName: null,
          datasetId: null,
          datasetPath: null,
          cascaderFeatureList: [],
          sample: null,
        })
      )
      dispatch(
        submitDataSource([
          { datasetSourceCheck: false, datasetFeatureCheck: false },
          { providerSourceCheck: false, providerFeatureCheck: false },
          { databaseSourceCheck: false, databaseFeatureCheck: false },
          { customSourceCheck: false, customFeatureCheck: false },
          { payloadSourceCheck: false, payloadFeatureCheck: false },
        ])
      )
      dispatch(resetDataSource("all"))
    }
  }
  
  return (
    <div className={styles.container_form}>
      <div>
        <Form.Item
          label="Select data source"
          tooltip="Features available to create your rules depend on the selected data source. Therefore, select here data sources that contain the features necessary to build the policy rules.  "
        >
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={onChangeDataSource}
            defaultValue={dataSource[5]}
          >
            <Tooltip title="Create rules using calibration files.">
              <Checkbox
                value="sourceDataset"
                disabled={
                  dataSource[1].providerSourceCheck ||
                  dataSource[2].databaseSourceCheck ||
                  dataSource[3].customSourceCheck ||
                  dataSource[4].payloadSourceCheck ||
                  dataSource[0].datasetFeatureCheck
                    ? true
                    : false
                }
              >
                Dataset
              </Checkbox>
            </Tooltip>

            <Tooltip title="Create rules using input data that will be send at policy request url.">
              <Checkbox
                value="sourcePayload"
                disabled={
                  dataSource[0].datasetSourceCheck ||
                  dataSource[4].payloadFeatureCheck
                }
              >
                Input Data
              </Checkbox>
            </Tooltip>

            <Tooltip title="Create rules using features from your data connections (providers).">
              <Checkbox
                value="sourceProvider"
                disabled={
                  dataSource[0].datasetSourceCheck ||
                  dataSource[1].providerFeatureCheck
                }
              >
                Data Connections
              </Checkbox>
            </Tooltip>

            <Tooltip title="Create rules using features from your internal databases.">
              <Checkbox
                value="sourceDatabase"
                disabled={
                  dataSource[0].datasetSourceCheck ||
                  dataSource[2].databaseFeatureCheck
                }
              >
                Database
              </Checkbox>
            </Tooltip>

            <Tooltip title="Create rules using your custom features.">
              <Checkbox
                value="sourceCustomFeatures"
                disabled={
                  dataSource[0].datasetSourceCheck ||
                  dataSource[3].customFeatureCheck
                }
              >
                Custom Features
              </Checkbox>
            </Tooltip>
          </Checkbox.Group>
        </Form.Item>
      </div>

      {dataSource[0].datasetSourceCheck && (
        <div className={styles.container_dataset}>
          <ChooseDataset componentId={componentId} />
        </div>
      )}

      {(dataSource[1].providerSourceCheck ||
        dataSource[2].databaseSourceCheck ||
        dataSource[3].customSourceCheck ||
        dataSource[4].payloadSourceCheck) && (
        <div className={styles.container_dataset}>
          <ChooseFeatures componentId={componentId} />
        </div>
      )}

      {!dataSource[1].providerSourceCheck &&
        !dataSource[2].databaseSourceCheck &&
        !dataSource[3].customSourceCheck &&
        !dataSource[4].payloadSourceCheck &&
        !dataSource[0].datasetSourceCheck && (
          <div className={styles.container_button}>
            <Form.Item>
              <Button
                size="large"
                onClick={() => dispatch(setActiveStep(componentId - 1))}
              >
                Back
              </Button>
            </Form.Item>
          </div>
        )}
    </div>
  )
}

export default ChooseDataSource
