//css
import styles from "./dataset.module.css"

//react
import { useEffect, useState } from "react"

//router
import { useNavigate, useParams } from "react-router-dom"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setDatasetProps } from "../../redux-store/upload-dataset/dataset-setup-slice"
import { logout } from "../../redux-store/auth-reducer/authentication-thunks"

//service & servers
import {
	getDatasetById,
	putDataset,
} from "../../services/dataset-database-service"
import { authServer } from "../../authentication"
import { getDatasetPropsFromS3 } from "../../services/read-s3-dataset-service/get-dataset-properties-S3"

//antd
import { Button, Col, Divider, Modal, Row, Space, message } from "antd"

//components
import SampleTable from "../../components/dataset/sample-table"

const DatasetPreview = () => {
	const { id } = useParams()

	const dispatch = useAppDispatch()

	let navigate = useNavigate()

	const { datasetProps } = useAppSelector((state) => state.datasetProps)

	const [loading, setLoading] = useState<boolean>(true)

	// get dataset sample from db
	const getData = async () => {
		setLoading(true)
		try {
			//get data from database
			const data = await getDatasetById(id!)
			dispatch(setDatasetProps(data))
		} catch (error: unknown) {
			if (error instanceof Error) {
				const saveError = new Error(error.message)
				console.log(saveError)
			}
		}
		setLoading(false)
	}

	//check if file was correctly processed
	const checkFile = async () => {
		if (
			!datasetProps.size ||
			!datasetProps.qtdRows ||
			!datasetProps.featureList ||
			!datasetProps.sample
		) {
			try {
				const response = await getDatasetPropsFromS3({
					fileName: datasetProps.fileName!
				})

				if (!response?.error) {
					const updatedFileDataset = {
						datasetId: datasetProps.datasetId,
						uploadBy: datasetProps.uploadBy,
						uploadAt: datasetProps.uploadAt,
						updatedAt: datasetProps.updatedAt,
						updatedBy: datasetProps.updatedBy,
						datasetName: datasetProps.datasetName,
						fileName: datasetProps.fileName,
						storagePath: datasetProps.storagePath,
						policies: datasetProps.policies,
						description: datasetProps.description,
						size: response?.size,
						qtdRows: response?.records,
						sample: response?.sample,
						featureList: response!.features,
						providerBookList: datasetProps.providerBookList,
						providerFeatureList: datasetProps.providerFeatureList,
					}

					try {
						// update database with new file info
						const result = await putDataset(updatedFileDataset)

						dispatch(setDatasetProps(updatedFileDataset))
					} catch (error: unknown) {
						message.error(
							"Oops! Something went wrong. Please try again later.",
							5
						)
					}
				} else {
					Modal.error({
						title: "Oops! Something went wrong.",
						content: (
							<p>
								File{" "}
								<strong>
									{datasetProps.fileName!.replace(id! + "_", " ")}
								</strong>{" "}
								was not found!
								<br />
								Please try to upload it again at the dataset setup page. <br />
								If the error persists contact the support for more help.
							</p>
						),
						okText: "Try New Upoad",
						okButtonProps: {
							type: "default",
							style: { borderColor: "#ff4d4f", color: "#ff4d4f" },
						},
						onOk: () => {
							navigate(`/dataset-setup/${id}`)
							return false
						},
					})
				}
			} catch {
				Modal.error({
					title: "Oops! Something went wrong.",
					content: (
						<p>
							There was a problem to get your access credentials. <br />
							Please try to log out and sign in again. <br />
							If the error persists contact the support for more help.
						</p>
					),
					okText: "Log Out",
					okButtonProps: {
						type: "default",
						style: { borderColor: "#ff4d4f", color: "#ff4d4f" },
					},
					onOk: () => {
						dispatch(logout())
						return false
					},
				})
			}
		}
	}

	useEffect(() => {
		getData()
		if (datasetProps.datasetId) checkFile()
	}, [datasetProps.datasetId])

	return (
		<div className={`container-page ${styles.table_container}`}>
			<div className={styles.divider}>
				<Row>
					<Col flex={3} className={styles.container_normal}>
						<h5>{datasetProps.datasetName} </h5>
					</Col>

					<Col flex={2} className={styles.container_reverse}>
						<Button
							size="large"
							type="primary"
							onClick={() => navigate(`/dataset-setup/${id}`)}
						>
							Setup Dataset
						</Button>
					</Col>
				</Row>

				<p>
					<strong>Description:</strong> {datasetProps.description}
				</p>
			</div>
			<div className={styles.divider}>
				<Divider orientation="left" orientationMargin="0">
					<h6>File Preview</h6>
				</Divider>

				<Space size="large" className={styles.container_db_details}>
					<p>
						<strong>File:</strong>{" "}
						{datasetProps.fileName?.replace(id! + "_", " ")}
					</p>
					<p>
						<strong>Size:</strong> {datasetProps.size}
					</p>
					<p>
						<strong>Records amount:</strong> {datasetProps.qtdRows}
					</p>
				</Space>

				<SampleTable
					loading={loading}
					styleProps={{
						scrollBody: { x: "80vw" },
					}}
					sample={datasetProps.sample}
				/>
			</div>
		</div>
	)
}

export default DatasetPreview
