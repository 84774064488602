//css
import styles from "./backup-rules.module.css"

//react router dom
import { useNavigate } from "react-router-dom"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setActiveStep } from "../../redux-store/new-policy-reducer/active-step-slice"
import {
  submmitNewPolicyInitialData,
  submmitDescription,
  submmitName,
  submmitGroupName,
  submmitGroupDescription,
} from "../../redux-store/new-policy-reducer/new-policy-slice"

//antd
import { Input, Button, Form, Space, Divider, Tooltip, Select } from "antd"

const selectItemsFinalNode: { value: string; label: string }[] = [
  { value: "status", label: "STATUS" },
  { value: "value", label: "VALUE" },
]

type Props = {
  componentId: number
  showBtn: boolean
}
const { TextArea } = Input

const uuid = require("uuid")

const BackupRules = ({ componentId, showBtn }: Props) => {
  const dispatch = useAppDispatch()
  const {
    policyName,
    description,
    policyGroupName,
    policyGroupDescription,
    policyGroupFinalType,
  } = useAppSelector((state) => state.newPolicy)
  const { user } = useAppSelector((state) => state.auth)

  let navigate = useNavigate()

  
  return (
    <div className={styles.container_form}>
 
    </div>
  )
}

export default BackupRules
