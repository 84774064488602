// redux
import { createSlice } from "@reduxjs/toolkit"

//types
import { IActiveStep } from "../../types"

// Initial State
const initialState: IActiveStep = {
  activeStep: { id: 0 },
  isPolicySaved: false,
}

// Reducer
export const activeStepSlice = createSlice({
  name: "activeStep",
  initialState,
  reducers: {
    resetActiveStep: () => initialState,
    setActiveStep: (state, action) => {
      return {
        ...state,
        activeStep: { id: action.payload },
      }
    },
    setIsPolicySaved: (state, action) => {
      return {
        ...state,
        isPolicySaved: action.payload,
      }
    },
  },
})

export const { resetActiveStep, setActiveStep, setIsPolicySaved } =
  activeStepSlice.actions

export default activeStepSlice.reducer
