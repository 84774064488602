//styles
import styles from "./policy.module.css"

//react
import { useEffect, useState } from "react"

//Amplify
import { API } from "aws-amplify"

//router
import { useParams } from "react-router-dom"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import {
  submitDataset,
  submmitInitalData,
  updateRules,
} from "../../redux-store/new-policy-reducer/new-policy-slice"
import { updateAllNodeProps } from "../../redux-store/new-policy-reducer/node-slice"
import { setDatasetProps } from "../../redux-store/upload-dataset/dataset-setup-slice"
import { setAlert } from "../../redux-store/upload-dataset/upload-dataset-slice"

//antd
import {
  Button,
  Input,
  Table,
  Row,
  Col,
  Space,
  Select,
  SelectProps,
  Tooltip,
  Collapse,
  Modal,
  Divider,
  Spin,
} from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import type { ColumnsType } from "antd/es/table"

//utils & functions
import { formatDateUnix } from "../../utils/string-unixtime"
import { distinctOptionsList } from "../../utils/distinct-option-list"
import { checkPolicyOnProcess } from "../../functions/policy"

//services
import { getFile } from "../../services/storage-service/getFile"
import {
  storageBacktestInputDir,
  storagePath,
} from "../../services/storage-service"
import { getDatasetById } from "../../services/dataset-database-service"

//components
import NewBacktest from "./new-backtest"
import SampleTable from "../../components/dataset/sample-table"
import { getPolicyList } from "../../services/policy-database-service"

const { Panel } = Collapse

const { Search } = Input

interface DataType {
  key: string
  createdBy: string
  createdAt: string
  name: string
  description: string
  inputFile: string | null
  outputFile: string | null
  downloadSignedURL: string | null
  loading: boolean
}

const PolicyBacktest = () => {
  const { id } = useParams()

  const dispatch = useAppDispatch()

  //redux states
  const { policyName, datasetName, description, rules } = useAppSelector(
    (state) => state.newPolicy
  )

  const { datasetProps } = useAppSelector((state) => state.datasetProps)

  const { nodesProps } = useAppSelector((state) => state.node)

  const [data, setData] = useState<DataType[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const [searchedName, setSearchedName] = useState<string>("")
  const [selectedUser, setSelectedUser] = useState<string[]>([])

  const [usersList, setUserList] = useState<SelectProps["options"]>([])

  const [editNewBacktest, setEditNewBacktest] = useState<boolean>(false)

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchedName],
      onFilter: (value: string | number | boolean, record: DataType) =>
        String(record.name).toLowerCase().includes(String(value).toLowerCase()),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Dataset file",
      dataIndex: "inputFile",
      key: "inputFile",
    },
    {
      title: "User",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, { createdBy }) => (
        <Space direction="horizontal">
          <div className={styles.circle}>
            <p> {createdBy?.substring(0, 1).toUpperCase()} </p>
          </div>
        </Space>
      ),
      align: "center",
      filteredValue: selectedUser,
      onFilter: (value: string | number | boolean, record: DataType) =>
        String(record.createdBy)
          .toLowerCase()
          .includes(String(value).toLowerCase()),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        formatDateUnix(a.createdAt) - formatDateUnix(b.createdAt),
      align: "center",
    },
    {
      title: "Result",
      key: "result",
      render: (_, record) => (
        <Space size="small">
          <Tooltip title="Download result file">
            <Button
              type="link"
              size="small"
              shape="circle"
              icon={<DownloadOutlined />}
              href={record.downloadSignedURL ?? ""}
              disabled={record.downloadSignedURL ? false : true}
              loading={record.loading}
            />
          </Tooltip>
        </Space>
      ),
      align: "center",
    },
  ]

  // get policy list from db
  const getData = async () => {
    setLoading(true)
    try {
      const policies = await getPolicyList()
      console.log('back',policies)

      const nameHeader: string = "backtest_" + id + ":"

      let testData = []
      for (let i in policies) {
        if (policies[i].policy_id === id) {
          //set states
          dispatch(
            submmitInitalData({
              policyName: policies[i].name,
              description: policies[i].description,
              policy_id: policies[i].policy_id,
              created_at: policies[i].created_at,
              created_by: policies[i].created_by,
              policy_status: policies[i].policy_status,
              policy_record_id: policies[i].policy_record_id,
              datasetDecisionPath: policies[i].datasetDecisionPath,
              metabasePath: policies[i].metabasePath,
              policyGroupName: policies[i].policyGroupName,
              policyGroupDescription: policies[i].policy_group_description,
              policyGroupFinalType: policies[i].policy_group_final_ype,
              payloadIn:policies[i].payload.input,
              payloadOut: policies[i].payload.output,
              dataSource: policies[i].dataSource.source,
              dataSelected:policies[i].dataSource.selected,
            })
          )

          dispatch(
            submitDataset({
              datasetName: policies[i].dataset,
              datasetId: policies[i].datasetId,
              datasetPath: policies[i].datasetPath,
            })
          )

          //rules
          dispatch(updateRules(policies[i].nodes.nodeRules))

          //nodesProps
          dispatch(updateAllNodeProps(policies[i].nodes.nodeProps))

          //get dataset
          const data = await getDatasetById(policies[i].datasetId)
          dispatch(setDatasetProps(data))
        }

        if (policies[i].name && policies[i].name.includes(nameHeader)) {
          const backtestName: string = policies[i].name.replace(nameHeader, "")

          const fileHeader: string =
            storagePath + storageBacktestInputDir + policies[i].policy_id + "_"
          let inputFile: string | null = null
          if (policies[i].datasetPath)
            inputFile = policies[i].datasetPath.replace(fileHeader, "")

          let backtestSignedURL: any = null
          if (policies[i].datasetDecisionPath)
            backtestSignedURL = await getFile(policies[i].datasetDecisionPath)

          testData.push({
            key: policies[i].policy_id,
            createdBy: policies[i].created_by,
            createdAt: policies[i].created_at,
            name: backtestName,
            description: policies[i].description,
            inputFile: inputFile,
            outputFile: policies[i].datasetDecisionPath,
            downloadSignedURL: backtestSignedURL,
            loading: false,
          })
        }
      }
      setData(testData)
    } catch (error: unknown) {
      if (error instanceof Error) {
        const saveError = new Error(error.message)
        console.log(saveError)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    let options: SelectProps["options"] = []

    for (let i in data) {
      let username: string | null | undefined
      if (data[i].createdBy.includes("@")) {
        const index: number | null | undefined = data[i].createdBy.indexOf("@")
        username = data[i].createdBy.substring(0, index)
      } else {
        username = data[i].createdBy
      }
      options.push({
        label: username.toLowerCase(),
        value: data[i].createdBy,
      })
    }

    setUserList(distinctOptionsList(options))
  }, [data])

  const newBacktest = () => {
    //validate policy nodes
    const validatePolicy = checkPolicyOnProcess({
      nodesProps: nodesProps,
      rules: rules,
    })
    if (validatePolicy.status) {
      setEditNewBacktest(true)
    } else {
      Modal.error({
        title: "Oops! Your policy is incomplete.",
        content: (
          <p>
            Missing {validatePolicy.qtdError} final node(s). <br />
            Please review your policy before continue.
          </p>
        ),
        okButtonProps: {
          type: "default",
          style: { borderColor: "#ff4d4f", color: "#ff4d4f" },
        },
      })
    }
  }

  return (
    <div className="container-page">
      <Spin tip="Loading..." spinning={!policyName} size="large">
        <Collapse bordered={false} className={styles.collapse_container}>
          <Panel header={`Policy: ${policyName ?? ""}`} key="1">
            <p>
              <strong>Description:</strong> {description}
            </p>
            <p>
              <strong>Dataset:</strong> {datasetName}
            </p>
            <div className={styles.sample_table}>
              <SampleTable
                loading={loading}
                styleProps={{ scrollBody: { x: "70vw" } }}
                sample={datasetProps.sample}
              />
            </div>
          </Panel>
        </Collapse>

        <div className={styles.table_container}>
          <div className={styles.button_container}>
            <Row>
              <Col flex={3} className={styles.container_normal}>
                <Search
                  placeholder="Search backtest name"
                  allowClear
                  onSearch={(value) => setSearchedName(value)}
                  style={{ width: "60%", padding: "0px 30px 0px 0px" }}
                  size="large"
                />

                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "60%" }}
                  placeholder="All users "
                  onChange={(value: string[]) => setSelectedUser(value)}
                  options={usersList}
                  size="large"
                />
              </Col>
              <Col flex={2} className={styles.container_reverse}>
                <Button size="large" type="primary" onClick={newBacktest}>
                  New Backtest
                </Button>
              </Col>
            </Row>
          </div>

          <div>
            <Table
              className={styles.table}
              bordered
              columns={columns}
              dataSource={data}
              size="small"
              loading={loading}
              pagination={{ pageSize: 12 }}
            />
          </div>
        </div>

        <>
          <Modal
            title="New Backtest"
            centered
            width={800}
            open={editNewBacktest}
            onCancel={() => {
              setEditNewBacktest(false)
              dispatch(setAlert({ alertMessage: null, alertType: undefined }))
            }}
            destroyOnClose
            footer={false}
            maskClosable={false}
            afterClose={getData}
          >
            <Divider />
            <NewBacktest policyId={id!} setStateEdit={setEditNewBacktest} />
            <Divider className={styles.divider} />
          </Modal>
        </>
      </Spin>
    </div>
  )
}

export default PolicyBacktest
