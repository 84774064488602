//css
import styles from "./custom-node.module.css"

//react
import { useState, useEffect } from "react"

//reactflow
import { Handle, Position, useReactFlow } from "reactflow"
import "reactflow/dist/style.css"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import {
  changeDeleteState,
  deleteRules,
  resetPolicyVolumetry,
  selectedDataSource,
  submitDataSource,
  submitRules,
  submitSelectedFeature,
} from "../../redux-store/new-policy-reducer/new-policy-slice"
import {
  addNewNode,
  updateSourceNode,
  updateNodeProps,
  deleteNodeProps,
} from "../../redux-store/new-policy-reducer/node-slice"

//antd
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Tooltip,
} from "antd"

import {
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons"

//types
import { INodeRule, nodeSize } from "../../types"

//components
import FeatureSetUp from "./feature-setup"

const uuid = require("uuid")

const { TextArea } = Input

export const CustomNode = ({
  id,
  data,
}: {
  id: string
  data: { label: string }
}) => {
  const dispatch = useAppDispatch()
  const { policyGroupFinalType, dataSelected, dataSource, datasetName, rules } =
    useAppSelector((state) => state.newPolicy)
  const { nodes, edges, nodesProps } = useAppSelector((state) => state.node)

  const { setCenter } = useReactFlow()

  const [activeNode, setActiveNode] = useState<string | null>(null)
  const [isFinalNode, setIsFinalNode] = useState<boolean>(
    rules[id]?.isfinalNode ?? false
  )
  const [isDecisionValue, setIsDecisionValue] = useState<boolean>(
    policyGroupFinalType === "value"
  )
  const [isDecisionStatus, setIsDecisionStatus] = useState<boolean>(
    policyGroupFinalType === "status"
  )
  const [isDisable, setIsDisable] = useState<boolean>(false)

  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)

  const selectItemsDecision: { value: string; label: string }[] = [
    { value: "approved", label: "Approved" },
    { value: "reproved", label: "Reproved" },
    { value: "manual analysis", label: "Manual Analysis" },
  ]

  const submitEditForm = ({
    feature,
    operator,
    threshold,
    decisionTp,
    decisionStatus,
    decisionValue,
    decisionReason,
  }: {
    feature: []
    operator:
      | ">"
      | ">="
      | "<"
      | "<="
      | "="
      | "!="
      | "in"
      | "not in"
      | "rf compare"
    threshold: string | null
    decisionTp: "status" | "value" | null
    decisionStatus: "approved" | "reproved" | "manual analysis" | null
    decisionValue: string | null
    decisionReason: string | null
  }) => {
    const nodeRule: INodeRule = {
      feature: feature ?? [],
      featureLabel: rules[id].featureLabel ?? [],
      dataSourceType: rules[id].dataSourceType ?? null,
      operator: operator ?? null,
      threshold: threshold ?? null,
      isfinalNode: isFinalNode ?? false,
      decisionType: policyGroupFinalType,
      decisionStatus: decisionStatus ?? null,
      decisionValue: decisionValue ?? null,
      isDecisionStatus: isDecisionStatus ?? false,
      isDecisionValue: isDecisionValue ?? false,
      isDeleted: false,
      volumetry: rules[id].volumetry,
      decisionReason: decisionReason ?? null,
    }
    //console.log("inside", feature)

    if (!nodeRule.isfinalNode && !nodesProps[id]?.children1Id) {
      newNode(id)
    }
    dispatch(submitRules({ id, nodeRule }))
    dispatch(resetPolicyVolumetry())
    dispatch(
      submitSelectedFeature({
        name: null,
        dataType: null,
        allowedValues: null,
        source: null,
      })
    )
    setActiveNode(null)
  }

  const newNode = (id: string) => {
    const nodeWidth: number = nodeSize.nodeWidth
    const nodeHeight: number = nodeSize.nodeHeight
    const vSpace: number = nodeSize.vSpace
    const hSpace: number = nodeSize.hSpace

    const nodeId1: string = "N" + uuid.v1().substring(0, 8)
    const nodeId2: string = "N" + uuid.v1().substring(0, 8)

    let SideChildren1: number = 1
    let SideChildren2: number = -1

    if (nodesProps[id]?.nodeSide !== 0) {
      SideChildren1 = nodesProps[id]?.nodeSide
      SideChildren2 = nodesProps[id]?.nodeSide
    }

    const index: number = nodes.findIndex((object: any) => {
      return object.id === id
    })

    //initial state for rules:
    const initialState: INodeRule = {
      feature: [],
      featureLabel: [],
      dataSourceType: null,
      operator: null,
      threshold: null,
      isfinalNode: false,
      decisionType: policyGroupFinalType,
      decisionStatus: null,
      decisionValue: null,
      isDecisionStatus: false,
      isDecisionValue: false,
      isDeleted: false,
      volumetry: null,
      decisionReason: null,
    }

    //create intial node rule state
    dispatch(submitRules({ id: nodeId1, nodeRule: initialState }))
    dispatch(submitRules({ id: nodeId2, nodeRule: initialState }))

    //string Ids
    const nodeSId1: string = nodeId1 //String(nodeId1)
    const nodePos1: { x: number; y: number } = {
      x: nodes[index].position.x + hSpace,
      y: nodes[index].position.y + vSpace,
    }
    const nodeSId2: string = nodeId2 //String(nodeId2)
    const nodePos2: { x: number; y: number } = {
      x: nodes[index].position.x - hSpace,
      y: nodes[index].position.y + vSpace,
    }

    const viewPoint: { x: number; y: number } = {
      x: nodes[index].position.x + 0.7 * nodeWidth,
      y: nodes[index].position.y + 2 * nodeHeight,
    }

    //new nodes
    const newNode = nodes.concat(
      {
        id: nodeSId1,
        type: "custom",
        position: { x: nodePos1.x, y: nodePos1.y },
        data: {
          label: "",
        },
      },
      {
        id: nodeSId2,
        type: "custom",
        position: { x: nodePos2.x, y: nodePos2.y },
        data: {
          label: "",
        },
      }
    )

    //new edges
    const newEdge = edges.concat(
      {
        id: id + "-" + nodeSId1,
        source: id,
        target: nodeSId1,
        type: "customTrue",
        data: {
          label: "50%",
        },
      },
      {
        id: id + "-" + nodeSId2,
        source: id,
        target: nodeSId2,
        type: "customFalse",
        data: {
          label: "50%",
        },
      }
    )

    dispatch(addNewNode({ newNode, newEdge }))

    const NodePropsParent = {
      arrayId: nodesProps[id]?.arrayId,
      parentId: nodesProps[id]?.parentId,
      children1Id: nodeSId1,
      children2Id: nodeSId2,
      positionX: nodesProps[id]?.positionX,
      positionY: nodesProps[id]?.positionY,
      nodeSide: nodesProps[id]?.nodeSide,
      nodeLevel: nodesProps[id]?.nodeLevel,
    }

    const NodePropsChilden1 = {
      arrayId: nodeId1,
      parentId: id,
      children1Id: null,
      children2Id: null,
      positionX: nodePos1.x,
      positionY: nodePos1.y,
      nodeSide: SideChildren1,
      nodeLevel: nodesProps[id]?.nodeLevel + 1,
    }

    const NodePropsChilden2 = {
      arrayId: nodeId2,
      parentId: id,
      children1Id: null,
      children2Id: null,
      positionX: nodePos2.x,
      positionY: nodePos2.y,
      nodeSide: SideChildren2,
      nodeLevel: nodesProps[id]?.nodeLevel + 1,
    }

    dispatch(updateNodeProps({ id: id, nodeProps: NodePropsParent }))
    dispatch(updateNodeProps({ id: nodeSId1, nodeProps: NodePropsChilden1 }))
    dispatch(updateNodeProps({ id: nodeSId2, nodeProps: NodePropsChilden2 }))

    dispatch(updateSourceNode({ id: id, index: index }))

    setCenter(viewPoint.x, viewPoint.y, {
      zoom: 1,
      duration: 1000,
    })
  }
  const deleteNode = (id: string) => {
    //nodes to be deleted:
    const parentId: string = nodesProps[id]?.parentId!

    const deleteId1: string = nodesProps[parentId]?.children1Id!
    const indexDeleteId1: number = nodes.findIndex((object: any) => {
      return object.id === deleteId1
    })

    const indexEdgeId1: number = edges.findIndex((object: any) => {
      return object.target === deleteId1
    })
    const deleteId2: string = nodesProps[parentId]?.children2Id!
    const indexDeleteId2: number = nodes.findIndex((object: any) => {
      return object.id === deleteId2
    })
    const indexEdgeId2: number = edges.findIndex((object: any) => {
      return object.target === deleteId2
    })

    //check if its possible to delete: they have children?
    if (
      nodesProps[deleteId1!]?.children1Id ||
      nodesProps[deleteId1!]?.children2Id ||
      nodesProps[deleteId2!]?.children1Id ||
      nodesProps[deleteId2!]?.children2Id
    ) {
      dispatch(changeDeleteState({ id: deleteId1, isDeleted: true }))
      dispatch(changeDeleteState({ id: deleteId2, isDeleted: true }))

      Modal.error({
        title: "Oops! It is not possible to delete the selected nodes.",
        content: (
          <p>
            Nodes that have children can not be deleted. Please delete first the
            children nodes and try again.
          </p>
        ),
        okButtonProps: {
          type: "default",
          style: { borderColor: "#ff4d4f", color: "#ff4d4f" },
        },
        afterClose: () => {
          dispatch(changeDeleteState({ id: deleteId1, isDeleted: false }))
          dispatch(changeDeleteState({ id: deleteId2, isDeleted: false }))
        },
      })
    } else {
      //delete
      dispatch(changeDeleteState({ id: deleteId1, isDeleted: true }))
      dispatch(changeDeleteState({ id: deleteId2, isDeleted: true }))

      Modal.confirm({
        title: "Are you sure you want to delete the selected nodes?",
        content: "You will not be able to recover the unsaved changes",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          //delete nodes
          const afterDeleteNodes = []
          for (let i in nodes) {
            if (Number(i) !== indexDeleteId1 && Number(i) !== indexDeleteId2) {
              afterDeleteNodes.push(nodes[i])
            }
          }

          //delete edges
          const afterDeleteEdges = []
          for (let i in edges) {
            if (Number(i) !== indexEdgeId1 && Number(i) !== indexEdgeId2) {
              afterDeleteEdges.push(edges[i])
            }
          }

          dispatch(
            addNewNode({
              newNode: afterDeleteNodes,
              newEdge: afterDeleteEdges,
            })
          )

          //delete props
          dispatch(
            deleteNodeProps({
              id1: deleteId1,
              id2: deleteId2,
              parentId: parentId,
            })
          )

          //delete rules
          dispatch(
            deleteRules({ id1: deleteId1, id2: deleteId2, parentId: parentId })
          )

          //clean source selection if there is no nodes
          if (afterDeleteEdges.length < 1) {
            dispatch(
              submitDataSource([
                {
                  datasetSourceCheck: dataSource[0].datasetSourceCheck,
                  datasetFeatureCheck: false,
                },
                {
                  providerSourceCheck: dataSource[1].providerSourceCheck,
                  providerFeatureCheck: false,
                },
                {
                  databaseSourceCheck: dataSource[2].databaseSourceCheck,
                  databaseFeatureCheck: false,
                },
                {
                  customSourceCheck: dataSource[3].customSourceCheck,
                  customFeatureCheck: false,
                },
                {
                  payloadSourceCheck: dataSource[4].payloadSourceCheck,
                  payloadFeatureCheck: false,
                },
                dataSource[5],
              ])
            )

            for (let k in dataSelected) {
              dispatch(
                selectedDataSource({
                  id: k,
                  selected: {
                    sourceStatus: dataSelected[k].sourceStatus,
                    featureStatus: false,
                    type: dataSelected[k].type,
                  },
                })
              )
            }
          }

          setLoadingDelete(false)
          //setActiveNode(null)
        },
        onCancel: () => {
          dispatch(changeDeleteState({ id: deleteId1, isDeleted: false }))
          dispatch(changeDeleteState({ id: deleteId2, isDeleted: false }))
        },
        cancelButtonProps: { className: "modal-cancelBtn" },
        okButtonProps: { className: "modal-okBtn" },
      })
    }
  }

  useEffect(() => {
    if (nodesProps[id]?.children1Id) {
      setIsDisable(true)
    }
  }, [id, nodesProps])

  let formInitialValues = {}
  if (rules[id].isfinalNode) {
    /// final node
    formInitialValues = {
      feature: null,
      operator: null,
      finalNode: true,
      threshold: null,
      decisionTp: policyGroupFinalType,
      decisionStatus: rules[id].decisionStatus,
      decisionValue: rules[id].decisionValue,
      decisionReason: rules[id].decisionReason,
    }
  } else {
    // rule node
    formInitialValues = {
      feature: rules[id].featureLabel,
      operator: rules[id].operator,
      finalNode: rules[id].isfinalNode,
      threshold: rules[id].threshold,
      decisionTp: null,
      decisionStatus: null,
      decisionValue: null,
      decisionReason: null,
    }
  }

  return (
    <>
      {/* Edit Node - activeNode*/}
      {activeNode && (
        <div
          className={`${styles.custom_node} ${styles.node_color} ${
            rules[id]?.isDeleted && styles.node_color_delete
          }`}
        >
          <div>
            <Form
              layout="horizontal"
              size="small"
              onFinish={submitEditForm}
              className={styles.custom_node_form}
              requiredMark={false}
              initialValues={formInitialValues}
            >
              <div className={styles.custom_node_header}>
                <Row>
                  <Col flex={4} className={` ${styles.custom_node_btn_header}`}>
                    {id !== "N0" && (
                      <Space size="small" direction="horizontal">
                        <Switch
                          checked={isFinalNode}
                          size="small"
                          onChange={(checked: boolean) => {
                            setIsFinalNode(checked)
                          }}
                        />
                        <span> Final node </span>
                      </Space>
                    )}
                  </Col>
                  <Col flex={1} className="container-row-reverse">
                    <div>
                      {id !== "N0" && (
                        <Tooltip title="Delete node">
                          <Button
                            type="text"
                            className={styles.btn_color}
                            shape="circle"
                            size="small"
                            icon={<DeleteOutlined />}
                            onClick={() => deleteNode(id)}
                          />
                        </Tooltip>
                      )}
                      <Tooltip title="Save node">
                        <Button
                          className={styles.btn_color}
                          type="text"
                          shape="circle"
                          size="small"
                          icon={<SaveOutlined />}
                          htmlType="submit"
                        />
                      </Tooltip>

                      <Tooltip title="Close">
                        <Button
                          className={styles.btn_color}
                          type="text"
                          shape="circle"
                          size="small"
                          icon={<CloseOutlined />}
                          onClick={() => {
                            if (
                              isFinalNode &&
                              !rules[id].decisionStatus &&
                              !rules[id].decisionValue
                            ) {
                              setIsFinalNode(false)
                            }
                            setActiveNode(null)
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
              </div>

              {!isFinalNode && <FeatureSetUp required={!isFinalNode} id={id} />}

              {isFinalNode && (
                <>
                  <Form.Item label="Type">
                    <Tag
                      style={{
                        fontSize: 14,
                        borderColor: "#2394c5",
                        color: "#2394c5",
                      }}
                    >
                      {policyGroupFinalType!.charAt(0).toUpperCase() +
                        policyGroupFinalType!.substring(1)}
                    </Tag>
                  </Form.Item>

                  {policyGroupFinalType === "status" && (
                    <Form.Item
                      label="Decision"
                      name="decisionStatus"
                      rules={[
                        {
                          required: isDecisionStatus,
                          message: "Please select a status",
                        },
                      ]}
                    >
                      <Select
                        className="nodrag"
                        placeholder="Select"
                        size="small"
                        options={selectItemsDecision}
                        getPopupContainer={(node) => node.parentNode}
                      />
                    </Form.Item>
                  )}

                  {policyGroupFinalType === "value" && (
                    <Form.Item
                      label="Value"
                      name="decisionValue"
                      rules={[
                        {
                          required: isDecisionValue,
                          message: "Please insert a value",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Insert here"
                        size="small"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}

                  {policyGroupFinalType && (
                    <Form.Item
                      label="Reason"
                      name="decisionReason"
                      rules={[
                        {
                          required: isFinalNode,
                          message: "Please insert the reason of this decision",
                        },
                      ]}
                    >
                      <TextArea
                        size="small"
                        style={{ width: "100%" }}
                        autoSize={true}
                        maxLength={100}
                        placeholder="Insert the reason here"
                      />
                    </Form.Item>
                  )}
                </>
              )}
            </Form>
          </div>

          <Handle type="source" position={Position.Bottom} id={id} />
          <Handle type="target" position={Position.Top} id={id} />
        </div>
      )}

      {/* Display Node*/}
      {!activeNode && (
        <Tooltip
          title={
            rules[id].decisionReason
              ? `ID: ${id.toUpperCase()} - Reason: ${rules[id].decisionReason}`
              : `ID: ${id.toUpperCase()}`
          }
        >
          <div
            className={`${styles.custom_node} 
					${rules[id]?.threshold ? styles.node_color : styles.node_color_disable}
					${isFinalNode && policyGroupFinalType === "value" && styles.node_color_value}
					${
            isFinalNode &&
            rules[id].decisionStatus === "approved" &&
            styles.node_color_approved
          }
					${
            isFinalNode &&
            rules[id].decisionStatus === "reproved" &&
            styles.node_color_reproved
          }
					${
            isFinalNode &&
            rules[id].decisionStatus === "manual analysis" &&
            styles.node_color_manual
          }
					${isFinalNode && rules[id].isDeleted && styles.node_color_delete}
					`}
          >
            {rules[id]?.threshold || rules[id]?.isfinalNode ? (
              <div className={styles.custom_node_header}>
                <Row>
                  <Col span={12} className="container-row">
                    <h6>{`${
                      id === "N0"
                        ? datasetName ?? "Applied Rule"
                        : isFinalNode
                        ? "Final Node"
                        : "Applied Rule"
                    }`}</h6>
                  </Col>
                  <Col span={12} className="container-row-reverse">
                    <div className={styles.btn_container}>
                      {id !== "N0" && (
                        <Tooltip title="Delete node">
                          <Button
                            className={` 
												${!isFinalNode && styles.btn_color}
												${isFinalNode && policyGroupFinalType === "value" && styles.btn_color}
												${
                          isFinalNode &&
                          policyGroupFinalType === "status" &&
                          rules[id]?.decisionStatus === "approved" &&
                          styles.btn_color_approved
                        }
												${
                          isFinalNode &&
                          policyGroupFinalType === "status" &&
                          rules[id]?.decisionStatus === "reproved" &&
                          styles.btn_color_reproved
                        }
												${
                          isFinalNode &&
                          policyGroupFinalType === "status" &&
                          rules[id]?.decisionStatus === "manual analysis" &&
                          styles.btn_color_manual
                        }
												`}
                            type="text"
                            shape="circle"
                            size="small"
                            icon={<DeleteOutlined />}
                            onClick={() => deleteNode(id)}
                          />
                        </Tooltip>
                      )}

                      <Tooltip title="Edit node">
                        <Button
                          className={` 
												${!isFinalNode && styles.btn_color}
												${isFinalNode && policyGroupFinalType === "value" && styles.btn_color}
												${
                          isFinalNode &&
                          rules[id]?.decisionStatus === "approved" &&
                          styles.btn_color_approved
                        }
												${
                          isFinalNode &&
                          rules[id]?.decisionStatus === "reproved" &&
                          styles.btn_color_reproved
                        }
												${
                          isFinalNode &&
                          rules[id]?.decisionStatus === "manual analysis" &&
                          styles.btn_color_manual
                        }
												`}
                          type="text"
                          shape="circle"
                          size="small"
                          icon={<EditOutlined />}
                          onClick={() => setActiveNode(id)}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className={styles.btn_edit_disable_container}>
                {id === "N0" ? (
                  <span> Click here to start</span>
                ) : (
                  <span> Click here to edit </span>
                )}
                <Button
                  className={styles.btn_edit_disable}
                  type="text"
                  shape="circle"
                  size="large"
                  icon={<EditOutlined />}
                  onClick={() => setActiveNode(id)}
                />
              </div>
            )}

            <div className={styles.node_details}>
              {
                // full set: source, book, feature
                rules[id]?.threshold &&
                  rules[id]?.featureLabel.length === 3 && (
                    <>
                      <span>{rules[id]?.featureLabel[0]}</span> <br />
                      <span>{rules[id]?.featureLabel[2]}</span>{" "}
                      <strong>{rules[id]?.operator?.toUpperCase()}</strong>{" "}
                      <span> {rules[id]?.threshold}</span>
                      {rules["N0"].volumetry && id === "N0" && (
                        <div className={styles.node_details_initial_volumetry}>
                          Initial volumetry {rules[id].volumetry} ({" "}
                          {Math.round(
                            ((100 * rules[id].volumetry!) /
                              rules["N0"].volumetry) *
                              10
                          ) / 10}
                          %)
                        </div>
                      )}
                    </>
                  )
              }

              {
                // short set: source, feature
                rules[id]?.threshold && rules[id]?.featureLabel.length < 3 && (
                  <>
                    <span>{rules[id]?.featureLabel[0]}</span> <br />
                    <span>{rules[id]?.featureLabel[1]}</span>{" "}
                    <strong>{rules[id]?.operator?.toUpperCase()}</strong>{" "}
                    <span> {rules[id]?.threshold}</span>
                    {rules["N0"].volumetry && id === "N0" && (
                      <div className={styles.node_details_initial_volumetry}>
                        Initial volumetry {rules[id].volumetry} ({" "}
                        {Math.round(
                          ((100 * rules[id].volumetry!) /
                            rules["N0"].volumetry) *
                            10
                        ) / 10}
                        %)
                      </div>
                    )}
                  </>
                )
              }

              {rules[id]?.isfinalNode && (
                <div className={styles.node_details_decision}>
                  {policyGroupFinalType === "value" && (
                    <Tag className={styles.decision_tag} color="#2394c5">
                      {`Value ${rules[id]?.decisionValue} `}
                    </Tag>
                  )}

                  {policyGroupFinalType === "status" &&
                    rules[id]?.decisionStatus === "approved" && (
                      <Tag color="#6ED178">Approved</Tag>
                    )}

                  {policyGroupFinalType === "status" &&
                    rules[id]?.decisionStatus === "reproved" && (
                      <Tag color="#ff4d4f">Reproved</Tag>
                    )}

                  {policyGroupFinalType === "status" &&
                    rules[id]?.decisionStatus === "manual analysis" && (
                      <Tag color="#EEBB16">Manual Analysis</Tag>
                    )}
                  {rules["N0"].volumetry && (
                    <div className={styles.node_details_final_volumetry}>
                      Final volumetry {rules[id].volumetry} (
                      {Math.round(
                        ((100 * rules[id].volumetry!) / rules["N0"].volumetry) *
                          10
                      ) / 10}
                      %)
                    </div>
                  )}
                </div>
              )}
            </div>

            <Handle type="source" position={Position.Bottom} id={id} />
            <Handle type="target" position={Position.Top} id={id} />
          </div>
        </Tooltip>
      )}
    </>
  )
}

export default CustomNode
