//react
import React from "react"

//amplify
import { API } from "aws-amplify"

//redux
import { useAppSelector } from "../../redux-store/hooks"

//antd
import { Button } from "antd"

//services
import { BacktestPolicy } from "../../services/policy-database-service/backtest-policy"
import { getPolicyList } from "../../services/policy-database-service"

//types
import { policyJson, INodeRule } from "../../types"
import { getProviderList } from "../../services/provider-database-service/get-provider-list"

type Props = {}

const Home = (props: Props) => {
  //auth state
  const { user } = useAppSelector((state) => state.auth)

  let username: string | null | undefined
  const index: number | null | undefined = user?.email!.indexOf("@")
  username = user?.email!.substring(0, index)

  const GetAllExample = async () => {
    const response = await API.get(
      "providerCatalogueAPI",
      "/providercatalogue",
      {}
    )
    console.log(response)
  }

  const CallBacktest = async () => {
    const response = await BacktestPolicy({
      policyId: "0d6c3631-0f2b-11ee-b84c-81a803623ac3",
    })
    return response.datasetId
  }

  const callAPI = async () => {
    const response = await API.get("policiesAPI", "/policies", {})
    const responseBody = JSON.parse(response.body)
    console.log("Policy API LIST", responseBody)
  }

  const clonePolicy = async () => {
    const policies = await getPolicyList()

    for (let i in policies) {
      let finalType: string | null = null
      let newNodeRules: { [nodeId: string]: INodeRule } = {}
      for (let j in policies[i].nodes.nodeRules) {
        newNodeRules[j] = {
          feature: policies[i].nodes.nodeRules[j].feature,
          featureLabel: policies[i].nodes.nodeRules[j].feature,
          dataSourceType: policies[i].nodes.nodeRules[j].dataSourceType,
          operator: policies[i].nodes.nodeRules[j].operator,
          threshold: policies[i].nodes.nodeRules[j].threshold,
          isfinalNode: policies[i].nodes.nodeRules[j].isfinalNode,
          decisionType: policies[i].nodes.nodeRules[j].decisionType,
          decisionStatus: policies[i].nodes.nodeRules[j].decisionStatus,
          decisionValue: policies[i].nodes.nodeRules[j].decisionValue,
          isDecisionStatus: policies[i].nodes.nodeRules[j].isDecisionStatus,
          isDecisionValue: policies[i].nodes.nodeRules[j].isDecisionValue,
          isDeleted: policies[i].nodes.nodeRules[j].isDeleted,
          volumetry: policies[i].nodes.nodeRules[j].volumetry,
          decisionReason: policies[i].nodes.nodeRules[j].decisionReason,
        }

        if (policies[i].nodes.nodeRules[j].decisionType)
          finalType = policies[i].nodes.nodeRules[j].decisionType
      }

      const selList: string[] = []
      let datasetCheck: boolean = false
      if (policies[i].dataset) {
        datasetCheck = true
        selList.push("sourceDataset")
      }

      if (policies[i].dataSource.source[1].providerSourceCheck)
        selList.push("sourceProvider")
      if (policies[i].dataSource.source[2].databaseSourceCheck)
        selList.push("sourceDatabase")
      if (policies[i].dataSource.source[3].customSourceCheck)
        selList.push("sourceCustomFeatures")
      if (policies[i].dataSource.source[4].payloadSourceCheck)
        selList.push("sourcePayload")

      //update new info
      const clonePolicy: policyJson = {
        policy_record_id: policies[i].policy_record_id,
        policy_id: policies[i].policy_id,
        policy_group_name: policies[i].name,
        policy_group_description: policies[i].description,
        policy_group_final_type:
          policies[i].policy_group_final_type ?? finalType,
        created_by: policies[i].created_by,
        name: policies[i].name,
        description: policies[i].description,
        dataset: policies[i].dataset,
        datasetPath: policies[i].datasetPath,
        payload: policies[i].payload ?? {
          input: {
            requestPayload: [
              {
                field: "cpf",
                description: "Brazillian document (CPF)",
                dataType: "STRING",
                allowedValues: "format 00000000000 ",
              },
            ],
            cascaderPayloadList: [],
          },
          output: [],
        },
        dataSource: {
          source: [
            {
              datasetSourceCheck: datasetCheck,
              datasetFeatureCheck:
                policies[i].dataSource.source[0].datasetFeatureCheck,
            },
            {
              providerSourceCheck:
                policies[i].dataSource.source[1].providerSourceCheck,
              providerFeatureCheck:
                policies[i].dataSource.source[1].providerFeatureCheck,
            },
            {
              databaseSourceCheck:
                policies[i].dataSource.source[2].databaseSourceCheck,
              databaseFeatureCheck:
                policies[i].dataSource.source[2].databaseFeatureCheck,
            },
            {
              customSourceCheck:
                policies[i].dataSource.source[3].customSourceCheck,
              customFeatureCheck:
                policies[i].dataSource.source[3].customFeatureCheck,
            },
            {
              payloadSourceCheck:
                policies[i].dataSource.source[4].payloadSourceCheck,
              payloadFeatureCheck:
                policies[i].dataSource.source[4].payloadFeatureCheck,
            },
            selList,
          ],
          selected: {},
        },
        metabasePath: policies[i].metabasePath,
        datasetDecisionPath: policies[i].datasetDecisionPath,
        datasetId: policies[i].datasetId,
        dataProviders: policies[i].dataProviders,
        created_at: policies[i].created_at,
        updated_at: policies[i].updated_at,
        updated_by: policies[i].updated_by,
        policy_status: policies[i].policy_status,
        rule: policies[i].rule,
        nodes: {
          nodeProps: policies[i].nodes.nodeProps,
          nodeRules: newNodeRules,
        },
      }

      try {
        await API.post("policiesAPI", "/policies", {
          body: clonePolicy,
        })
        console.log("put", i, clonePolicy)
      } catch (error: unknown) {
        if (error instanceof Error) {
          const saveError = new Error(error.message)
          console.log(saveError)
        }
      }
    }

    console.log("finish")
  }

  return (
    <div className="container-page">
      <h3>Hello {username}</h3>
      <h2>Welcome to PoD Decision Engine! </h2>

      {/* <Button
				size="large"
				type="primary"
				onClick={GetAllExample}
			>
				Get All ProviderTable Example
			</Button>  
			<Button
				size="large"
				type="primary"
				onClick={CallBacktest}
				>
					Backtest example
				</Button>*/}

      {/* <Button size="large" type="primary" onClick={callAPI}>
        CALL API
      </Button> */}
    </div>
  )
}

export default Home
