//css
import styles from "./payload.module.css"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setActiveStep } from "../../redux-store/new-policy-reducer/active-step-slice"
import { submmitPayloadIn, submmitPayloadOut } from "../../redux-store/new-policy-reducer/new-policy-slice"

//antd
import { Button, Form, Space, Row, Col, Tooltip, Checkbox } from "antd"
import { CheckboxValueType } from "antd/es/checkbox/Group";

const outputData: { value: string; label: string; description: string; disabled:boolean }[] = [
  { value: "decision", label: "Decision", description: "Final decision" , disabled: true},
  { value: "decisionReason", label: "Decision's reason", description: "Reason of the final decision", disabled: false},
  { value: "fullLog", label: "Decision path", description: "Description of the complete rule path to the final decision" , disabled: false},
  { value: "features", label: "Features", description: "Features considered to determine the final decision" , disabled: false},

]

type Props = {
  componentId: number
}

const PayloadOut = ({ componentId }: Props) => {
  const dispatch = useAppDispatch()
  const { payloadOut } = useAppSelector((state) => state.newPolicy)

  const onChangeSelectOutput = (checkedValues: CheckboxValueType[]) => {
    console.log(checkedValues)
    dispatch(submmitPayloadOut(checkedValues))
  }

  return (
    <div className={styles.container_form}>
      <div className={styles.container_form}>
        <div>
          <Form layout="vertical">
            <Form.Item
              label="Select output data"
              tooltip="Select here the ouput data that will be recived as a response from the policy request."
            >
              <Checkbox.Group
                className={styles.container_checkbox}
                onChange={onChangeSelectOutput}
                defaultValue={payloadOut}
              >
                <Row gutter={[20, 20]}>
                  {outputData.map((data) => (
                    <Col span={12} key={data.value}>
                      <Tooltip title={data.description}>
                        <Checkbox value={data.value} disabled={data.disabled} >{data.label}</Checkbox>
                      </Tooltip>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </div>

        <div className={styles.container_button}>
          <Space size="large">
            <Form.Item>
              <Button size="large" type="primary" onClick={() => dispatch(setActiveStep(componentId + 1))}>
                Continue
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                onClick={() => dispatch(setActiveStep(componentId - 1))}
              >
                Back
              </Button>
            </Form.Item>
          </Space>
        </div>
      </div>
    </div>
  )
}

export default PayloadOut
