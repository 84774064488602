//css
import styles from "./new-policy.module.css"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import {
  resetActiveStep,
  setActiveStep,
  setIsPolicySaved,
} from "../../redux-store/new-policy-reducer/active-step-slice"
import { resetNode } from "../../redux-store/new-policy-reducer/node-slice"
import { resetNewPolicy } from "../../redux-store/new-policy-reducer/new-policy-slice"

//antd
import { Modal, Steps, message } from "antd"

//components
import InitialData from "../../components/policy/initial-data"
import ChooseDataSource from "../../components/policy/choose-data-source"
import CreateRules from "../../components/policy/create-rules"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt"
import PayloadIn from "../../components/policy/payload-in"
import BackupRules from "../../components/policy/backup-rules"
import PolicyGroup from "../../components/policy/policy-group";
import PayloadOut from "../../components/policy/payload-out";

const NewPolicy = () => {
  const dispatch = useAppDispatch()
  const { activeStep } = useAppSelector((state) => state.activeStep)
  const { policyName, description, policyGroupName, cascaderFeatureList } =
    useAppSelector((state) => state.newPolicy)

  //states user to prevent loss of data
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog)

  useEffect(() => {
    if (showPrompt) {
      Modal.confirm({
        title: "Are you sure that you want to leave the current page?",
        content: "The changes that you made won't be saved.",
        okText: "Yes",
        cancelText: "No",
        onCancel: cancelNavigation,
        onOk: confirmNavigation,
        cancelButtonProps: { className: "modal-cancelBtn" },
        okButtonProps: { className: "modal-okBtn" },
        width: 500,
      })
    }
  }, [showPrompt, cancelNavigation, confirmNavigation])

  const onChange = (id: number) => {
    if (id === 0) {
      dispatch(setActiveStep(id))
    }

    if (policyName && description && cascaderFeatureList) {
      dispatch(setActiveStep(id))
    } else if (policyName && description && id === 1) {
      dispatch(setActiveStep(id))
    }

    if ((id === 1 || id === 2) && (!policyName || !description)) {
      message.error("Please fill Initial data form before continue.")
    }

    if (id === 2 && policyName && description && !cascaderFeatureList) {
      message.error("Please choose data source before continue.")
    }
  }

  return (
    <>
      <div className={styles.container_page}>
        <h5 className={styles.title}>
          {" "}
          {policyName && `Policy: ${policyName} (group: ${policyGroupName})`}
        </h5>

        <div className="container-row ">
          <div className={styles.container_steps}>
            <Steps
              current={activeStep.id}
              //onChange={onChange}
              direction="vertical"
              items={[
                {
                  title: "Group",
                  description: <br></br>,
                },
                {
                  title: "Details",
                  description: <br></br>,
                },
                {
                  title: "Input Data",
                  description: <br></br>,
                },
                {
                  title: "Output Data",
                  description: <br></br>,
                },
                {
                  title: "Data Source",
                  description: <br></br>,
                },
                {
                  title: "Rules",
                  description: <br></br>,
                } /*,
              {
                title: "Backup Rules",
                description: <br></br>,
              },*/,
              ]}
            />
          </div>

          <div className={styles.container_components}>
            <div className={styles.container_form}>
              {activeStep.id === 0 && (
                <PolicyGroup componentId={0} />
              )}
              {activeStep.id === 1 && (
                <InitialData componentId={1} showBtn={true} />
              )}
              {activeStep.id === 2 && <PayloadIn componentId={2} />}
              {activeStep.id === 3 && <PayloadOut componentId={3} />}
              {activeStep.id === 4 && <ChooseDataSource componentId={4} />}
            </div>

            {activeStep.id === 5 && <CreateRules componentId={5} />}

            {activeStep.id === 6 && (
              <BackupRules componentId={6} showBtn={false} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default NewPolicy
