import { Progress } from "antd"
import styles from "./custom-edge.module.css"

import React, { FC, useEffect, useState } from "react"
import {
  EdgeProps,
  getBezierPath,
  getStraightPath,
  EdgeLabelRenderer,
} from "reactflow"
import { useAppSelector } from "../../redux-store/hooks"

const CustomEdgeFalse: FC<EdgeProps> = ({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    //sourcePosition,
    targetX,
    targetY,
    //targetPosition,
  })

  const { datasetName, rules } = useAppSelector((state) => state.newPolicy)

  const [isShowInfo, setShowInfo] = useState<boolean>(false)

  useEffect(() => {
    if (rules[target].volumetry) {
      setShowInfo(true)
    } else {
      setShowInfo(false)
    }
  }, [rules[target].volumetry])

  return (
    <>
      <path id={id} className="react-flow__edge-path" d={edgePath} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
          className={`nodrag nopan ${styles.custom_edge} ${styles.custom_edge_false}`}
        >
          {!datasetName && source === "N0" && (
            <div className={styles.custom_edge_false_no_dataset}>
              <span>false</span>
            </div>
          )}

          {source === "N0" && datasetName && <span>false</span>}
          {datasetName && (
            <Progress
              type="circle"
              percent={
                Math.round(
                  ((100 * rules[target].volumetry!) /
                    rules[source].volumetry!) *
                    10
                ) / 10
              }
              size={50}
              strokeColor="#ff4d4f"
              showInfo={isShowInfo}
              className={`progress_circle_false ${styles.custom_edge_form} ${styles.custom_edge_progress_false}`}
            />
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  )
}

export default CustomEdgeFalse
