
//antd
import { Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table"

//types
import { providerBookListType } from "../../types"


type Props = {
	scrollBody: any
	connectionBookList: any
}

const ConnectionBookTable = ({ scrollBody, connectionBookList }: Props) => {
	let dataSource = connectionBookList

	const columns: ColumnsType<providerBookListType> = [
		{
			title: "Provider",
			dataIndex: "provider",
			key: "provider",
		},
		{
			title: "Books",
			key: "dataProviders",
			dataIndex: "dataProviders",
			render: (_, { bookList }) => (
				<>
					{bookList?.map((book) => {
						let color = "geekblue"
						if (book) {
							return (
								<Tag style={{ marginTop: "2px", marginBottom:"2px" }}
                color={color} key={book}>
									{book.toUpperCase()}
								</Tag>
							)
						}
					})}
				</>
			),
		},
	]

	return (
		<>
    {dataSource.length>0 && 
			<Table
				dataSource={dataSource}
				columns={columns}
				bordered
				size="small"
				scroll={scrollBody}
				pagination={dataSource.length > 5 ? {
          pageSize: 5
        } : false}

        
			/>}
		</>
	)
}

export default ConnectionBookTable
