//css
import styles from "./dataset.module.css"

//react
import { ReactNode, useEffect, useState } from "react"

//router
import { useNavigate, useParams } from "react-router-dom"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import {
  resetDatasetProps,
  setDatasetProps,
} from "../../redux-store/upload-dataset/dataset-setup-slice"
import { logout } from "../../redux-store/auth-reducer/authentication-thunks"
import { setAlert } from "../../redux-store/upload-dataset/upload-dataset-slice"

//services & servers
import {
  getDatasetById,
  putDataset,
} from "../../services/dataset-database-service"
import { getDatasetPropsFromS3 } from "../../services/read-s3-dataset-service/get-dataset-properties-S3"

//antd
import { Button, Form, Space, Divider, Spin, Modal, Result } from "antd"

//hooks
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt"

//types
import { datasetDb } from "../../types"

//components
import NotFound from "../../components/not-found/not-found"
import FeaturesSetupTable from "../../components/dataset/feature-setup-table"
import DetailsInputs from "../../components/dataset/details-inputs"

//functions
import { saveDataset } from "../../functions/dataset/save-dataset"
import { modalError } from "../../components/dataset/modal-error"

const DatasetSetup = () => {
  const { id } = useParams()

  let navigate = useNavigate()

  const dispatch = useAppDispatch()

  //redux states
  const { user } = useAppSelector((state) => state.auth)

  const { datasetProps } = useAppSelector((state) => state.datasetProps)

  //states
  const [loading, setLoading] = useState<boolean>(true)

  const [loadingSave, setLoadingSave] = useState<{
    loading: boolean
    success: boolean
  }>({ loading: false, success: false })

  //states user to prevent loss of data
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog)

  //error messages
  let generalAlertMessage: ReactNode = <p></p>
  let fileNotFoundAlertMessage: ReactNode = <p></p>
  if (datasetProps.datasetName) {
    generalAlertMessage = (
      <p>
        Oops! Something went wrong. Please try again later. You can check the
        status of your dataset <strong>{datasetProps.datasetName}</strong> at
        the Dataset's page. If the error persists contact the support for more
        help.
      </p>
    )
  }
  if (datasetProps.fileName) {
    fileNotFoundAlertMessage = (
      <p>
        Oops! Something went wrong. File{" "}
        <strong>{datasetProps.fileName!.replace(id! + "_", " ")}</strong> was
        not found! Please click the change file button to upload again. If the
        error persists contact the support for more help.
      </p>
    )
  }

  useEffect(() => {
    if (showPrompt) {
      Modal.confirm({
        title: "Are you sure that you want to leave the current page?",
        content: "The changes that you made won't be saved.",
        okText: "Yes",
        cancelText: "No",
        onCancel: cancelNavigation,
        onOk: confirmNavigation,
        cancelButtonProps: { className: "modal-cancelBtn" },
        okButtonProps: { className: "modal-okBtn" },
        width: 500,
      })
    }
  }, [showPrompt, cancelNavigation, confirmNavigation])

  // get dataset list from db and set redux states
  const getData = async () => {
    setLoading(true)
    try {
      //get data from database
      const data = await getDatasetById(id!)
      dispatch(setDatasetProps(data))
    } catch (error: unknown) {
      if (error instanceof Error) {
        const saveError = new Error(error.message)
        console.log(saveError)
      }
    }
    setLoading(false)
  }

  //check if file was correctly processed
  const checkFile = async () => {
    if (
      !datasetProps.size ||
      !datasetProps.qtdRows ||
      !datasetProps.featureList ||
      !datasetProps.sample
    ) {
      try {
        const response = await getDatasetPropsFromS3({
          fileName: datasetProps.fileName!
        })

        if (!response?.error) {
          const updatedFileDataset = {
            datasetId: datasetProps.datasetId,
            uploadBy: datasetProps.uploadBy,
            uploadAt: datasetProps.uploadAt,
            updatedAt: datasetProps.updatedAt,
            updatedBy: datasetProps.updatedBy,
            datasetName: datasetProps.datasetName,
            fileName: datasetProps.fileName,
            storagePath: datasetProps.storagePath,
            policies: datasetProps.policies,
            description: datasetProps.description,
            size: response?.size,
            qtdRows: response?.records,
            sample: response?.sample,
            featureList: response!.features,
            providerBookList: [],
            providerFeatureList: [],
          }

          try {
            // update database with new file info
            const result = await putDataset(updatedFileDataset)

            dispatch(setDatasetProps(updatedFileDataset))

            dispatch(
              setAlert({
                alertMessage: null,
                alertType: undefined,
              })
            )
          } catch (error: unknown) {
            dispatch(
              setAlert({
                alertMessage: generalAlertMessage,
                alertType: "error",
              })
            )
          }
        } else {
          dispatch(
            setAlert({
              alertMessage: fileNotFoundAlertMessage,
              alertType: "error",
            })
          )
        }
      } catch {
        modalError({
          name: datasetProps.datasetName!,
          type: "credential",
          okAction: () => {
            dispatch(logout())
            return false
          },
          closeAction: () => {},
        })
      }
    }
  }

  useEffect(() => {
    getData()
    if (datasetProps.datasetId) checkFile()
  }, [datasetProps.fileName])

  const saveNewSettings = async () => {
    setShowDialog(false)
    setLoadingSave({ loading: true, success: false })

    //changes
    const updatedFileDataset: datasetDb = {
      datasetId: id!,
      uploadBy: datasetProps.uploadBy,
      uploadAt: datasetProps.uploadAt,
      updatedAt: new Date().toLocaleString(),
      updatedBy: user?.email!,
      datasetName: datasetProps.datasetName,
      fileName: datasetProps.fileName,
      storagePath: datasetProps.storagePath,
      policies: datasetProps.policies,
      description: datasetProps.description,
      size: datasetProps.size,
      qtdRows: datasetProps.qtdRows,
      sample: datasetProps.sample,
      featureList: datasetProps.featureList,
      providerBookList: datasetProps.providerBookList,
      providerFeatureList: datasetProps.providerFeatureList,
    }

    const saveStatus: boolean = await saveDataset({
      datasetProps: updatedFileDataset
    })
    if (saveStatus) {
      setLoadingSave({ loading: false, success: true })
    } else {
      setLoadingSave({ loading: false, success: false })
    }
  }

  const cancelNewSettings = () => {
    navigate("/datasets")
    dispatch(setAlert({ alertMessage: null, alertType: undefined }))
  }

  return (
    <>
      {loadingSave.success ? (
        <div>
          <Result
            className={styles.container_saved_policy}
            status="success"
            title={`The applied changes to ${datasetProps.datasetName} was successfully saved!`}
            subTitle={`You can check its status at the Datasets Page`}
            extra={[
              <Button
                key="saveSucessMessage"
                type="primary"
                onClick={() => {
                  setLoadingSave({ loading: false, success: false })
                  dispatch(resetDatasetProps())
                  navigate("/datasets")
                }}
              >
                Go to Datasets Page
              </Button>,
            ]}
          />
        </div>
      ) : (
        <div>
          <Spin tip="Loading..." spinning={loading} size="large">
            {datasetProps.datasetId && (
              <div className={`container-page ${styles.table_container}`}>
                <Form
                  layout="vertical"
                  requiredMark={false}
                  onFinish={saveNewSettings}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey === false) {
                      e.preventDefault()
                    }
                  }}
                >
                  <div className={styles.divider}>
                    <Divider orientation="left" orientationMargin="0">
                      <h6>Details</h6>
                    </Divider>

                    <DetailsInputs setState={setShowDialog} />
                  </div>

                  <div>
                    <Divider orientation="left" orientationMargin="0">
                      <h6>Features</h6>
                    </Divider>

                    <FeaturesSetupTable
                      setState={setShowDialog}
                      styleProps={{
                        searchWidth: "40%",
                        selectWidth: "100%",
                        scrollBody: { y: "85vh" },
                      }}
                    />
                  </div>

                  <Space size="large" className={styles.container_reverse}>
                    <Form.Item>
                      <Button
                        type="primary"
                        //onClick={saveNewSettings}
                        loading={loadingSave.loading}
                        disabled={!showDialog}
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </Form.Item>

                    <Form.Item>
                      <Button onClick={cancelNewSettings}>Cancel</Button>
                    </Form.Item>
                  </Space>
                </Form>
              </div>
            )}

            {!loading && !datasetProps.datasetId && (
              <NotFound
                buttonText="Go to Datasets Page"
                buttonUrl="/datasets"
              />
            )}
          </Spin>
        </div>
      )}
    </>
  )
}

export default DatasetSetup
