export function formatDateUnix (dt: string) {
		if (dt.includes("T") || dt.includes("Z")) {
			const [dateComponents, timeComponents] = dt.split("T")
			const [year, month, day] = dateComponents.split("-")
			const [hours, minutes, seconds] = timeComponents.split(":")

			const date = new Date(+year, +month - 1, +day, +hours, +minutes).getTime()

			return date
		} else {
			const [dateComponents, timeComponents] = dt.split(",")
			const [day, month, year] = dateComponents.split("/")
			const [hours, minutes, seconds] = timeComponents.split(":")

			const date = new Date(
				+year,
				+month - 1,
				+day,
				+hours,
				+minutes,
				+seconds
			).getTime()

			return date
		}
	}