//css
import styles from "./feature-setup.module.css"

//react
import { useEffect, useState } from "react"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setDatasetProps } from "../../redux-store/upload-dataset/dataset-setup-slice"

//antd
import { Alert, Cascader, Input, Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import type { DefaultOptionType } from "antd/es/cascader"

//types
import { featuresDataType } from "../../types"

//services
import { newSampleCatalog } from "../providers/sample-catalog"

const { Search } = Input

const filter = (inputValue: string, path: DefaultOptionType[]) =>
  path.some(
    (option) =>
      (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) >
      -1
  )

type Props = {
  setState: React.Dispatch<React.SetStateAction<any>> | null
  styleProps: { searchWidth: string; selectWidth: string; scrollBody: any }
}
const FeaturesSetupTable = ({ setState, styleProps }: Props) => {
  const dispatch = useAppDispatch()

  //redux states
  const { datasetProps } = useAppSelector((state) => state.datasetProps)

  const [searchedFeature, setSearchedFeature] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true)

  //get dataset list
  const options: any[] = [
    {
      key: "uii0",
      label: "Input Data",
      value: "payload",
      image: null,
      description: "payload",
    },
  ]
  const getData = async () => {
    setLoading(true)
    try {
      for (let i in newSampleCatalog) {
        for (let k in newSampleCatalog[i].data) {
          options.push(newSampleCatalog[i].data[k].description)
        }
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [options])

  const updateFeatureList = (
    value: string[],
    selectedOptions: any[],
    recordKey: number
  ) => {
    if (setState) setState(true)
    let stringstate = JSON.stringify(datasetProps)
    let tempList = JSON.parse(stringstate)

    let label: string[] = [selectedOptions[0].label]
    let dataType: string = ""
    let allowedValues: string = ""

    if (selectedOptions.length === 2) {
      label = [selectedOptions[0].label, selectedOptions[1].label]
      dataType = selectedOptions[1].dataType
      allowedValues = selectedOptions[1].allowedValues
    }

    if (selectedOptions[0].type === "provider") {
      label = [
        selectedOptions[0].label,
        selectedOptions[1].label,
        selectedOptions[2].label,
      ]
      dataType = selectedOptions[2].dataType
      allowedValues = selectedOptions[2].allowedValues
    }

    tempList.featureList[recordKey].provider = value
    tempList.featureList[recordKey].providerLabel = label
    tempList.featureList[recordKey].sourceType = selectedOptions[0].type
    tempList.featureList[recordKey].dataType = dataType
    tempList.featureList[recordKey].allowedValues = allowedValues
    dispatch(setDatasetProps(tempList))
  }

  // table definition
  const columns: ColumnsType<featuresDataType> = [
    {
      title: "Feature",
      dataIndex: "feature",
      key: "feature",
      filteredValue: [searchedFeature],
      onFilter: (value: string | number | boolean, record: featuresDataType) =>
        String(record.feature)
          .toLowerCase()
          .includes(String(value).toLowerCase()),
    },
    {
      title: "Data connection",
      key: "action",
      render: (_, record) => (
        <Cascader
          options={options}
          style={{ width: styleProps.selectWidth }}
          allowClear
          onChange={(value: any, selectedOptions: any) => {
            updateFeatureList(value, selectedOptions, record.key)
          }}
          placeholder="Select a feature from your favorite connections"
          showSearch={{ filter }}
          defaultValue={record.providerLabel ?? undefined}
          className={styles.cascader}
          size="small"
        />
      ),
      align: "center",
    },
  ]

  return (
    <>
      <Alert
        message="The correct setup of features is very important step to ensure the quality of the decision process. This is essential to guarantee that the correct data connection will be used."
        showIcon
        type="warning"
        className={styles.error_alert}
      />

      <Search
        className={styles.feature_search_bar}
        placeholder="Search feature name"
        allowClear
        onSearch={(value) => setSearchedFeature(value)}
        size="large"
        style={{ width: styleProps.searchWidth }}
      />

      <Table
        className={styles.table_features}
        bordered
        columns={columns}
        dataSource={datasetProps?.featureList}
        size="small"
        pagination={{
          pageSize: datasetProps?.featureList.length,
          position: [],
        }}
        scroll={styleProps.scrollBody}
      />
    </>
  )
}

export default FeaturesSetupTable
