//css
import styles from "./notification-panel.module.css"

//ant
import { Tabs, Popover, Badge } from "antd"
import { BellOutlined } from "@ant-design/icons"


const NotificationPanel = () => {
	const content = (
		<Tabs
			className="tabs_notification"
			defaultActiveKey="1"
			centered
			size="large"
			onChange={(key) => console.log(key)}
			items={[
				{
					label: `Notifications (1)`,
					key: "1",
					children: "New Notifications",
				},
				{
					label: `Read (0)`,
					key: "2",
					children: `Already read notifications`,
				},
			]}
		/>
	)

	return (
		<div className={styles.notification}>
			<Popover placement="bottomRight" content={content} trigger="click">
				<Badge count={1}>
					<BellOutlined className={styles.notification_icon} />
				</Badge>
			</Popover>
		</div>
	)
}

export default NotificationPanel
