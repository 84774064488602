//styles
import styles from "./feature-list-drawer.module.css"

//react
import { useState } from "react"

//router-dom
import { useNavigate } from "react-router-dom"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import {
	resetShowBookFeature,
	resetShowFeature,
	setUserFeatureList,
} from "../../redux-store/feature-store/feature-store-slice"

//antd
import { Drawer, Space, Button, Input, Table, Result } from "antd"
import { StarOutlined, StarFilled } from "@ant-design/icons"
import type { ColumnsType } from "antd/es/table"

//components

//services

//types
type fetureList = {
	key: string
	label: string
	name: string
	description: string
	favorite: boolean
}

const { Search } = Input

const FeatureListDrawer = () => {
	const dispatch = useAppDispatch()

	let navigate = useNavigate()

	const { showFeature, userFeatureList } = useAppSelector(
		(state) => state.featureStore
	)

	const [loading, setLoading] = useState<boolean>(false)

	const [searchedDescription, setSearchedDescription] = useState<string>("")

	const [successResult, setSuccessResult] = useState<{
		isSuccess: boolean
		title: string | null
		subtitle: string | null
		action: boolean
	}>({ isSuccess: false, title: null, subtitle: null, action: false })

	// get dataset list from db and set redux states
	const columns: ColumnsType<fetureList> = [
		{
			title: "Feature",
			dataIndex: "label",
			key: "label",
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			filteredValue: [searchedDescription],
			onFilter: (value: string | number | boolean, record: fetureList) =>
				String(record.description)
					.toLowerCase()
					.includes(String(value).toLowerCase()),
		},
		{
			title: "Favorite",
			key: "favorite",
			render: (_, record) => (
				<Button
					type="link"
					size="small"
					shape="circle"
					icon={record.favorite ? <StarFilled /> : <StarOutlined />}
					disabled={!showFeature.bookActive}
					onClick={(e) => {
						let stringstate = JSON.stringify(userFeatureList)
						let tempList = JSON.parse(stringstate)
						const index = userFeatureList.findIndex((object) => {
							return object.key === record.key
						})
						tempList[index].favorite = true
						dispatch(setUserFeatureList(tempList))
					}}
				/>
			),
			align: "center",
		},
	]

	let opacity: number = 0.7
	if (showFeature.bookActive) opacity = 1.0

	const requestBookAccess = () => {
		//console.log("requestBookAccess")
		setSuccessResult({
			isSuccess: true,
			title: "Your request was successfully submitted!",
			subtitle: "Within 2 bussiness days we will get in touch.",
			action: false,
		})
	}

	const saveFavoriteFeatures = () => {
		//console.log("saveFavoriteFeatures")
		setSuccessResult({
			isSuccess: true,
			title: "Your list of favorite features was successfully saved!",
			subtitle: "You can check its status at the Connections Page",
			action: true,
		})
	}

	const onClose = () => {
		dispatch(resetShowBookFeature())
		setSuccessResult({
			isSuccess: false,
			title: null,
			subtitle: null,
			action: false,
		})
	}

	return (
		<Drawer
			title={showFeature.bookName}
			width={"50%"}
			closable={false}
			maskClosable={false}
			onClose={() => dispatch(resetShowFeature())}
			open={showFeature.show}
			extra={
				<Space>
					{!successResult.isSuccess && (
						<Button
							key="fstore-cancel"
							onClick={() => dispatch(resetShowFeature())}
						>
							Cancel
						</Button>
					)}

					{showFeature.bookActive && !successResult.isSuccess && (
						<Button
							key="fstore-save"
							type="primary"
							onClick={saveFavoriteFeatures}
						>
							Save
						</Button>
					)}

					{!showFeature.bookActive && !successResult.isSuccess && (
						<Button
							key="fstore-request"
							type="primary"
							onClick={requestBookAccess}
						>
							Request access
						</Button>
					)}
				</Space>
			}
		>
			{!successResult.isSuccess && (
				<div>
					<Search
						placeholder="Search feature description"
						allowClear
						onSearch={
							//datasetSearch
							(value) => setSearchedDescription(value)
						}
						size="large"
						className={styles.search_bar}
					/>
					<Table
						className={styles.table}
						bordered
						columns={columns}
						dataSource={userFeatureList}
						size="small"
						loading={loading}
						pagination={false}
						//pagination={{ pageSize: 10 }}
						style={{ opacity: opacity }}
					/>
				</div>
			)}

			{successResult.isSuccess && (
				<div>
					<Result
						key="fstore-result"
						className={styles.container_saved_policy}
						status="success"
						title={successResult.title}
						subTitle={successResult.subtitle}
						extra={[
							<>
								{showFeature.bookActive && (
									<Button
										key="fstore-saveSuccessMessage"
										type="primary"
										onClick={() => {
											setSuccessResult({
												isSuccess: false,
												title: null,
												subtitle: null,
												action: false,
											})
											navigate("/connections")
											onClose()
										}}
									>
										Go to Connections Page
									</Button>
								)}
							</>,
							<Button
								key="fstore-closeSuccessMessage"
								onClick={onClose}
								type="primary"
							>
								Close
							</Button>,
						]}
					/>
				</div>
			)}
		</Drawer>
	)
}

export default FeatureListDrawer
