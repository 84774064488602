//react
import { useEffect } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen"

//reactflow
import ReactFlow, {
	Background,
	ControlButton,
	Controls,
	ReactFlowProvider,
} from "reactflow"
import {
	Node,
	Edge,
	EdgeTypes,
	useNodesState,
	NodeChange,
	applyNodeChanges,
} from "reactflow"
import "reactflow/dist/style.css"

//custom nodes
import CustomNode from "./custom-node"
import CustomEdgeTrue from "./custom-edge-true"
import CustomEdgeFalse from "./custom-edge-false"
import { FullscreenOutlined } from "@ant-design/icons"

//redux
import { updateNodePosition } from "../../redux-store/new-policy-reducer/node-slice"
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"

const nodeTypes = {
	custom: CustomNode,
}

const edgeTypes: EdgeTypes = {
	customTrue: CustomEdgeTrue,
	customFalse: CustomEdgeFalse,
}

const fitViewOptions = {
	padding: 0.3,
}

type Props = { nodes: Node[]; edges: Edge[] }

const NodeChart = ({ nodes, edges }: Props) => {
	const dispatch = useAppDispatch()
	
	const { rules } = useAppSelector((state) => state.newPolicy)

	const { nodesProps } = useAppSelector((state) => state.node)


	const handle = useFullScreenHandle()

	const [nodesf, setNodes, onNodesChange] = useNodesState(nodes)

	useEffect(() => {
		setNodes(nodes)
	}, [nodes, setNodes])

	useEffect(() => {
		for (let i in nodesf) {
			const id = nodesf[i].id
			if (!rules[id].isDeleted) {
				const newPositionX = nodesf[i].position.x;
				const newPositionY = nodesf[i].position.y;
				const diffX = newPositionX - nodesProps[id].positionX!;
				const diffY = newPositionY - nodesProps[id].positionY!;
				if (diffX !== 0 || diffY !== 0)
					dispatch(updateNodePosition({
						id: id,
						positionX: newPositionX,
						positionY: newPositionY
					}));
			}
		}
	}, [rules])

	
	const proOptions = { hideAttribution: true };

	return (
		<FullScreen handle={handle} className="fullscreen-container">
			<div style={{ height: "100%" }}>
				<ReactFlowProvider>
					<ReactFlow
						nodes={nodesf}
						onNodesChange={(changes: NodeChange[]) => {
							onNodesChange(changes)
						}}
						edges={edges}
						edgeTypes={edgeTypes}
						nodeTypes={nodeTypes}
						//fitView
						fitViewOptions={fitViewOptions}
						attributionPosition="top-right"
						deleteKeyCode={null}
						proOptions={proOptions}
					>
						<Background />
						<Controls>
							<ControlButton onClick={handle.enter} title="fullscreen">
								<div>
									<FullscreenOutlined />
								</div>
							</ControlButton>
						</Controls>
					</ReactFlow>
				</ReactFlowProvider>
			</div>
		</FullScreen>
	)
}
export default NodeChart
