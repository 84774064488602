//react
import { useEffect, useState } from "react"

//router
import { useParams } from "react-router-dom"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import {
  addNewNode,
  updateAllNodeProps,
} from "../../redux-store/new-policy-reducer/node-slice"
import {
  submmitInitalData,
  submitDataset,
  updateRules,
} from "../../redux-store/new-policy-reducer/new-policy-slice"

//services & servers
import { getDatasetById } from "../../services/dataset-database-service"
import { getPolicyById } from "../../services/policy-database-service"

//hooks
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt"

//antd
import { Modal, Spin } from "antd"

//components
import NotFound from "../../components/not-found/not-found"

//types
import { policyJson } from "../../types"
import NewPolicy from "../new-policy/new-policy"
import { setActiveStep } from "../../redux-store/new-policy-reducer/active-step-slice"
import { getProviderList } from "../../services/provider-database-service/get-provider-list"
import { createCascaderFeatureList } from "../../functions/policy/create-cacader-feature-list"

const PolicyEdit = () => {
  const { id } = useParams()

  const dispatch = useAppDispatch()

  //redux states
  const { policyName } = useAppSelector((state) => state.newPolicy)

  ///states
  const [loading, setLoading] = useState<boolean>(false)


  //states user to prevent loss of data
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog)

  useEffect(() => {
    if (showPrompt) {
      Modal.confirm({
        title: "Are you sure that you want to leave the current page?",
        content: "The changes that you made won't be saved.",
        okText: "Yes",
        cancelText: "No",
        onCancel: cancelNavigation,
        onOk: confirmNavigation,
        cancelButtonProps: { className: "modal-cancelBtn" },
        okButtonProps: { className: "modal-okBtn" },
        width: 500,
      })
    }
  }, [showPrompt, cancelNavigation, confirmNavigation])

  // get dataset list from db and set redux states
  const getData = async () => {
    setLoading(true)

    try {
      //get policy data from database
      const policy: policyJson = await getPolicyById(id!)

      //console.log(policy)
      //set states
      //Detais & Dataset
      dispatch(
        submmitInitalData({
          policyName: policy.name,
          description: policy.description,
          policy_id: policy.policy_id,
          created_at: policy.created_at,
          created_by: policy.created_by,
          policy_status: policy.policy_status,
          policy_record_id: policy.policy_record_id,
          metabasePath: policy.metabasePath,
          datasetDecisionPath: policy.datasetDecisionPath,
          policyGroupName: policy.policy_group_name,
          policyGroupDescription: policy.policy_group_description,
          policyGroupFinalType: policy.policy_group_final_type,
          payloadIn: policy.payload.input,
          payloadOut: policy.payload.output,
          dataSource: policy.dataSource,
        })
      )

      dispatch(
        submitDataset({
          datasetName: policy.dataset,
          datasetId: policy.datasetId,
          datasetPath: policy.datasetPath,
        })
      )

      //rules
      dispatch(updateRules(policy.nodes.nodeRules))

      //nodesProps
      dispatch(updateAllNodeProps(policy.nodes.nodeProps))

      //nodes & edges
      let newNodes = [
        {
          id: policy.nodes.nodeProps["N0"].arrayId,
          type: "custom",
          position: {
            x: policy.nodes.nodeProps["N0"].positionX,
            y: policy.nodes.nodeProps["N0"].positionY,
          },
          data: {
            label: "Dataset",
          },
        },
      ]
      let newEdges = []
      for (let i in policy.nodes.nodeProps) {
        if (i !== "N0") {
          newNodes.push({
            id: policy.nodes.nodeProps[i].arrayId,
            type: "custom",
            position: {
              x: policy.nodes.nodeProps[i].positionX,
              y: policy.nodes.nodeProps[i].positionY,
            },
            data: {
              label: "",
            },
          })

          let edgeType: string = "customTrue"
          if (
            i ===
            policy.nodes.nodeProps[policy.nodes.nodeProps[i].parentId!]
              .children2Id
          )
            edgeType = "customFalse"
          newEdges.push({
            id: policy.nodes.nodeProps[i].parentId + "-" + i,
            source: policy.nodes.nodeProps[i].parentId,
            target: i,
            type: edgeType,
            data: {
              label: "50%",
            },
          })
        }
      }

      dispatch(addNewNode({ newNode: newNodes, newEdge: newEdges }))
      if (policy.datasetId) {
        //get dataset
        try {
          const response = await getDatasetById(policy.datasetId!)
          dispatch(
            submitDataset({
              datasetName: response!.datasetName,
              datasetId: response!.datasetId,
              datasetPath: response!.storagePath,
              cascaderFeatureList: response!.providerFeatureList,
              sample: response!.sample,
            })
          )
        } catch (error) {
          console.log(error)
        }
      } else {
        //get datasource list
        let respList: any | null = null
        try {
          respList = await getProviderList()
        } catch (error) {
          console.log(error)
        }

        const tmpFeatureList = createCascaderFeatureList({
          dataSource: policy.dataSource.source,
          dataSelected: policy.dataSource.selected,
          payloadIn: policy.payload.input,
          providerList: respList.providerList,
          databaseList: respList.databaseList,
          customFeatureList: respList.customFeatureList,
        })

        dispatch(
          submitDataset({
            datasetName: null,
            datasetId: null,
            datasetPath: null,
            cascaderFeatureList: tmpFeatureList,
            sample: null,
          })
        )
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        const saveError = new Error(error.message)
        console.log(saveError)
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()

    dispatch(setActiveStep(5))
  }, [])

  return (
    <>
      <div>
        <Spin tip="Loading..." spinning={loading} size="large">
          {policyName && <NewPolicy />}

          {!loading && !policyName && (
            <NotFound buttonText="Go to Policies Page" buttonUrl="/policies" />
          )}
        </Spin>
      </div>
    </>
  )
}

export default PolicyEdit
