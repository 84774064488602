export const ouzeProvider = [
  {
    dataType: "provider",
    data: [
      {
        Id: "uii1",
        type: "provider",
        description: {
          key: "uii1",
          label: "Boa Vista",
          value: "BoaVista",
          image: null,
          type: "provider",
          description:
            "Boa Vista Serviços is the second largest credit bureau in Brazil. It has a database built on a national network of hundreds of organizations representing the Brazilian retail market, providing instant and reliable support for companies in all stages of the business cycle. Boa Vista was created as a new company in 2010 through the demutualization of the Serviço Central de Proteção ao Crédito (SCPC), a division of the Associação Comercial de São Paulo (ACSP or “Commercial Association of São Paulo).",
          children: [
            {
              key: "uuidb1001",
              value: "SCPC100",
              label: "SCPC: Renda Presumida",
              children: [
                {
                  key: "uuidf1002",
                  value: "inicioFaixaValorRendaPresumida",
                  label: "Renda (Inicio)",
                  dataType: "NUMBER",
                  allowedValues: "brazillian currency value",
                },
                {
                  key: "uuidf2003",
                  value: "finalFaixaValorRendaPresumida",
                  label: "Renda (Fim)",
                  dataType: "NUMBER",
                  allowedValues: "brazillian currency value",
                },
                {
                  key: "uuidf2004",
                  value: "percentualDisponivelRenda",
                  label: "% Renda Disponível",
                  dataType: "NUMBER",
                  allowedValues: "from 0 to 100",
                },
              ],
            },
            {
              key: "uuidb1249",
              value: "SCPC249",
              label: "Dados Cadastrais (PF)",
              children: [
                {
                  key: "uuibvs24901",
                  value: "nome",
                  label: "Nome",
                  dataType: "STRING",
                  allowedValues: "any string",
                },
                {
                  key: "uuibvs24902",
                  value: "dataNascimento",
                  label: "Data Nascimento",
                  dataType: "STRING",
                  allowedValues: "format YYYY-MM-DD",
                },
                {
                  key: "uuibvs24903",
                  value: "nomeMae",
                  label: "Nome Mãe",
                  dataType: "STRING",
                  allowedValues: "any string",
                },
                {
                  key: "uuibvs24904",
                  value: "tituloEleitor",
                  label: "Título Eleitor",
                  dataType: "NUMERO",
                  allowedValues: "",
                },
                {
                  key: "uuibvs24901",
                  value: "condicao",
                  label: "Situação",
                  dataType: "STRING",
                  allowedValues:
                    "REGULAR, CANCELADO, PENDENTE, SUSPENSO, INEXISTENTE, DADOS_INCOMPLETOS, NULO, NAO_ESPECIFICADO",
                },
              ],
            },
            {
              key: "uuidb1256",
              value: "SCPC256",
              label: "Resumo Cheques Sustados",
              children: [
                {
                  key: "uuibvs25601",
                  value: "tipoDocumento",
                  label: "Tipo Documento",
                  dataType: "STRING",
                  allowedValues: "CPF, CNPJ",
                },
                {
                  key: "uuibvs25602",
                  value: "documento",
                  label: "Documento",
                  dataType: "STRING",
                  allowedValues: "format 00000000000000",
                },
                {
                  key: "uuibvs25603",
                  value: "total",
                  label: "Qtd Sustados (21)",
                  dataType: "NUMBBER",
                  allowedValues: "from 0 to 99999",
                },
                {
                  key: "uuibvs25604",
                  value: "periodoInicial",
                  label: "Data Primeira Ocorrência",
                  dataType: "STRING",
                  allowedValues: "format YYYY-MM-DD",
                },
                {
                  key: "uuibvs25605",
                  value: "periodoFinal",
                  label: "Data Última Ocorrência",
                  dataType: "STRING",
                  allowedValues: "format YYYY-MM-DD",
                },
              ],
            },
            {
              key: "uuidb1242",
              value: "SCPC242",
              label: "Devoluções Por Motivo (CCF)",
              children: [
                {
                  key: "uuibvs24201",
                  value: "tipoDocumento",
                  label: "Tipo Documento",
                  dataType: "STRING",
                  allowedValues: "CPF, CNPJ",
                },
                {
                  key: "uuibvs24202",
                  value: "documento",
                  label: "Documento",
                  dataType: "STRING",
                  allowedValues: "format 00000000000000",
                },
                {
                  key: "uuibvs24203",
                  value: "nome",
                  label: "Nome",
                  dataType: "STRING",
                  allowedValues: "any string",
                },
                {
                  key: "uuibvs24204",
                  value: "banco",
                  label: "Banco",
                  dataType: "NUMBER",
                  allowedValues: "from 0 to 999",
                },
                {
                  key: "uuibvs24205",
                  value: "agencia",
                  label: "Agência",
                  dataType: "NUMBER",
                  allowedValues: "from 0 to 9999",
                },
                {
                  key: "uuibvs24206",
                  value: "totalMotivo12",
                  label: "Qtd Devoluções (12)",
                  dataType: "NUMBBER",
                  allowedValues: "from 0 to 999",
                },
                {
                  key: "uuibvs24207",
                  value: "ultimoMotivo12",
                  label: "Data Última Ocorrência (12)",
                  dataType: "STRING",
                  allowedValues: "format YYYY-MM-DD",
                },
                {
                  key: "uuibvs24208",
                  value: "totalMotivo13",
                  label: "Qtd Devoluções (13)",
                  dataType: "NUMBBER",
                  allowedValues: "from 0 to 999",
                },
                {
                  key: "uuibvs24209",
                  value: "ultimoMotivo13",
                  label: "Data Última Ocorrência (13)",
                  dataType: "STRING",
                  allowedValues: "format YYYY-MM-DD",
                },
                {
                  key: "uuibvs24210",
                  value: "totalMotivo14",
                  label: "Qtd Devoluções (14)",
                  dataType: "NUMBBER",
                  allowedValues: "from 0 to 999",
                },
                {
                  key: "uuibvs24211",
                  value: "ultimoMotivo14",
                  label: "Data Última Ocorrência (14)",
                  dataType: "STRING",
                  allowedValues: "format YYYY-MM-DD",
                },
                {
                  key: "uuibvs24212",
                  value: "totalMotivo99",
                  label: "Qtd Devoluções (99)",
                  dataType: "NUMBBER",
                  allowedValues: "from 0 to 999",
                },
                {
                  key: "uuibvs24213",
                  value: "ultimoMotivo99",
                  label: "Data Última Ocorrência (99)",
                  dataType: "STRING",
                  allowedValues: "format YYYY-MM-DD",
                },
              ],
            },
            {
              key: "uuidb1254",
              value: "SCPC254",
              label: "Resumo Devoluções (CCF)",
              children: [
                {
                  key: "uuibvs25401",
                  value: "tipoDocumento",
                  label: "Tipo Documento",
                  dataType: "STRING",
                  allowedValues: "CPF, CNPJ",
                },
                {
                  key: "uuibvs25402",
                  value: "documento",
                  label: "Documento",
                  dataType: "STRING",
                  allowedValues: "format 00000000000000",
                },
                {
                  key: "uuibvs25403",
                  value: "total",
                  label: "Qtd Devoluções",
                  dataType: "NUMBBER",
                  allowedValues: "from 0 to 99999",
                },
                {
                  key: "uuibvs25404",
                  value: "dataPrimeiraOcorrencia",
                  label: "Data Primeira Ocorrência",
                  dataType: "STRING",
                  allowedValues: "format YYYY-MM-DD",
                },
                {
                  key: "uuibvs25405",
                  value: "dataUltimaOcorrencia",
                  label: "Data Última Ocorrência",
                  dataType: "STRING",
                  allowedValues: "format YYYY-MM-DD",
                },
              ],
            },
            {
              key: "uuidb1141",
              value: "SCPC141",
              label: "Resumo Débitos (PF)",
              children: [
                {
                  key: "uuibvs14103",
                  value: "totalDebitos",
                  label: "Qtd Débitos",
                  dataType: "NUMBBER",
                  allowedValues: "from 0 to 99999999",
                },
                {
                  key: "uuibvs14104",
                  value: "dataPrimeiroDebito",
                  label: "Data Primeiro Débito",
                  dataType: "STRING",
                  allowedValues: "format YYYY-MM-DD",
                },
                {
                  key: "uuibvs14105",
                  value: "dataUltimoCapitulo",
                  label: "Data Último Débito",
                  dataType: "STRING",
                  allowedValues: "format YYYY-MM-DD",
                },
                {
                  key: "uuibvs14106",
                  value: "moeda",
                  label: "Moeda do Valor Acumulado",
                  dataType: "STRING",
                  allowedValues: "code for currency type",
                },
                {
                  key: "uuibvs14107",
                  value: "valorAcumulado",
                  label: "Valor Acumulado",
                  dataType: "NUMBER",
                  allowedValues: "from 0 to 9999999999999",
                },
              ],
            },
            {
              key: "uuidb2001",
              value: "scorePositivo",
              label: "Score Positivo PF",
              children: [
                {
                  key: "uuibvsSPPF01",
                  value: "tr_601:score",
                  label: "Score Consumidor",
                  dataType: "NUMBBER",
                  allowedValues: "from 0 to 1000",
                },
                {
                  key: "uuibvsSPPF02",
                  value: "tr_601:texto ",
                  label: "Contexto Descrição",
                  dataType: "STRING",
                  allowedValues: "any string",
                },
                {
                  key: "uuibvsSPPF03",
                  value: "tr_804:codigo",
                  label: "Status Informações",
                  dataType: "STRING",
                  allowedValues:
                    "SUCESSO, SEM_HISTORICO, NAO_PARTICIPANTE, CONSULTA_SEM_AUTORIZACAO, CONSULTA_BLOQUEIO_TOTAL,  CONSULTA_BLOQUEIO_PARCIAL",
                },
                {
                  key: "uuibvsSPPF04",
                  value: "tr_804:mensagem",
                  label: "Descrição Status Informações",
                  dataType: "STRING",
                  allowedValues: "any string",
                },
              ],
            },
            {
              key: "uuidb2001CA",
              value: "scorePositivoCA",
              label: "Score Positivo PF / CA",
              children: [
                {
                  key: "uuibvsSPPF01CA",
                  value: "tr_601:valor",
                  label: "Renda Presumida",
                  dataType: "NUMBBER",
                  allowedValues: "brazillian currency value",
                },
              ],
            },
          ],
        },
      },
      {
        Id: "uii2",
        type: "provider",
        description: {
          label: "SERASA",
          value: "serasa",
          image: null,
          key: "uii2",
          description:
            "Serasa Experian is the leading global information services company, providing data and analytical tools to clients around the world. The Group helps businesses to manage credit risk, prevent fraud, target marketing offers and automate decision making. Experian also helps individuals to check their credit report and credit score, and protect against identity theft.",
          children: [
            {
              key: "uuidb1007",
              value: "SECadastro",
              label: "Cadastral",
              children: [
                {
                  key: "uuidf1009",
                  value: "situacaoCadastral",
                  label: "Situação Cadastral",
                  dataType: "STRING",
                  allowedValues: "",
                },
                {
                  key: "uuidf2010",
                  value: "dataNascimento",
                  label: "Data Nascimento",
                  dataType: "STRING",
                  allowedValues: "",
                },
              ],
            },
            {
              key: "uuidbSEXP04",
              value: "PEFIN",
              label: "PEFIN",
              children: [
                {
                  key: "uuidSEXP04f01",
                  value: "qtdPEFIN",
                  label: "Qtd PEFIN",
                  dataType: "NUMBER",
                  allowedValues: "",
                },
              ],
            },
            {
              key: "uuidbSEXP05",
              value: "REFIN",
              label: "REFIN",
              children: [
                {
                  key: "uuidSEXP05f01",
                  value: "qtdREFIN",
                  label: "Qtd REFIN",
                  dataType: "NUMBER",
                  allowedValues: "",
                },
              ],
            },
            {
              key: "uuidbSEXP06",
              value: "rendaPresumida",
              label: "Renda Presumida",
              children: [
                {
                  key: "uuidSEXP06f01",
                  value: "valRendaPresumidaMedia",
                  label: "Valor Renda Presumida Média",
                  dataType: "NUMBER",
                  allowedValues: "",
                },
              ],
            },
            {
              key: "uuidbSEXP07",
              value: "alertaObito",
              label: "Alerta Óbito",
              children: [
                {
                  key: "uuidSEXP07f01",
                  value: "flObrito",
                  label: "Flag Alerta Óbito",
                  dataType: "STRING",
                  allowedValues: "S, N",
                },
              ],
            },

            {
              key: "uuidbSEXP03",
              value: "debitos",
              label: "Débitos",
              children: [
                {
                  key: "uuidSEXP03f01",
                  value: "qtdChequeSemFundo",
                  label: "Qtd Cheque Sem Fundo",
                  dataType: "NUMBER",
                  allowedValues: "",
                },
                {
                  key: "uuidSEXP03f02",
                  value: "qtdProtesto",
                  label: "Qtd Protesto",
                  dataType: "NUMBER",
                  allowedValues: "",
                },
                {
                  key: "uuidSEXP03f03",
                  value: "qtdDividaVencida",
                  label: "Qtd Dívida Vencida",
                  dataType: "NUMBER",
                  allowedValues: "",
                },
              ],
            },
            {
              key: "uuidbSEXP02",
              value: "acoesJudiciais",
              label: "Ações Judiciais",
              children: [
                {
                  key: "uuidSEXP02f01",
                  value: "qtdAcoesJudiciais",
                  label: "Qtd Ações Judiciais",
                  dataType: "NUMBER",
                  allowedValues: "",
                },
              ],
            },
            {
              key: "uuidbSEXP01",
              value: "falenciasConcordatas",
              label: "Falências e Concordatas",
              children: [
                {
                  key: "uuidSEXP01f01",
                  value: "qtdFalenciasConcordatas",
                  label: "Qtd Falências e Concordatas",
                  dataType: "NUMBER",
                  allowedValues: "",
                },
              ],
            },
            {
              key: "uuidb2011",
              value: "spc",
              label: "SPC",
              children: [
                {
                  key: "uuidf1012",
                  value: "qtdAnotacoesSPC",
                  label: "Qtd Anotações do SPC",
                  dataType: "NUMBER",
                  allowedValues: "",
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    dataType: "custom",
    data: [
      {
        Id: "uii3",
        type: "custom",
        description: {
          label: "Group 1",
          value: "customG1",
          image: null,
          key: "uuid3",
          description: "description of group of custom features 1",
          children: [
            {
              key: "015",
              value: "feat1",
              label: "feature 1",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
            {
              key: "016",
              value: "feat2",
              label: "feature 2",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
          ],
        },
      },
      {
        Id: "uii4",
        type: "custom",
        description: {
          label: "Group 2",
          value: "customG2",
          image: null,
          key: "uuid4",
          description: "description of group of custom features 2",
          children: [
            {
              key: "uuidf10121",
              value: "feat1",
              label: "feature 1",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
            {
              key: "uuidf201022",
              value: "feat2",
              label: "feature 2",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
          ],
        },
      },
      {
        Id: "uii7",
        type: "custom",
        description: {
          label: "Group 3",
          value: "customG3",
          image: null,
          key: "uuid7",
          description: "description of group of custom features 3",
          children: [
            {
              key: "uuidf101027",
              value: "feat1",
              label: "feature 1",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
            {
              key: "uuidf201028",
              value: "feat2",
              label: "feature 2",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
          ],
        },
      },
      {
        Id: "uii8",
        type: "custom",
        description: {
          label: "Group 4",
          value: "customG4",
          image: null,
          key: "uuid8",
          description: "description of group of custom features 4",
          children: [
            {
              key: "uuidf101033",
              value: "feat1",
              label: "feature 1",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
            {
              key: "uuidf201034",
              value: "feat2",
              label: "feature 2",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
          ],
        },
      },
    ],
  },
  {
    dataType: "database",
    data: [
      {
        Id: "uii5",
        type: "database",
        description: {
          label: "Cargo Colaboradores",
          value: "cargoColaboradores",
          image: null,
          description: "Internal data with position of employees",
          key: "uuid5",
          children: [
            {
              key: "uuidf101038",
              value: "documento",
              label: "documento",
              dataType: "STRING",
              allowedValues: "format 00000000000",
            },
            {
              key: "uuidf201039",
              value: "cargo",
              label: "cargo",
              dataType: "NUMBER",
              allowedValues: "",
            },
          ],
        },
      },
      {
        Id: "uii6",
        type: "database",
        description: {
          label: "Customizada Colaboradores",
          value: "customizadaColaboradores",
          image: null,
          description:
            "Internal data with information about limit for employees",
          key: "uii6",
          children: [
            {
              key: "uuidf101040",
              value: "documento",
              label: "documento",
              dataType: "STRING",
              allowedValues: "format 00000000000",
            },
            {
              key: "uuidf201041",
              value: "limite",
              label: "limite",
              dataType: "NUMBER",
              allowedValues: "Brazillian currency value",
            },
          ],
        },
      },
      {
        Id: "uii9",
        type: "database",
        description: {
          label: "De/Para Lojas",
          value: "deParaLojas",
          image: null,
          description: "Internal data with correspondence between stores",
          key: "uii9",
          children: [
            {
              key: "uuidf101042",
              value: "cod1",
              label: "cod1",
              dataType: "NUMBER",
              allowedValues: "",
            },
            {
              key: "uuidf201043",
              value: "cod2",
              label: "cod2",
              dataType: "NUMBER",
              allowedValues: "",
            },
            {
              key: "uuidf201045dlj",
              value: "cod3",
              label: "cod3",
              dataType: "NUMBER",
              allowedValues: "",
            },
          ],
        },
      },
    ],
  },
]

export const ouzeCustomFeature = [
  {
    Id: "uii3",
    type: "custom",
    description: {
      label: "Group 1",
      value: "customG1",
      image: null,
      customId: "uuid3",
      description: "description of group of custom features 1",
      bookList: [
        {
          key: "book1",
          value: "uuidb1",
          label: "book number 1",
          children: [
            {
              key: "uuidf1",
              value: "feat1",
              label: "feature 1",
            },
            {
              key: "uuidf2",
              value: "feat2",
              label: "feature 2",
            },
          ],
        },
        {
          value: "book2",
          label: "book number 2",
          children: [
            {
              key: "uuidf1",
              value: "feat1",
              label: "feature 1",
            },
            {
              key: "uuidf2",
              value: "feat2",
              label: "feature 2",
            },
          ],
        },
      ],
    },
  },
  {
    Id: "uii4",
    type: "custom",
    description: {
      label: "Group 2",
      value: "customG2",
      image: null,
      Id: "uuid4",
      description: "description of group of custom features 2",
      bookList: [
        {
          value: "book1",
          label: "book number 1",
          children: [
            {
              value: "feat1",
              label: "feature 1",
            },
            {
              value: "feat2",
              label: "feature 2",
            },
          ],
        },
        {
          value: "book2",
          label: "book number 2",
          children: [
            {
              value: "feat1",
              label: "feature 1",
            },
            {
              value: "feat2",
              label: "feature 2",
            },
          ],
        },
      ],
    },
  },
]

export const ouzeDatabase = [
  {
    Id: "uii5",
    type: "database",
    description: {
      label: "Database 1",
      value: "database1",
      image: null,
      description: "description of database 1",
      Id: "uuid5",
      children: [
        {
          value: "feat1",
          label: "feature 1",
        },
        {
          value: "feat2",
          label: "feature 2",
        },
      ],
    },
  },
  {
    Id: "uii6",
    type: "database",
    description: {
      label: "Database 2",
      value: "database2",
      image: null,
      description: "description of database 2",
      children: [
        {
          value: "feat1",
          label: "feature 1",
        },
        {
          value: "feat2",
          label: "feature 2",
        },
      ],
    },
  },
]

/*
interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

const options: Option[] = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
        children: [
          {
            value: 'xihu',
            label: 'West Lake',
          },
        ],
      },
    ],
  },]
  */
