import { MenuOutlined } from "@ant-design/icons"
import { Button, Col, Popover, Row, Space, Tooltip } from "antd";

type Props = {}

const ProductsMenu = (props: Props) => {

  const content = (
    <>
    <Space direction="vertical" size='large'>

      <Row>
          <Space direction="horizontal" size='large' >
            <Tooltip title="PoD Play.Ground">
            <a href="https://podplatform.io/" target="_blank" rel="noreferrer">
              <img src={require("./images/playground-short.png")} alt="fabric" height={45} />  
            </a>
        </Tooltip>
      
            <Tooltip title="PoD Data.Fabric">
            <a href="https://podplatform.io/" target="_blank" rel="noreferrer" >

                <img src={require("./images/data-fabric-short.png")} alt="fabric" height={45} />
                </a>

        </Tooltip>           


            <Tooltip title="PoD Auto ML.">
            <a href="https://podplatform.io/" target="_blank" rel="noreferrer">

                <img src={require("./images/autoML-short.png")} alt="fabric" height={45} />
                </a>

        </Tooltip>
          </Space>


      </Row>

      <Row>
          <Space direction="horizontal" size='large'>
            <Tooltip title="PoD API.Hub">
            <a href="https://podplatform.io/" target="_blank" rel="noreferrer">

                <img src={require("./images/api-hub-short.png")} alt="fabric" height={45} />
                </a>

        </Tooltip>

            <Tooltip title="PoD Data.Management">
            <a href="https://podplatform.io/" target="_blank" rel="noreferrer">

                <img src={require("./images/data-management-short.png")} alt="fabric" height={45} />
                </a>
        </Tooltip>
          </Space>

      </Row>
  
 
      </Space>
    </>)
  return (
    <div>
      <Popover placement="bottomRight" content={content} trigger="click">
        <Tooltip title="PoD Apps">
          <Button
            type="link"
            size="large"
            shape="circle"
            icon={<MenuOutlined />}	
          />
        </Tooltip>
      </Popover>
    </div>
  )
}

export default ProductsMenu