//css
import styles from "./choose-features.module.css"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { selectedDataSource } from "../../redux-store/new-policy-reducer/new-policy-slice"

//antd
import { Button, Col, Row, Tooltip } from "antd"

const ChooseDatabase = ({ data }: { data: any[] }) => {
  const dispatch = useAppDispatch()

  const { dataSelected } = useAppSelector((state) => state.newPolicy)

  return (
    <Row gutter={[25, 25]} className={styles.card_container}>
      {data?.map((db) => {
        return (
          <Col key={db.Id}>
            <Tooltip title={db.description.description}>
              <Button
                className={styles.card}
                onClick={() => {
                  let sourceStatus = true
                  let featureStatus = false
                  if (dataSelected[db.Id]) {
                    sourceStatus = !dataSelected[db.Id].sourceStatus
                    featureStatus = dataSelected[db.Id].featureStatus
                  }
                  dispatch(
                    selectedDataSource({
                      id: db.Id,
                      selected: {
                        sourceStatus: sourceStatus,
                        featureStatus: featureStatus,
                        type: db.type,
                      },
                    })
                  )
                }}
                size="large"
                type={
                  dataSelected[db.Id] && dataSelected[db.Id].sourceStatus
                    ? "primary"
                    : "default"
                }
                disabled={
                  dataSelected[db.Id] && dataSelected[db.Id].featureStatus
                    ? true
                    : false
                }
              >
                {db.description.label}
              </Button>
            </Tooltip>
          </Col>
        )
      })}
    </Row>
  )
}

export default ChooseDatabase
