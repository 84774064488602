//styles
import styles from "./policy.module.css"

//react
import { useState } from "react"

//react hooks
import { useEffect } from "react"

//router
import { Link, useNavigate } from "react-router-dom"

//redux
import { useAppDispatch } from "../../redux-store/hooks"
import {
  resetActiveStep,
  setIsPolicySaved,
} from "../../redux-store/new-policy-reducer/active-step-slice"
import { resetNewPolicy } from "../../redux-store/new-policy-reducer/new-policy-slice"
import { resetNode } from "../../redux-store/new-policy-reducer/node-slice"
import { resetDatasetProps } from "../../redux-store/upload-dataset/dataset-setup-slice"

//antd
import {
  Button,
  Input,
  Table,
  Row,
  Col,
  Space,
  Tag,
  Select,
  SelectProps,
  Tooltip,
} from "antd"
import {
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
  BarChartOutlined,
  RetweetOutlined,
} from "@ant-design/icons"
import type { ColumnsType } from "antd/es/table"

//utils
import { formatDateUnix } from "../../utils/string-unixtime"
import { distinctOptionsList } from "../../utils/distinct-option-list"

//services
import { deletePolicyById } from "../../services/policy-database-service/delete-policy-by-id"
import { getFile } from "../../services/storage-service/getFile"
import { getPolicyList } from "../../services/policy-database-service"

//components
import { deleteById } from "../../components/delete-confirmation-modal/delete-confirmation-modal-by-id"
import FeatureProviderTable from "../../components/policy/feature-provider-table"


//types
import { policyJson } from "../../types"


const { Search } = Input

const Policy = () => {
  let navigate = useNavigate()

  const dispatch = useAppDispatch()

  const [data, setData] = useState<policyJson[]>([])

  const [signedURL, setSignedURL] = useState<{
    [id: string]: { url: string | undefined }
  }>({})

  const [loading, setLoading] = useState<boolean>(false)

  const [searchedName, setSearchedName] = useState<string>("")
  const [selectedUser, setSelectedUser] = useState<string[]>([])

  const [usersList, setUserList] = useState<SelectProps["options"]>([])

  const columns: ColumnsType<policyJson> = [
    {
      title: "Policy Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchedName],
      onFilter: (value: string | number | boolean, record: policyJson) =>
        String(record.name).toLowerCase().includes(String(value).toLowerCase()),
    },
    {
      title: "Data connections",
      key: "dataProviders",
      dataIndex: "providerList",
      render: (_, { dataProviders }) => (
        <>
          {dataProviders?.map((prov) => {
            let color = "geekblue"
            if (prov.name) {
              return (
                <Tag color={color} key={prov.name}>
                  {prov.label ? prov.label.toUpperCase() : prov.name.toUpperCase()}
                </Tag>
              )
            }
          })}
        </>
      ),
    },
    {
      title: "Dataset",
      dataIndex: "dataset",
      key: "dataset",
      render: (_, { dataset, datasetId }) => (
        <Link
          to={`/dataset-preview/${datasetId}`}
          onClick={() => {
            dispatch(resetDatasetProps())
          }}
        >
          {dataset}
        </Link>
      ),
    },
    {
      title: "User",
      dataIndex: "updated_by",
      key: "updated_by",
      render: (_, { updated_by }) => (
        <Space direction="horizontal">
          <div className={styles.circle}>
            <p> {updated_by?.substring(0, 1).toUpperCase()} </p>
          </div>
        </Space>
      ),
      align: "center",
      filteredValue: selectedUser,
      onFilter: (value: string | number | boolean, record: policyJson) =>
        String(record.created_by)
          .toLowerCase()
          .includes(String(value).toLowerCase()),
    },
    {
      title: "Last Updated Date",
      dataIndex: "updated_at",
      key: "updated_at",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        formatDateUnix(a.updated_at!) - formatDateUnix(b.updated_at!),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Tooltip title="Dashboard">
            <Button
              type="link"
              size="small"
              shape="circle"
              icon={<BarChartOutlined />}
              href={record.metabasePath ?? ""}
              disabled={record.metabasePath ? false : true}
              target="_blank"
            />
          </Tooltip>

          <Tooltip title="Download processed file">
            <Button
              type="link"
              size="small"
              shape="circle"
              icon={<DownloadOutlined />}
              href={signedURL[record.policy_record_id!].url}
              disabled={signedURL[record.policy_record_id!].url ? false : true}
            />
          </Tooltip>

          <Tooltip title="Backtest">
            <Button
              type="link"
              size="small"
              shape="circle"
              icon={<RetweetOutlined />}
              onClick={(e) => {
                dispatch(setIsPolicySaved(false))
                dispatch(resetActiveStep())
                dispatch(resetNewPolicy())
                dispatch(resetNode())
                navigate(`/policy-backtest/${record.policy_id}`)
              }}
            />
          </Tooltip>

          <Tooltip title="Preview & Edit">
            <Button
              type="link"
              shape="circle"
              size="small"
              icon={<EditOutlined />}
              onClick={(e) => {
                dispatch(setIsPolicySaved(false))
                dispatch(resetActiveStep())
                dispatch(resetNewPolicy())
                dispatch(resetNode())
                navigate(`/policy-edit/${record.policy_id}`)
              }}
            />
          </Tooltip>

          <Tooltip title="Delete">
            <Button
              type="link"
              size="small"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={(e) => {
                deleteById({
                  body: record.policy_id!,
                  title: `Are you sure you want to delete ${record.name!}`,
                  content: `You will not be able to recover this information.`,
                  successMesssage: `${record.name!} was successfully deleted.`,
                  action: deletePolicyById,
                  onCloseMessage: getData,
                })
              }}
            />
          </Tooltip>
        </Space>
      ),
      align: "center",
    },
  ]

  // get policy list from db
  const getData = async () => {
    setLoading(true)

    //get data
    try {
      const policies = await getPolicyList()

      let data = []
      let url: { [id: string]: { url: string | undefined } } = {}
      for (let i in policies) {
        if (policies[i].name && !policies[i].name.includes("backtest_")) {
          data.push(policies[i])

          url[policies[i].policy_record_id] = { url: undefined }
          if (policies[i].datasetDecisionPath) {
            const signedURL = await getFile(policies[i].datasetDecisionPath)
            url[policies[i].policy_record_id] = { url: signedURL }
          }
        }
      }

      setData(data)
      setSignedURL(url)
    } catch (error: unknown) {
      if (error instanceof Error) {
        const saveError = new Error(error.message)
        console.log(saveError)
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    let options: SelectProps["options"] = []

    for (let i in data) {
      let username: string | null | undefined
      if (data[i].created_by!.includes("@")) {
        const index: number | null | undefined =
          data[i].created_by!.indexOf("@")
        username = data[i].created_by!.substring(0, index)
      } else {
        username = data[i].created_by
      }
      options.push({
        label: username!.toLowerCase(),
        value: data[i].created_by!,
      })
    }

    setUserList(distinctOptionsList(options))
  }, [data])

  return (
    <div className="container-page">
      <div className={styles.table_container}>
        <div className={styles.button_container}>
          <Row>
            <Col flex={3} className={styles.container_normal}>
              <Search
                placeholder="Search policy name"
                allowClear
                onSearch={(value) => setSearchedName(value)}
                style={{ width: "60%", padding: "0px 30px 0px 0px" }}
                size="large"
              />

              <Select
                mode="multiple"
                allowClear
                style={{ width: "60%" }}
                placeholder="All users "
                onChange={(value: string[]) => setSelectedUser(value)}
                options={usersList}
                size="large"
              />
            </Col>
            <Col flex={2} className={styles.container_reverse}>
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  dispatch(setIsPolicySaved(false))
                  dispatch(resetActiveStep())
                  dispatch(resetNewPolicy())
                  dispatch(resetNode())
                  navigate("/new-policy")
                }}
              >
                New Policy
              </Button>
            </Col>
          </Row>
        </div>
        <div>
          <Table
            className={styles.table}
            bordered
            columns={columns}
            dataSource={data}
            size="small"
            loading={loading}
            pagination={{ pageSize: 12 }}
            rowKey={(record) => record.policy_id!}
            expandable={{
              expandedRowRender: (record) => (
                <>
                  <p>
                    <strong>Created by: </strong> {record.created_by} at{" "}
                    {record.created_at!.substring(0, 10)}
                  </p>
                  <p style={{ margin: 0 }}>
                    <strong>Description: </strong>

                    {(record.description?.length ?? 0) > 0
                      ? record.description
                      : "No description available."}
                  </p>
                  <p>
                    <strong>Features: </strong>
                  </p>
                  <div className={styles.connection_table}>
                    <FeatureProviderTable
                      featureProviderList={record.dataProviders}
                      scrollBody={{}}
                    />
                  </div>
                </>
              ),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Policy
