//css
import styles from "./sample-table.module.css"

//redux
import { useAppSelector } from "../../redux-store/hooks"

//antd
import { Table } from "antd"
import type { ColumnsType } from "antd/es/table"

type Props = {
	loading: boolean
	sample: [string[]] | null
	styleProps: { scrollBody: any }
}

const SampleTable = ({ loading, sample, styleProps }: Props) => {
	const uuid = require("uuid")

	//const { datasetProps } = useAppSelector((state) => state.datasetProps)

	let col = []
	let data = []
	let dataitem = "["

	if (sample) {
		for (let j in sample) {
			dataitem = dataitem + "{"
			for (let k in sample[j]) {
				if (j === "0") {
					//header
					col.push({
						title: sample[j][k],
						dataIndex: sample[j][k],
						key: sample[j][k],
					})
				} else {
					//rows
					dataitem =
						dataitem +
						'"key":"' +
						uuid.v1() +
						'","' +
						String(sample["0"][k]).replaceAll('"', " ") +
						'":"' +
						String(sample[j][k]).replaceAll('"', " ") +
						'",'
				}
			}
			dataitem = dataitem + "},"
		}

		dataitem = dataitem + "]"

		data = JSON.parse(
			dataitem.replaceAll(",}", "}").replaceAll(",]", "]").replaceAll("{},", "")
		)
	}

	// table definition
	const columns: ColumnsType<[string[]]> = col

	//console.log('sample',data,col)

	return (
		<Table
			className={styles.table_sample}
			bordered
			columns={columns}
			dataSource={data}
			size="small"
			loading={loading}
			pagination={false}
			scroll={styleProps.scrollBody}
		/>
	)
}

export default SampleTable
