//css
import styles from "./upload-btn.module.css"

//react
import { ReactNode, useState } from "react"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setDatasetProps } from "../../redux-store/upload-dataset/dataset-setup-slice"
import { logout } from "../../redux-store/auth-reducer/authentication-thunks"
import { setAlert } from "../../redux-store/upload-dataset/upload-dataset-slice"

//antd
import {
  InboxOutlined,
  UploadOutlined,
  FileSyncOutlined,
} from "@ant-design/icons"
import { Upload, UploadProps, Button, Progress } from "antd"
import { UploadRequestOption } from "rc-upload/lib/interface"

//services
import {
  putFile,
  removeFile,
  storagePath,
} from "../../services/storage-service"
import { getDatasetPropsFromS3 } from "../../services/read-s3-dataset-service/get-dataset-properties-S3"
import { putDataset } from "../../services/dataset-database-service"

//types
import { datasetDb } from "../../types"

//components
import { modalError } from "./modal-error"

//functions && utils
import { checkDatasetLayout } from "../../functions/dataset/check-dataset-layout"

type Props = {
  id: string
  type: "dragger" | "btn" | "backtest"
  bucketPath: string
  closeModal: () => void
  setLoading: React.Dispatch<React.SetStateAction<any>>
  setSuccess: React.Dispatch<React.SetStateAction<any>> | null
  setShowDialog: React.Dispatch<React.SetStateAction<any>> | null
  setFileName: React.Dispatch<React.SetStateAction<any>> | null
  setSavedFileName: React.Dispatch<React.SetStateAction<any>> | null
}

const UploadDatasetBtn = ({
  id,
  type,
  bucketPath,
  closeModal,
  setLoading,
  setSuccess,
  setShowDialog,
  setFileName,
  setSavedFileName,
}: Props) => {
  const dispatch = useAppDispatch()

  //redux states
  const { user } = useAppSelector((state) => state.auth)
  const { datasetProps } = useAppSelector((state) => state.datasetProps)

  const [loadingChangeFile, setLoadingChangeFile] = useState<boolean>(false)
  const [showFileList, setShowFileList] = useState<boolean>(false)
  const [progress, setProgress] = useState<number | null>(null)

  const { Dragger } = Upload

  const generalAlertMessage: ReactNode = (
    <p>
      Oops! Something went wrong. Please try again later. You can check the
      status of your dataset <strong>{datasetProps.datasetName}</strong> at the
      Dataset's page. If the error persists contact the support for more help.
    </p>
  )


  const defaultAlertMessage: ReactNode = (
    <p>
      Oops! Something went wrong. Please try again later. If the
      error persists contact the support for more help.
    </p>
  )

  const props: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    async customRequest(options: UploadRequestOption) {
      setLoading(true)
      setLoadingChangeFile(true)
      setShowFileList(false)

      dispatch(setAlert({ alertMessage: null, alertType: undefined }))

      const file = options.file as File

      const saveFileName: string = bucketPath + id + "_" + file.name

      let isFileChange: boolean = true

      let newDataset: datasetDb = {
        datasetId: datasetProps.datasetId,
        uploadBy: datasetProps.uploadBy ?? user?.email!,
        updatedBy: user?.email!,
        uploadAt: datasetProps.uploadAt ?? new Date().toLocaleString(),
        updatedAt: new Date().toLocaleString(),
        datasetName: datasetProps.datasetName,
        fileName: saveFileName,
        storagePath: storagePath + saveFileName,
        policies: datasetProps.policies,
        description: datasetProps.description,
        size: null,
        qtdRows: null,
        sample: null,
        featureList: [],
        providerBookList: [],
        providerFeatureList: [],
      }

      if (type !== "backtest") {
        //check dataset name and description
        if (!datasetProps.datasetName || !datasetProps.description) {
          isFileChange = false
          const uploadError = new Error(
            "Missing dataset's name and/or description."
          )
          options.onError!(uploadError)
          setShowFileList(true)
          dispatch(
            setAlert({
              alertMessage:
                "Please insert a name and description for your dataset before change file.",
              alertType: "error",
            })
          )
        } else {
          isFileChange = true
        }
      }

      //check file type
      if (isFileChange) {
        if (file.type.includes("csv")) {
          isFileChange = true
        } else {
          const uploadError = new Error("File format not supported.")
          options.onError!(uploadError)
          setShowFileList(true)
          dispatch(
            setAlert({
              alertMessage: "File format not supported.",
              alertType: "error",
            })
          )
          isFileChange = false
        }
      }

      // File type ok then remove file for btn
      if (isFileChange && type === "btn") {
        try {
          const response = await removeFile(datasetProps.fileName!)

          dispatch(setAlert({ alertMessage: null, alertType: undefined }))
          isFileChange = true
        } catch (error: unknown) {
          isFileChange = false
          dispatch(
            setAlert({
              alertMessage: generalAlertMessage,
              alertType: "error",
            })
          )
          if (error instanceof Error) {
            console.log(error.message)
          }
        }
      }

      //file type ok then upload new file
      if (isFileChange) {
        try {
          const response = await putFile(saveFileName, file, setProgress)

          if (type !== "backtest") {
            // insert new dataset info at database
            const result = await putDataset(newDataset)
            dispatch(setDatasetProps(newDataset))
          }

          options.onSuccess!(response)

          isFileChange = true
        } catch (error: unknown) {
          isFileChange = false

          if (type === "dragger") {
            modalError({
              name: datasetProps.datasetName!,
              type: "general",
              okAction: () => {
                return false
              },
              closeAction: closeModal,
            })
            closeModal()
          }

          if (type === "backtest") {
            dispatch(
              setAlert({
                alertMessage: defaultAlertMessage,
                alertType: "error",
              })
            )
          }

          if (type === "btn") {
            dispatch(
              setAlert({
                alertMessage: generalAlertMessage,
                alertType: "error",
              })
            )
          }

          if (error instanceof Error) {
            const uploadError = new Error(error.message)
            options.onError!(uploadError)
            setShowFileList(true)
          }
        }
      }

      // Upload new file ok then get file props
      if (isFileChange) {
        try {
          const response = await getDatasetPropsFromS3({
            fileName: saveFileName!
          })

          if (!response?.error) {
            newDataset = {
              datasetId: newDataset.datasetId,
              uploadBy: newDataset.uploadBy,
              uploadAt: newDataset.uploadAt,
              updatedAt: new Date().toLocaleString(),
              updatedBy: user?.email!,
              datasetName: newDataset.datasetName,
              fileName: newDataset.fileName,
              storagePath: newDataset.storagePath,
              policies: newDataset.policies,
              description: newDataset.description,
              size: response?.size,
              qtdRows: response?.records,
              sample: response?.sample,
              featureList: response!.features,
              providerBookList: [],
              providerFeatureList: [],
            }

            isFileChange = true
          } else {
            isFileChange = false

            if (type === "dragger") {
              modalError({
                name: datasetProps.datasetName!,
                type: "general",
                okAction: () => {
                  return false
                },
                closeAction: closeModal,
              })
              closeModal()
            }

            if (type === "btn") {
              dispatch(
                setAlert({
                  alertMessage: generalAlertMessage,
                  alertType: "error",
                })
              )
            }

            if (type === "backtest") {
              const responseRemove = await removeFile(saveFileName)
              isFileChange = false
              dispatch(
                setAlert({
                  alertMessage: defaultAlertMessage,
                  alertType: "error",
                })
              )
            }

            options.onError!(response.error)
            setShowFileList(true)
          }
        } catch {
          isFileChange = false

          modalError({
            name: datasetProps.datasetName!,
            type: "credential",
            okAction: () => {
              dispatch(logout())
              return false
            },
            closeAction: closeModal,
          })
        }
      }

      // get file props ok then update database
      if (isFileChange && type !== "backtest") {
        try {
          // insert new dataset info at database
          const result = await putDataset(newDataset)

          isFileChange = true
          if (setShowDialog) setShowDialog(false)
          if (setSuccess) setSuccess(true)
          dispatch(setDatasetProps(newDataset))
        } catch (error: unknown) {
          isFileChange = false

          if (type === "dragger") {
            modalError({
              name: datasetProps.datasetName!,
              type: "general",
              okAction: () => {
                return false
              },
              closeAction: closeModal,
            })
            closeModal()
          } else if (type === "btn") {
            dispatch(
              setAlert({
                alertMessage: generalAlertMessage,
                alertType: "error",
              })
            )
          }
        }
      }

      // get file props ok then check file features
      if (isFileChange && type === "backtest") {
        const checkDataset: boolean = checkDatasetLayout({
          sampleSelected: datasetProps.sample,
          sampleAvailable: newDataset.sample,
        })

        if (checkDataset) {
          isFileChange = true
          setFileName!(file.name)
          setSavedFileName!(saveFileName)
        } else {
          isFileChange = false
          const responseRemove = await removeFile(saveFileName)
          console.log("remove", saveFileName, responseRemove)

          const uploadError = new Error(
            "File is not compatible with the dataset used to create the policy"
          )
          options.onError!(uploadError)

          dispatch(
            setAlert({
              alertMessage: `Oops! The selected dataset is not compatible with your policy.
              Please upload a dataset that contains all features available in the dataset used to create the policy. You can explore dataset detais at the Policy detais area.`,
              alertType: "error",
            })
          )
        }
      }

      if (isFileChange) {
        setProgress(null)

        dispatch(
          setAlert({
            alertMessage: `File ${file.name} was successfully uploaded.`,
            alertType: "success",
          })
        )
      }

      isFileChange = false
      setLoading(false)
      setLoadingChangeFile(false)
    },
  }

  return (
    <div>
      {type === "dragger" && (
        <>
          <Dragger {...props} showUploadList={showFileList}>
            {progress ? (
              <div className={styles.dargger_progress_container}>
                <p className="ant-upload-drag-icon">
                  <FileSyncOutlined />
                </p>
                <p className="ant-upload-text">
                  Wait ... Your file is being uploaded ...
                </p>
                <p className="ant-upload-hint"></p>
                <Progress percent={progress} size="small" status="active" />
              </div>
            ) : (
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area
                </p>
                <br></br>
                <p className="ant-upload-hint">
                  Support for uploading in csv (comma separated values) file
                  format
                </p>
              </>
            )}
          </Dragger>
        </>
      )}

      {type === "btn" && (
        <>
          <Upload {...props} showUploadList={showFileList}>
            <Button
              icon={<UploadOutlined />}
              loading={loadingChangeFile}
              size="large"
            >
              Click here to change file
              <strong>
                {datasetProps.fileName?.replace(
                  datasetProps.datasetId! + "_",
                  " "
                )}
              </strong>
            </Button>
          </Upload>
          {progress && (
            <Progress percent={progress} size="small" status="active" />
          )}
        </>
      )}

      {type === "backtest" && (
        <>
          <Upload {...props} showUploadList={showFileList}>
            <Button
              icon={<UploadOutlined />}
              loading={loadingChangeFile}
              size="large"
            >
              Click to Upload
            </Button>
          </Upload>
          {progress && (
            <Progress percent={progress} size="small" status="active" />
          )}
        </>
      )}
    </div>
  )
}

export default UploadDatasetBtn
