//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setDatasetProps } from "../../redux-store/upload-dataset/dataset-setup-slice"

//antd
import { Input, Form } from "antd"

//types
import { datasetDb } from "../../types"

const { TextArea } = Input

type Props = {
	setState: React.Dispatch<React.SetStateAction<any>> | null
}

const DetailsInputs = ({ setState }: Props) => {
	const dispatch = useAppDispatch()

	//redux states
	const { datasetProps } = useAppSelector((state) => state.datasetProps)

	return (
		<>
			<Form.Item
				label="Name of dataset"
				name="datasetName"
				initialValue={datasetProps.datasetName}
				rules={[
					{
						required: true,
						message: "Please insert a name for your dataset",
					},
				]}
			>
				<Input
					size="large"
					placeholder="Insert a name for your dataset here"
					onChange={(e) => {
						const newDataset: datasetDb = {
							datasetId: datasetProps.datasetId,
							uploadBy: datasetProps.uploadBy,
							uploadAt: datasetProps.uploadAt,
							updatedAt: datasetProps.updatedAt,
							updatedBy: datasetProps.updatedBy,
							datasetName: e.target.value,
							fileName: datasetProps.fileName,
							storagePath: datasetProps.storagePath,
							policies: datasetProps.policies,
							description: datasetProps.description,
							size: datasetProps.size,
							qtdRows: datasetProps.qtdRows,
							sample: datasetProps.sample,
							featureList: datasetProps.featureList,
							providerBookList: datasetProps.providerBookList,
							providerFeatureList: datasetProps.providerFeatureList,
						}
						dispatch(setDatasetProps(newDataset))
						if (setState) setState(true)
					}}
				/>
			</Form.Item>

			<Form.Item
				label="Description"
				name="datasetDescription"
				initialValue={datasetProps.description}
				rules={[
					{
						required: true,
						message: "Please insert a description for your dataset",
					},
				]}
			>
				<TextArea
					size="large"
					placeholder="Insert a description for your dataset here"
					autoSize={true}
					onChange={(e) => {
						const newDataset: datasetDb = {
							datasetId: datasetProps.datasetId,
							uploadBy: datasetProps.uploadBy,
							uploadAt: datasetProps.uploadAt,
							updatedAt: datasetProps.updatedAt,
							updatedBy: datasetProps.updatedBy,
							datasetName: datasetProps.datasetName,
							fileName: datasetProps.fileName,
							storagePath: datasetProps.storagePath,
							policies: datasetProps.policies,
							description: e.target.value,
							size: datasetProps.size,
							qtdRows: datasetProps.qtdRows,
							sample: datasetProps.sample,
							featureList: datasetProps.featureList,
							providerBookList: datasetProps.providerBookList,
							providerFeatureList: datasetProps.providerFeatureList,
						}
						dispatch(setDatasetProps(newDataset))
						if (setState) setState(true)
					}}
				/>
			</Form.Item>
		</>
	)
}

export default DetailsInputs
