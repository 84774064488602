import type { MenuProps } from "antd"

type MenuItem = Required<MenuProps>["items"][number]

export function getItem(
	label: React.ReactNode,
	key: React.Key,
	icon?: React.ReactNode,
	onClick?: React.MouseEventHandler<HTMLAnchorElement> &
		React.MouseEventHandler<HTMLButtonElement>
): MenuItem {
	return {
		key,
		icon,
		label,
		onClick,
	} as MenuItem
}