import { IDataSelected, IDataSource, IPayloadIn } from "../../types"

type Props = {
  dataSource: IDataSource
  payloadIn: IPayloadIn
  dataSelected: IDataSelected
  providerList: any[]
  databaseList: any[]
  customFeatureList: any[]
}
export const createCascaderFeatureList = ({
  dataSource,
  dataSelected,
  payloadIn,
  providerList,
  databaseList,
  customFeatureList,
}: Props) => {
  const tmpFeatureList = []

  if (dataSource[4].payloadSourceCheck) {
    tmpFeatureList.push(payloadIn.cascaderPayloadList[0])
  }

  for (let i in dataSelected) {
    if (dataSelected[i].sourceStatus) {
      let index = providerList.findIndex((object: any) => {
        return object.Id === i
      })
      if (providerList[index])
        tmpFeatureList.push(providerList[index].description)

      index = databaseList.findIndex((object: any) => {
        return object.Id === i
      })
      if (databaseList[index])
        tmpFeatureList.push(databaseList[index].description)

      index = customFeatureList.findIndex((object: any) => {
        return object.Id === i
      })
      if (customFeatureList[index])
        tmpFeatureList.push(customFeatureList[index].description)
    }
  }

  return tmpFeatureList
}
