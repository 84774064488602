import Lambda from 'aws-sdk/clients/lambda'; // npm install aws-sdk
import { API } from "aws-amplify"

export const BacktestPolicy = async ({ policyId }: { policyId: string }) => {
	try {
		const event = {
			"policy_id": policyId,
			"delimiter": ","
		};

		const result = await API.post("policiesAPI", `/backtest/${policyId}`, { body: event })
		return result;
	} catch (error: unknown) {
		if (error instanceof Error) {
			const saveError = new Error(error.message)
			console.log(saveError)
			return null;
		}
	}
}