// redux
import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { persistStore } from "redux-persist"
import thunk from "redux-thunk"
import logger from "redux-logger"

//reducers
import authReducer from "./auth-reducer/authentication-slice"
import activeStepSlice from "./new-policy-reducer/active-step-slice"
import newPolicySlice from "./new-policy-reducer/new-policy-slice"
import nodeSlice from "./new-policy-reducer/node-slice"
import uploadSlice from "./upload-dataset/upload-dataset-slice"
import datasetPropsSlice from "./upload-dataset/dataset-setup-slice"
import featureStoreSlice from "./feature-store/feature-store-slice"


const rootReducer = combineReducers({
	auth: authReducer,
	activeStep: activeStepSlice,
	newPolicy: newPolicySlice,
	node: nodeSlice,
	uploadModal:uploadSlice,
	datasetProps:datasetPropsSlice,
	featureStore:featureStoreSlice,
})

export const store = configureStore({
	reducer: rootReducer,
	middleware: [thunk, logger] as const,
})

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)
