//css
import styles from "./upload-dataset.module.css"

//react
import { useState } from "react"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import {
  setAlert,
  setShowModal,
} from "../../redux-store/upload-dataset/upload-dataset-slice"
import { resetDatasetProps } from "../../redux-store/upload-dataset/dataset-setup-slice"

//antd
import { Modal, Button, Form, Space, Divider, Alert, Steps, Result } from "antd"

//services
import { removeFile } from "../../services/storage-service"
import { callDeleteDatasetService } from "../../services/dataset-database-service/call-dataset-service"

//components
import DetailsInputs from "../dataset/details-inputs"
import FeaturesSetupTable from "../dataset/feature-setup-table"
import SampleTable from "../dataset/sample-table"
import UploadDatasetBtn from "../dataset/upload-btn"
import { modalError } from "../dataset/modal-error"

//functions
import { saveDataset } from "../../functions/dataset/save-dataset"

const UploadDataset = () => {
  const dispatch = useAppDispatch()

  //redux states
  const { datasetProps } = useAppSelector((state) => state.datasetProps)
  const { showModal, alert } = useAppSelector((state) => state.uploadModal)

  //states
  const [isSuccessUpload, setIsSuccessUpload] = useState<boolean>(false)
  const [isSuccessSave, setIsSuccessSave] = useState<boolean>(false)
  const [loadingFeatures, setLoadingFeatures] = useState<boolean>(false)
  const [loadingCancel, setLoadingCancel] = useState<boolean>(false)
  const [loadingChangeFile, setLoadingChangeFile] = useState<boolean>(false)
  const [step, setStep] = useState<number>(0)

  const onCancel = async () => {
    setLoadingCancel(true)

    //delete saved data at database
    try {
      const response = await callDeleteDatasetService({
        id: datasetProps.datasetId!,
        file: datasetProps.fileName!,
      })
    } catch (error: unknown) {
      if (error instanceof Error) {
        modalError({
          name: datasetProps.datasetName!,
          type: "cancel",
          okAction: () => {
            return false
          },
          closeAction: closeModal,
        })
      }
    }

    //reset states and cleanup
    closeModal()
  }

  const closeModal = () => {
    //reset states and cleanup
    dispatch(setAlert({ alertMessage: null, alertType: undefined }))
    setIsSuccessUpload(false)
    dispatch(setShowModal(false))
    setLoadingFeatures(false)
    setStep(0)
    setLoadingChangeFile(false)
    dispatch(resetDatasetProps())
    setIsSuccessSave(false)
    setLoadingCancel(false)
  }

  const continueDetails = () => {
    setStep(1)
    dispatch(setAlert({ alertMessage: null, alertType: undefined }))
  }

  const continueUpload = async () => {
    if (datasetProps.fileName) {
      setStep(2)
      dispatch(setAlert({ alertMessage: null, alertType: undefined }))
    } else {
      dispatch(
        setAlert({
          alertMessage: "Please upload a file before continue.",
          alertType: "error",
        })
      )
    }
  }

  const changeFile = async () => {
    setLoadingChangeFile(true)

    try {
      const response = await removeFile(datasetProps.fileName!)
      console.log("remove response", response)

      callDeleteDatasetService({
        id: datasetProps.datasetId!,
        file: datasetProps.fileName!,
      })

      dispatch(setAlert({ alertMessage: null, alertType: undefined }))
      setIsSuccessUpload(false)
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.log(error.message)

        modalError({
          name: "",
          type: "default",
          okAction: () => {
            return false
          },
          closeAction: () => {},
        })
      }
    }
    setLoadingChangeFile(false)
  }

  const saveSetup = async () => {
    const saveStatus: boolean = await saveDataset({
      datasetProps: datasetProps,
    })
    if (saveStatus) {
      dispatch(setAlert({ alertMessage: null, alertType: undefined }))
      setIsSuccessSave(true)
    }
  }

  return (
    <Modal
      title="Upload a new dataset"
      centered
      width={1200}
      open={showModal}
      onCancel={onCancel}
      destroyOnClose
      footer={false}
      maskClosable={false}
    >
      <Divider />

      <Steps
        size="small"
        current={step}
        className={styles.steps}
        items={[
          {
            title: "Details",
          },
          {
            title: "Upload",
          },
          {
            title: "Setup",
          },
        ]}
      />

      {/* STEP 1: Details */}
      {step === 0 && (
        <Form layout="vertical" requiredMark={false} onFinish={continueDetails}>
          <DetailsInputs setState={null} />

          <Form.Item>
            <Space size="large" className={styles.container_footer_btn}>
              <Button type="primary" htmlType="submit">
                Continue
              </Button>
              <Button onClick={onCancel} loading={loadingCancel}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}

      {/* STEP 2: Upload */}
      {alert.alertMessage && step === 1 && (
        <Alert
          closable
          message={alert.alertMessage}
          showIcon
          type={alert.alertType}
          className={styles.error_alert}
        />
      )}

      {step === 1 && (
        <Form layout="vertical" requiredMark={false}>
          {isSuccessUpload && (
            <>
              <p>
                <strong>File Preview</strong>
              </p>
              <Space size="large" className={styles.container_db_details}>
                <p>
                  <strong>Name: </strong>
                  {datasetProps.fileName?.replace(
                    datasetProps.datasetId! + "_",
                    " "
                  )}
                </p>
                <p>
                  <strong>Size:</strong> {datasetProps.size}
                </p>
                <p>
                  <strong>Records amount:</strong> {datasetProps.qtdRows}
                </p>
              </Space>

              <SampleTable
                loading={false}
                styleProps={{
                  scrollBody: { x: "50vw" },
                }}
                sample={datasetProps.sample}
              />
            </>
          )}

          {!isSuccessUpload && (
            <div className={styles.dragger}>
              <UploadDatasetBtn
                id={datasetProps.datasetId!}
                type="dragger"
                bucketPath=""
                closeModal={closeModal}
                setLoading={setLoadingFeatures}
                setSuccess={setIsSuccessUpload}
                setShowDialog={null}
                setFileName={null}
                setSavedFileName={null}
              />
            </div>
          )}

          <Form.Item>
            <Space size="large" className={styles.container_footer_btn}>
              <Button
                type="primary"
                onClick={continueUpload}
                loading={loadingFeatures}
              >
                Continue
              </Button>

              {isSuccessUpload && (
                <Button onClick={changeFile} loading={loadingChangeFile}>
                  Change File
                </Button>
              )}

              <Button onClick={() => setStep(0)}>Back</Button>
            </Space>
          </Form.Item>
        </Form>
      )}

      {/* STEP 3: Setup */}
      {step === 2 && isSuccessSave && (
        <div>
          <Result
            status="success"
            title={`Dataset ${datasetProps.datasetName} was successfully uploaded.`}
            subTitle={`You can check its status at the Datasets Page`}
          />
          <Space size="large" className={styles.container_footer_btn}>
            <Button type="primary" onClick={closeModal}>
              Close
            </Button>
          </Space>
        </div>
      )}

      {step === 2 && !isSuccessSave && (
        <Form layout="vertical" requiredMark={false}>
          <div className={styles.container_db_details}>
            <h6>{datasetProps.datasetName} </h6>
            <Space size="large">
              <p>
                <strong>File:</strong>{" "}
                {datasetProps.fileName?.replace(
                  datasetProps.datasetId + "_",
                  " "
                )}
              </p>
              <p>
                <strong>Size:</strong> {datasetProps.size}
              </p>
              <p>
                <strong>Records:</strong> {datasetProps.qtdRows}
              </p>
            </Space>
            <p>
              <strong>Description:</strong> {datasetProps.description}
            </p>
          </div>

          <FeaturesSetupTable
            setState={null}
            styleProps={{
              searchWidth: "100%",
              selectWidth: "100%",
              scrollBody: { y: "42vh" },
            }}
          />

          <Form.Item>
            <Space size="large" className={styles.container_footer_btn}>
              <Button type="primary" onClick={saveSetup}>
                Save
              </Button>
              <Button
                onClick={() => {
                  setStep(1)
                }}
              >
                Back
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}

      <Divider className={styles.divider} />
    </Modal>
  )
}

export default UploadDataset
