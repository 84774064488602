import { Modal, message } from "antd"
import { ReactNode } from "react"

type modalProps = {
  title: string
  content: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  okButtonProps: {
    type:
      | "default"
      | "link"
      | "text"
      | "ghost"
      | "primary"
      | "dashed"
      | undefined
    style: { borderColor: string; color: string }
  }
}

type Props = {
  type: "errorDefault" | "errorIncomplete" | "errorFinalNode" | "successSave" |  "successProccess"
  attribute: string | null
}

export const savePolicyAlert = ({ type, attribute }: Props) => {
  let title: string = "Oops! Something went wrong."

  let alertMessage: ReactNode = (
    <p>
      Please try again later. <br />
      If the error persists contact the support for more help.
    </p>
  )

  if (type === "successSave") {
    message.success(`Policy ${attribute} was successfully saved!`, 3)
  } else if (type === "successProccess") {
    message.success(`Policy ${attribute} was successfully processed!`, 3)
  } else {
    if (type === "errorIncomplete") {
      title = "Oops! Your policy is incomplete."
      alertMessage = (
        <p>
          Missing important information about your policy. <br />
          Please review policy name, policy description, selected dataset and if
          you have setup at least one node before continue.
        </p>
      )
    }

    if (type === "errorFinalNode") {
      title = "Oops! Your policy is incomplete."
      alertMessage = (
        <p>
          Missing {attribute} final node(s). <br />
          Please review your policy before continue.
        </p>
      )
    }

    const modalProps: modalProps = {
      title: title,
      content: alertMessage,
      okButtonProps: {
        type: "default",
        style: { borderColor: "#ff4d4f", color: "#ff4d4f" },
      },
    }

    return Modal.error(modalProps)
  }
}
