

// feature API
export const sampleCatalog = [
	{
		featureId: "647F0001-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 0",
		name: "feature_0_0",
		description: "description feature 647F0001",
		favorite: true,
	},
	{
		featureId: "647F0002-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 1",
		name: "feature_0_1",
		description: "description feature 647F0002",
		favorite: true,
	},
	{
		featureId: "647F0003-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 2",
		name: "feature_0_2",
		description: "description feature 647F0003",
		favorite: true,
	},
	{
		featureId: "647F0004-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 3",
		name: "feature_0_3",
		description: "description feature 647F0004",
		favorite: true,
	},
	{
		featureId: "647F0005-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 4",
		name: "feature_0_4",
		description: "description feature 647F0005",
		favorite: true,
	},
	{
		featureId: "647F0006-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 5",
		name: "feature_0_5",
		description: "description feature 647F0006",
		favorite: true,
	},
	{
		featureId: "647F0007-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 6",
		name: "feature_0_6",
		description: "description feature 647F0007",
		favorite: true,
	},
	{
		featureId: "647F0008-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 7",
		name: "feature_0_7",
		description: "description feature 647F0008",
		favorite: false,
	},
	{
		featureId: "647F0009-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 8",
		name: "feature_0_8",
		description: "description feature 647F0009",
		favorite: false,
	},
	{
		featureId: "647F0010-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 9",
		name: "feature_0_9",
		description: "description feature 647F0010",
		favorite: false,
	},
	{
		featureId: "647F0011-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 10",
		name: "feature_0_10",
		description: "description feature 647F0011",
		favorite: false,
	},
	{
		featureId: "647F0012-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 11",
		name: "feature_0_11",
		description: "description feature 647F0012",
		favorite: false,
	},
	{
		featureId: "647F0013-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 12",
		name: "feature_0_12",
		description: "description feature 647F0013",
		favorite: false,
	},
	{
		featureId: "647F0014-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 13",
		name: "feature_0_13",
		description: "description feature 647F0014",
		favorite: false,
	},
	{
		featureId: "647F0015-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 14",
		name: "feature_0_14",
		description: "description feature 647F0015",
		favorite: false,
	},
	{
		featureId: "647F0016-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 15",
		name: "feature_0_15",
		description: "description feature 647F0016",
		favorite: false,
	},
	{
		featureId: "647F0017-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 16",
		name: "feature_0_16",
		description: "description feature 647F0017",
		favorite: false,
	},
	{
		featureId: "647F0018-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 17",
		name: "feature_0_17",
		description: "description feature 647F0018",
		favorite: false,
	},
	{
		featureId: "647F0019-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 18",
		name: "feature_0_18",
		description: "description feature 747F0019",
		favorite: false,
	},
	{
		featureId: "647F0020-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 19",
		name: "feature_0_19",
		description: "description feature 747F0020",
		favorite: false,
	},
	{
		featureId: "647F0021-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 20",
		name: "feature_0_20",
		description: "description feature 747F0021",
		favorite: false,
	},
	{
		featureId: "647F0022-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 21",
		name: "feature_0_21",
		description: "description feature 747F0022",
		favorite: false,
	},
	{
		featureId: "647F0023-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 22",
		name: "feature_0_22",
		description: "description feature 747F0023",
		favorite: false,
	},
	{
		featureId: "647F0024-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 23",
		name: "feature_0_23",
		description: "description feature 747F0024",
		favorite: false,
	},
	{
		featureId: "647F0025-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 24",
		name: "feature_0_24",
		description: "description feature 947F0025",
		favorite: false,
	},
	{
		featureId: "647F0026-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 25",
		name: "feature_0_25",
		description: "description feature 947F0026",
		favorite: false,
	},
	{
		featureId: "647F0027-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 26",
		name: "feature_0_26",
		description: "description feature 947F0027",
		favorite: false,
	},
	{
		featureId: "647F0028-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 27",
		name: "feature_0_27",
		description: "description feature 947F0028",
		favorite: false,
	},
	{
		featureId: "647F0029-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 28",
		name: "feature_0_28",
		description: "description feature 947F0029",
		favorite: false,
	},
	{
		featureId: "647F0030-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 29",
		name: "feature_0_29",
		description: "description feature 947F0030",
		favorite: false,
	},
	{
		featureId: "647F0031-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 30",
		name: "feature_0_30",
		description: "description feature 647F0030",
		favorite: false,
	},

	{
		featureId: "647F0042-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 0",
		favorite: false,
		name: "feature_1_0",
		description:
			" this is a description to explain datailed information of feature_1_0",
	},
	{
		featureId: "647F0043-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 1",
		favorite: true,
		name: "feature_1_1",
		description:
			" this is a description to explain datailed information of feature_1_1",
	},
	{
		featureId: "647F0044-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 2",
		favorite: true,
		name: "feature_1_2",
		description:
			" this is a description to explain datailed information of feature_1_2",
	},
	{
		featureId: "647F0045-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 3",
		favorite: true,
		name: "feature_1_3",
		description:
			" this is a description to explain datailed information of feature_1_3",
	},
	{
		featureId: "647F0046-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 4",
		favorite: false,
		name: "feature_1_4",
		description:
			" this is a description to explain datailed information of feature_1_4",
	},
	{
		featureId: "647F0047-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 5",
		favorite: false,
		name: "feature_1_5",
		description:
			" this is a description to explain datailed information of feature_1_5",
	},
	{
		featureId: "647F0048-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 6",
		favorite: false,
		name: "feature_1_6",
		description:
			" this is a description to explain datailed information of feature_1_6",
	},
	{
		featureId: "647F0049-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 7",
		favorite: false,
		name: "feature_1_7",
		description:
			" this is a description to explain datailed information of feature_1_7",
	},
	{
		featureId: "647F0050-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 8",
		favorite: false,
		name: "feature_1_8",
		description:
			" this is a description to explain datailed information of feature_1_8",
	},
	{
		featureId: "647F0051-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 9",
		favorite: true,
		name: "feature_1_9",
		description:
			" this is a description to explain datailed information of feature_1_9",
	},


	//--------------------------------------------------------

	//--------------------------------------------------------
]
