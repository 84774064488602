import { Modal, message } from "antd"

export const deleteById = async ({
	body,
	title,
	content,
	successMesssage,
	action,
	onCloseMessage,
}: {
	body: any
	title: string
	content: string
	successMesssage: string
	action: (body:any) => Promise<boolean>
	onCloseMessage: () => void
}) => {
	return Modal.confirm({
		title: title,
		content: content,
		okText: "Yes",
		cancelText: "No",
		onOk: async () => {
			let resp: boolean = false
			try {
				resp = await action(body)
			} catch (error) {
				console.log(error)
				resp = false
			}
			if (resp) {
				message.success(successMesssage, 3, onCloseMessage)
			} else {
				message.error("Oops! Something went wrong. Please try again later.", 5,onCloseMessage)
			}
		},
		onCancel: () => console.log("Cancel"),
		cancelButtonProps: { className: "modal-cancelBtn" },
		okButtonProps: { className: "modal-okBtn" },
	})
}
