// redux
import { createSlice, isAnyOf } from "@reduxjs/toolkit"

//athentication thunks
import { refreshToken, logout } from "./authentication-thunks"

//types
import { StoreStatus, IAuthState } from "../../types"

// Initial State
const initialState: IAuthState = {
  isAuthenticated: false,
  message: null,
  status: StoreStatus.Idle,
  user: null,
}

// Reducer
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      user: null,
      isAuthenticated: false,
      message: "The user is successfully signout",
      status: StoreStatus.Succeeded,
    }))
    builder.addCase(refreshToken.fulfilled, (state, { payload }) => ({
      ...state,
      user: payload,
      isAuthenticated: true,
      message: "The user is successfully authenticated",
      status: StoreStatus.Succeeded,
    }))

    // Handle pending & rejected requests
    builder.addMatcher(
      isAnyOf(logout.rejected, refreshToken.rejected),
      (state, { error }) => ({
        ...state,
        status: StoreStatus.Failed,
        message: error.message || "",
      })
    )

    builder.addMatcher(isAnyOf(logout.pending, refreshToken.pending), () => ({
      ...initialState,
      status: StoreStatus.Loading,
    }))
  },
})

export const { reset } = authSlice.actions

export default authSlice.reducer
