type sample = [string[]] | null

export const checkDatasetLayout = ({
  sampleSelected,
  sampleAvailable,
}: {
  sampleSelected: sample
  sampleAvailable: sample
}) => {
  let colSelected = []

  if (sampleSelected) {
    for (let j in sampleSelected) {
      for (let k in sampleSelected[j]) {
        if (j === "0") {
          //header
          colSelected.push(sampleSelected[j][k])
        }
      }
    }
  }

  let colAvailable = []
  if (sampleAvailable) {
    for (let j in sampleAvailable) {
      for (let k in sampleAvailable[j]) {
        if (j === "0") {
          //header
          colAvailable.push(sampleAvailable[j][k])
        }
      }
    }
  }

  const qtdFeatures: number = colSelected.length
  let qtdAvailableFeatures: number = 0

  for (let key in colSelected) {
    if (colAvailable.includes(colSelected[key])) qtdAvailableFeatures += 1
  }

  if (qtdAvailableFeatures === qtdFeatures) {
    return true
  } else {
    return false
  }
}
