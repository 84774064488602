//response of provider API all
export const providerList = [
	{
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		providerName: "PROVIDER 0",
		providerDescription: "decription of Provider 0",
		providerLongDescription:
			"Texto sobre o provedor Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		img: null,//"https://cadastro-positivo.vocequod.com.br/images/quod-logo.png",
		active: true,
	},
	{
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		providerName: "PROVIDER A",
		providerDescription: "decription of provider",
		providerLongDescription:
			"Texto sobre o provedor Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		img: null,
		active: true,
	},
	{
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3",
		providerName: "PROVIDER B",
		providerDescription: "decription of provider",
		providerLongDescription:
			"Texto sobre o provedor Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		img: null,
		active: true,
	},
	{
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		providerName: "PROVIDER C",
		providerDescription: "decription of provider",
		providerLongDescription:
			"Texto sobre o provedor Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		img: null,
		active: false,
	},
]

//response book API
export const bookList = [
	{
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		bookName: "Book Full",
		bookDescription: "decription of provider book: Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		active: true,
	},
	{
		bookId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		bookName: "book_long_name_test_with_very_large_0_0",
		bookDescription: "decription of provider book: Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		active: false,
	},
	{
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		bookName: "book_0_1",
		bookDescription: "decription of provider book: Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		active: true,
	},
	{
		bookId: "647C0003-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		bookName: "book_0_2",
		bookDescription: "decription of provider book: Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		active: true,
	},
	{
		bookId: "647C0004-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		bookName: "book_0_3",
		bookDescription: "decription of provider book: Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		active: true,
	},
	{
		bookId: "647C0005-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		bookName: "book_0_4",
		bookDescription: "decription of provider book: Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		active: false,
	},
	{
		bookId: "647C0007-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		bookName: "book_0_5",
		bookDescription: "decription of provider book: Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		active: false,
	},
	{
		bookId: "647C0006-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		bookName: "book_0_5",
		bookDescription: "decription of provider book: Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		active: false,
	},

	{
		bookId: "647C0011-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		bookName: "book_1_0",
		bookDescription: "decription of provider book: Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		active: true,
	},
	{
		bookId: "647C0012-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		bookName: "book_1_1",
		bookDescription: "decription of provider book: Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
		active: true,
	},
]

// feature API
export const sampleCatalog = [
	{
		featureId: "647F0001-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 0",
		name: "feature_0_0",
		description: "description feature 647F0001",
		favorite: false,
	},
	{
		featureId: "647F0002-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 1",
		name: "feature_0_1",
		description: "description feature 647F0002",
		favorite: false,
	},
	{
		featureId: "647F0003-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 2",
		name: "feature_0_2",
		description: "description feature 647F0003",
		favorite: false,
	},
	{
		featureId: "647F0004-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 3",
		name: "feature_0_3",
		description: "description feature 647F0004",
		favorite: false,
	},
	{
		featureId: "647F0005-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 4",
		name: "feature_0_4",
		description: "description feature 647F0005",
		favorite: false,
	},
	{
		featureId: "647F0006-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 5",
		name: "feature_0_5",
		description: "description feature 647F0006",
		favorite: false,
	},
	{
		featureId: "647F0007-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 6",
		name: "feature_0_6",
		description: "description feature 647F0007",
		favorite: false,
	},
	{
		featureId: "647F0008-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 7",
		name: "feature_0_7",
		description: "description feature 647F0008",
		favorite: false,
	},
	{
		featureId: "647F0009-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 8",
		name: "feature_0_8",
		description: "description feature 647F0009",
		favorite: false,
	},
	{
		featureId: "647F0010-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 9",
		name: "feature_0_9",
		description: "description feature 647F0010",
		favorite: false,
	},
	{
		featureId: "647F0011-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 10",
		name: "feature_0_10",
		description: "description feature 647F0011",
		favorite: false,
	},
	{
		featureId: "647F0012-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 11",
		name: "feature_0_11",
		description: "description feature 647F0012",
		favorite: false,
	},
	{
		featureId: "647F0013-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 12",
		name: "feature_0_12",
		description: "description feature 647F0013",
		favorite: false,
	},
	{
		featureId: "647F0014-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 13",
		name: "feature_0_13",
		description: "description feature 647F0014",
		favorite: false,
	},
	{
		featureId: "647F0015-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 14",
		name: "feature_0_14",
		description: "description feature 647F0015",
		favorite: false,
	},
	{
		featureId: "647F0016-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 15",
		name: "feature_0_15",
		description: "description feature 647F0016",
		favorite: false,
	},
	{
		featureId: "647F0017-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 16",
		name: "feature_0_16",
		description: "description feature 647F0017",
		favorite: false,
	},
	{
		featureId: "647F0018-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 17",
		name: "feature_0_17",
		description: "description feature 647F0018",
		favorite: false,
	},
	{
		featureId: "647F0019-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 18",
		name: "feature_0_18",
		description: "description feature 747F0019",
		favorite: false,
	},
	{
		featureId: "647F0020-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 19",
		name: "feature_0_19",
		description: "description feature 747F0020",
		favorite: false,
	},
	{
		featureId: "647F0021-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 20",
		name: "feature_0_20",
		description: "description feature 747F0021",
		favorite: false,
	},
	{
		featureId: "647F0022-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 21",
		name: "feature_0_21",
		description: "description feature 747F0022",
		favorite: false,
	},
	{
		featureId: "647F0023-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 22",
		name: "feature_0_22",
		description: "description feature 747F0023",
		favorite: false,
	},
	{
		featureId: "647F0024-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 23",
		name: "feature_0_23",
		description: "description feature 747F0024",
		favorite: false,
	},
	{
		featureId: "647F0025-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 24",
		name: "feature_0_24",
		description: "description feature 947F0025",
		favorite: false,
	},
	{
		featureId: "647F0026-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 25",
		name: "feature_0_25",
		description: "description feature 947F0026",
		favorite: false,
	},
	{
		featureId: "647F0027-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 26",
		name: "feature_0_26",
		description: "description feature 947F0027",
		favorite: false,
	},
	{
		featureId: "647F0028-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 27",
		name: "feature_0_27",
		description: "description feature 947F0028",
		favorite: false,
	},
	{
		featureId: "647F0029-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 28",
		name: "feature_0_28",
		description: "description feature 947F0029",
		favorite: false,
	},
	{
		featureId: "647F0030-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 29",
		name: "feature_0_29",
		description: "description feature 947F0030",
		favorite: false,
	},
	{
		featureId: "647F0031-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0000-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0000-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 30",
		name: "feature_0_30",
		description: "description feature 647F0030",
		favorite: false,
	},

	//book 1
	{
		featureId: "647F0201-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0007-05e9-11ee-8915-93d344fa88c3",
		providerId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 0",
		name: "feature_0_0",
		description:
			" this is a description to explain datailed information of feature_0_0",
		favorite: false,
	},
	{
		featureId: "647F0202-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0007-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 0",
		name: "feature_0_0",
		description:
			" this is a description to explain datailed information of feature_0_0",
		favorite: false,
	},
	{
		featureId: "647F0203-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0007-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 0",
		name: "feature_0_0",
		description:
			" this is a description to explain datailed information of feature_0_0",
		favorite: false,
	},

	{
		featureId: "647F0032-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 0",
		name: "feature_0_0",
		description:
			" this is a description to explain datailed information of feature_0_0",
		favorite: false,
	},
	{
		featureId: "647F0033-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 1",
		name: "feature_0_1",
		description:
			" this is a description to explain datailed information of feature_0_1",
		favorite: false,
	},
	{
		featureId: "647F0034-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 2",
		name: "feature_0_2",
		description:
			" this is a description to explain datailed information of feature_0_2",
		favorite: false,
	},
	{
		featureId: "647F0035-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 3",
		name: "feature_0_3",
		description:
			" this is a description to explain datailed information of feature_0_3",
		favorite: false,
	},
	{
		featureId: "647F0036-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 4",
		name: "feature_0_4",
		description:
			" this is a description to explain datailed information of feature_0_4",
		favorite: false,
	},
	{
		featureId: "647F0037-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 5",
		name: "feature_0_5",
		description:
			" this is a description to explain datailed information of feature_0_5",
		favorite: false,
	},
	{
		featureId: "647F0038-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 6",
		name: "feature_0_6",
		description:
			" this is a description to explain datailed information of feature_0_6",
		favorite: false,
	},
	{
		featureId: "647F0039-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 7",
		name: "feature_0_7",
		description:
			" this is a description to explain datailed information of feature_0_7",
		favorite: false,
	},
	{
		featureId: "647F0040-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 8",
		name: "feature_0_8",
		description:
			" this is a description to explain datailed information of feature_0_8",
		favorite: false,
	},
	{
		featureId: "647F0041-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0001-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 0 9",
		name: "feature_0_9",
		description:
			" this is a description to explain datailed information of feature_0_9",
		favorite: false,
	},

	{
		featureId: "647F0042-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 0",
		favorite: false,
		name: "feature_1_0",
		description:
			" this is a description to explain datailed information of feature_1_0",
	},
	{
		featureId: "647F0043-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 1",
		favorite: false,
		name: "feature_1_1",
		description:
			" this is a description to explain datailed information of feature_1_1",
	},
	{
		featureId: "647F0044-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 2",
		favorite: false,
		name: "feature_1_2",
		description:
			" this is a description to explain datailed information of feature_1_2",
	},
	{
		featureId: "647F0045-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 3",
		favorite: false,
		name: "feature_1_3",
		description:
			" this is a description to explain datailed information of feature_1_3",
	},
	{
		featureId: "647F0046-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 4",
		favorite: false,
		name: "feature_1_4",
		description:
			" this is a description to explain datailed information of feature_1_4",
	},
	{
		featureId: "647F0047-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 5",
		favorite: false,
		name: "feature_1_5",
		description:
			" this is a description to explain datailed information of feature_1_5",
	},
	{
		featureId: "647F0048-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 6",
		favorite: false,
		name: "feature_1_6",
		description:
			" this is a description to explain datailed information of feature_1_6",
	},
	{
		featureId: "647F0049-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 7",
		favorite: false,
		name: "feature_1_7",
		description:
			" this is a description to explain datailed information of feature_1_7",
	},
	{
		featureId: "647F0050-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 8",
		favorite: false,
		name: "feature_1_8",
		description:
			" this is a description to explain datailed information of feature_1_8",
	},
	{
		featureId: "647F0051-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0002-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 1 9",
		favorite: false,
		name: "feature_1_9",
		description:
			" this is a description to explain datailed information of feature_1_9",
	},

	{
		featureId: "647F0052-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0003-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 2 0",
		favorite: false,
		name: "feature_2_0",
		description:
			" this is a description to explain datailed information of feature_2_0",
	},
	{
		featureId: "647F0053-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0003-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 2 1",
		favorite: false,
		name: "feature_2_1",
		description:
			" this is a description to explain datailed information of feature_2_1",
	},
	{
		featureId: "647F0054-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0003-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 2 2",
		favorite: false,
		name: "feature_2_2",
		description:
			" this is a description to explain datailed information of feature_2_2",
	},
	{
		featureId: "647F0055-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0003-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 2 3",
		favorite: false,
		name: "feature_2_3",
		description:
			" this is a description to explain datailed information of feature_2_3",
	},
	{
		featureId: "647F0056-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0003-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 2 4",
		favorite: false,
		name: "feature_2_4",
		description:
			" this is a description to explain datailed information of feature_2_4",
	},
	{
		featureId: "647F0057-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0003-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 2 5",
		favorite: false,
		name: "feature_2_5",
		description:
			" this is a description to explain datailed information of feature_2_5",
	},
	{
		featureId: "647F0058-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0003-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 2 6",
		favorite: false,
		name: "feature_2_6",
		description:
			" this is a description to explain datailed information of feature_2_6",
	},
	{
		featureId: "647F0059-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0003-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 2 7",
		favorite: false,
		name: "feature_2_7",
		description:
			" this is a description to explain datailed information of feature_2_7",
	},
	{
		featureId: "647F0060-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0003-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 2 8",
		favorite: false,
		name: "feature_2_8",
		description:
			" this is a description to explain datailed information of feature_2_8",
	},
	{
		featureId: "647F0061-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0003-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 2 9",
		favorite: false,
		name: "feature_2_9",
		description:
			" this is a description to explain datailed information of feature_2_9",
	},

	{
		featureId: "647F0062-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0004-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 3 0",
		favorite: false,
		name: "feature_3_0",
		description:
			" this is a description to explain datailed information of feature_3_0",
	},
	{
		featureId: "647F0063-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0004-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 3 1",
		favorite: false,
		name: "feature_3_1",
		description:
			" this is a description to explain datailed information of feature_3_1",
	},
	{
		featureId: "647F0064-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0004-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 3 2",
		favorite: false,
		name: "feature_3_2",
		description:
			" this is a description to explain datailed information of feature_3_2",
	},
	{
		featureId: "647F0065-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0004-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 3 3",
		favorite: false,
		name: "feature_3_3",
		description:
			" this is a description to explain datailed information of feature_3_3",
	},
	{
		featureId: "647F0066-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0004-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 3 4",
		favorite: false,
		name: "feature_3_4",
		description:
			" this is a description to explain datailed information of feature_3_4",
	},
	{
		featureId: "647F0067-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0004-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 3 5",
		favorite: false,
		name: "feature_3_5",
		description:
			" this is a description to explain datailed information of feature_3_5",
	},
	{
		featureId: "647F0068-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0004-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 3 6",
		favorite: false,
		name: "feature_3_6",
		description:
			" this is a description to explain datailed information of feature_3_6",
	},
	{
		featureId: "647F0069-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0004-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 3 7",
		favorite: false,
		name: "feature_3_7",
		description:
			" this is a description to explain datailed information of feature_3_7",
	},
	{
		featureId: "647F0060705e9-11ee-8915-93d344fa88c3",
		bookId: "647C0004-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 3 8",
		favorite: false,
		name: "feature_3_8",
		description:
			" this is a description to explain datailed information of feature_3_8",
	},
	{
		featureId: "647F0071-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0004-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 3 9",
		favorite: false,
		name: "feature_3_9",
		description:
			" this is a description to explain datailed information of feature_3_9",
	},

	//--------------------------------------------------------

	{
		featureId: "647F0072-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0005-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 4 0",
		favorite: false,

		name: "feature_4_0",
		description:
			" this is a description to explain datailed information of feature_4_0",
	},
	{
		featureId: "647F0073-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0005-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 4 1",
		favorite: false,
		name: "feature_4_1",
		description:
			" this is a description to explain datailed information of feature_4_1",
	},
	{
		featureId: "647F0074-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0005-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 4 2",
		favorite: false,
		name: "feature_4_2",
		description:
			" this is a description to explain datailed information of feature_4_2",
	},
	{
		featureId: "647F0075-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0005-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 4 3",
		favorite: false,
		name: "feature_4_3",
		description:
			" this is a description to explain datailed information of feature_4_3",
	},
	{
		featureId: "647F0076-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0005-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 4 5",
		favorite: false,
		name: "feature_4_5",
		description:
			" this is a description to explain datailed information of feature_4_5",
	},
	{
		featureId: "647F0077-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0005-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 4 6",
		favorite: false,
		name: "feature_4_6",
		description:
			" this is a description to explain datailed information of feature_4_6",
	},
	{
		featureId: "647F0078-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0005-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 4 7",
		favorite: false,
		name: "feature_4_7",
		description:
			" this is a description to explain datailed information of feature_4_7",
	},
	{
		featureId: "647F0079-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0005-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 4 8 ",
		favorite: false,
		name: "feature_4_8",
		description:
			" this is a description to explain datailed information of feature_4_8",
	},
	{
		featureId: "647F0080-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0005-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 4 9",
		favorite: false,
		name: "feature_4_9",
		description:
			" this is a description to explain datailed information of feature_4_9",
	},
	{
		featureId: "647F0081-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0005-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0001-05e9-11ee-8915-93d344fa88c3",
		label: "feature 4 4",
		favorite: false,
		name: "feature_4_4",
		description:
			" this is a description to explain datailed information of feature_4_4",
	},

	//--------------------------------------------------------

	{
		featureId: "647F0082-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0006-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		label: "feature 5 0",
		favorite: false,
		name: "feature_5_0",
		description:
			" this is a description to explain datailed information of feature_5_0",
	},
	{
		featureId: "647F0083-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0006-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		label: "feature 5 1",
		favorite: false,
		name: "feature_5_1",
		description:
			" this is a description to explain datailed information of feature_5_1",
	},
	{
		featureId: "647F0084-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0006-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		label: "feature 5 2",
		favorite: false,
		name: "feature_5_2",
		description:
			" this is a description to explain datailed information of feature_5_2",
	},
	{
		featureId: "647F0085-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0006-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		label: "feature 5 3",
		favorite: false,
		name: "feature_5_3",
		description:
			" this is a description to explain datailed information of feature_5_3",
	},
	{
		featureId: "647F0086-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0006-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		label: "feature 5 4",
		favorite: false,
		name: "feature_5_4",
		description:
			" this is a description to explain datailed information of feature_5_4",
	},
	{
		featureId: "647F0087-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0006-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		label: "feature 5 5",
		favorite: false,
		name: "feature_5_5",
		description:
			" this is a description to explain datailed information of feature_5_5",
	},
	{
		featureId: "647F0088-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0006-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		label: "feature 5 6",
		favorite: false,
		name: "feature_5_6",
		description:
			" this is a description to explain datailed information of feature_5_6",
	},
	{
		featureId: "647F0089-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0006-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		label: "feature 5 7",
		favorite: false,
		name: "feature_5_7",
		description:
			" this is a description to explain datailed information of feature_5_7",
	},
	{
		featureId: "647F0090-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0006-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		label: "feature 5 8",
		favorite: false,
		name: "feature_5_8",
		description:
			" this is a description to explain datailed information of feature_5_8",
	},
	{
		featureId: "647F0091-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0006-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0003-05e9-11ee-8915-93d344fa88c3",
		label: "feature 5 9",
		favorite: false,
		name: "feature_5_9",
		description:
			" this is a description to explain datailed information of feature_5_9",
	},

	//--------------------------------------------------------

	{
		featureId: "647F0092-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0011-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 0 0",
		favorite: false,
		name: "feature_0_0",
		description:
			" this is a description to explain datailed information of feature_0_0",
	},
	{
		featureId: "647F0093-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0011-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 0 1",
		favorite: false,
		name: "feature_0_1",
		description:
			" this is a description to explain datailed information of feature_0_1",
	},
	{
		featureId: "647F0094-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0011-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 0 2",
		favorite: false,
		name: "feature_0_2",
		description:
			" this is a description to explain datailed information of feature_0_2",
	},
	{
		featureId: "647F0095-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0011-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 0 3",
		favorite: false,
		name: "feature_0_3",
		description:
			" this is a description to explain datailed information of feature_0_3",
	},
	{
		featureId: "647F0096-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0011-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 0 4",
		favorite: false,
		name: "feature_0_4",
		description:
			" this is a description to explain datailed information of feature_0_4",
	},
	{
		featureId: "647F0097-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0011-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 0 5",
		favorite: false,
		name: "feature_0_5",
		description:
			" this is a description to explain datailed information of feature_0_5",
	},
	{
		featureId: "647F0098-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0011-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 0 6",
		favorite: false,
		name: "feature_0_6",
		description:
			" this is a description to explain datailed information of feature_0_6",
	},
	{
		featureId: "647F0099-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0011-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 0 7",
		favorite: false,
		name: "feature_0_7",
		description:
			" this is a description to explain datailed information of feature_0_7",
	},
	{
		featureId: "647F0100-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0011-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 0 8",
		favorite: false,
		name: "feature_0_8",
		description:
			" this is a description to explain datailed information of feature_0_8",
	},
	{
		featureId: "647F0101-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0011-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 0 9",
		favorite: false,
		name: "feature_0_9",
		description:
			" this is a description to explain datailed information of feature_0_9",
	},

	//--------------------------------------------------------

	{
		featureId: "647F0102-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0012-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 1 0",
		favorite: false,
		name: "feature_1_0",
		description:
			" this is a description to explain datailed information of feature_1_0",
	},
	{
		featureId: "647F0103-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0012-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 1 1",
		favorite: false,
		name: "feature_1_1",
		description:
			" this is a description to explain datailed information of feature_1_1",
	},
	{
		featureId: "647F0104-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0012-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 1 2",
		favorite: false,
		name: "feature_1_2",
		description:
			" this is a description to explain datailed information of feature_1_2",
	},
	{
		featureId: "647F0105-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0012-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 1 3",
		favorite: false,
		name: "feature_1_3",
		description:
			" this is a description to explain datailed information of feature_1_3",
	},
	{
		featureId: "647F0106-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0012-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 1 4",
		favorite: false,
		name: "feature_1_4",
		description:
			" this is a description to explain datailed information of feature_1_4",
	},
	{
		featureId: "647F0107-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0012-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 1 5",
		favorite: false,
		name: "feature_1_5",
		description:
			" this is a description to explain datailed information of feature_1_5",
	},
	{
		featureId: "647F0108-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0012-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 1 6",
		favorite: false,
		name: "feature_1_6",
		description:
			" this is a description to explain datailed information of feature_1_6",
	},
	{
		featureId: "647F0109-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0012-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 1 7",
		favorite: false,
		name: "feature_1_7",
		description:
			" this is a description to explain datailed information of feature_1_7",
	},
	{
		featureId: "647F0110-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0012-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 1 8",
		favorite: false,
		name: "feature_1_8",
		description:
			" this is a description to explain datailed information of feature_1_8",
	},
	{
		featureId: "647F0111-05e9-11ee-8915-93d344fa88c3",
		bookId: "647C0012-05e9-11ee-8915-93d344fa88c3",
		providerId: "647P0002-05e9-11ee-8915-93d344fa88c3", //prov1
		label: "feature 1 9",
		favorite: false,
		name: "feature_1_9",
		description:
			" this is a description to explain datailed information of feature_1_9",
	},

	//--------------------------------------------------------

	//--------------------------------------------------------
]
