//styes
import "./styles/index.css"

//components
import App from "./App"

//react
import React from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"

//router
import { BrowserRouter } from "react-router-dom"

//redux
import { Provider } from "react-redux"
import { store } from "./redux-store/store"

//antd
import { ConfigProvider } from "antd"

//Amplify
import { Amplify } from "aws-amplify"
import awsconfig from "./aws-exports"
import "@aws-amplify/ui-react/styles.css"
Amplify.configure(awsconfig)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
		<Provider store={store}>
			<BrowserRouter>
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: "#2394c5",
							colorSuccess: "#6ed178",
							colorWarning: "#eebb16",
							colorError: "#ff4d4f",
							colorInfo: "#2394c5",
							colorTextBase: "#373737",
							borderRadius: 4,
						},
					}}
				>
					<App />
				</ConfigProvider>
			</BrowserRouter>
		</Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
