//amplify
import { API } from "aws-amplify"

//types
import { policyJson } from "../../types"

export const putDataset = async (data: policyJson) => {
  try {
    const result = await API.post("policiesAPI", "/policies", {
      body: data,
    })

    return result
  } catch (error) {
    console.log(error)
    return error
  }
}
