import { Modal } from "antd"
import { ReactNode } from "react"

type modalProps = {
  title: string
  content: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  maskClosable: boolean
  afterClose: () => void
  okButtonProps: {
    type:
      | "default"
      | "link"
      | "text"
      | "ghost"
      | "primary"
      | "dashed"
      | undefined
    style: { borderColor: string; color: string }
  }
  okText: string
  onOk: () => void
}

type Props = {
  name: string
  type:
    | "general"
    | "credential"
    | "cancel"
    | "default"
    | "checkDataset"
    | "setupDataset"
    | "containPolicy"
  okAction: () => void
  closeAction: () => void
}

export const modalError = ({ name, type, okAction, closeAction }: Props) => {
  let title: string = "Oops! Something went wrong."
  const onOk: () => void = () => {
    okAction()
  }
  const afterClose: () => void = () => {
    closeAction()
  }

  let message: ReactNode = (
    <p>
      Please try again later. You can check the status of your <br />
      dataset <strong> {name} </strong> at the Dataset's page. <br />
      If the error persists contact the support for more help.
    </p>
  )

  let okText: string = "OK"

  if (type === "credential") {
    message = (
      <p>
        There was a problem to get your access credentials. <br />
        Please try to log out and sign in again. <br />
        If the error persists contact the support for more help.
      </p>
    )

    okText = "Log Out"
  }

  if (type === "cancel") {
    message = (
      <p>
        It was not possible to cancel the dataset upload action at the moment.{" "}
        <br />
        Please delete datset <strong> {name} </strong> at the Dataset's page.{" "}
        <br />
        If the error persists contact the support for more help.
      </p>
    )
  }

  if (type === "default") {
    message = (
      <p>
        Please try again later. <br />
        If the error persists contact the support for more help.
      </p>
    )
  }

  if (type === "checkDataset") {
    title = "Oops! The selected dataset is not compatible with your policy."
    message = (
      <p>
        Please select a dataset that contains the same available features as in
        the current dataset. You can explore dataset details at the Dataset's
        page.
        <br />
      </p>
    )
  }

  if (type === "setupDataset") {
    title = "Oops! There is no available features."
    message = (
      <p>
        It seems that you have skipped the dataset feature setup step. Please go
        back to the setup step or choose a different dataset.
      </p>
    )
  }

  if (type === "containPolicy") {
    title = "Oops! The selected dataset is associated to a policy."
    message = (
      <p>
        It is not possible to delete dataset {name} since it has been associated to one or more policies.
        Please delete the associated policies and try again.
      </p>
    )
  }

  const modalProps: modalProps = {
    title: title,
    content: message,
    okButtonProps: {
      type: "default",
      style: { borderColor: "#ff4d4f", color: "#ff4d4f" },
    },
    okText: okText,
    onOk: onOk,
    maskClosable: false,
    afterClose: afterClose,
  }

  return Modal.error(modalProps)
}
