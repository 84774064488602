//css
import styles from "./initial-data.module.css"

//react router dom
import { useNavigate } from "react-router-dom"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setActiveStep } from "../../redux-store/new-policy-reducer/active-step-slice"
import {
  submmitNewPolicyInitialData,
  submmitDescription,
  submmitName,
  submmitGroupName,
  submmitGroupDescription,
} from "../../redux-store/new-policy-reducer/new-policy-slice"

//antd
import { Input, Button, Form, Space, Divider, Select } from "antd"

const selectItemsFinalNode: { value: string; label: string }[] = [
  { value: "status", label: "Status" },
  { value: "value", label: "Value" },
]

type Props = {
  componentId: number
  showBtn: boolean
}
const { TextArea } = Input

const uuid = require("uuid")

const InitialData = ({ componentId, showBtn }: Props) => {
  const dispatch = useAppDispatch()
  const { policyName, description } = useAppSelector((state) => state.newPolicy)

  return (
    <div className={styles.container_form}>
      <Form
        layout="vertical"
        requiredMark={false}
        initialValues={{
          policyName: policyName,
          description: description,
        }}
      >
        <div className={styles.divider}>
          <Form.Item
            label="Version name"
            name="policyName"
            tooltip="Name used to identify the new policy version that will be added to the selected group."
            rules={[
              {
                required: true,
                message: "Please insert a policy name",
              },
            ]}
          >
            <Input
              placeholder="Insert a name for your policy version here"
              size="large"
              onChange={(e) => {
                dispatch(submmitName({ policyName: e.target.value }))
              }}
            />
          </Form.Item>

          <Form.Item
            label="Version description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input a description",
              },
            ]}
          >
            <TextArea
              size="large"
              autoSize={true}
              placeholder="Insert a description for your policy here"
              onChange={(e) => {
                dispatch(submmitDescription({ description: e.target.value }))
              }}
            />
          </Form.Item>
        </div>

        <div className={styles.container_button}>
          <Space size="large">
            <Form.Item>
              <Button
                size="large"
                type="primary"
                onClick={() => dispatch(setActiveStep(componentId + 1))}
              >
                Continue
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                onClick={() => dispatch(setActiveStep(componentId - 1))}
              >
                Back
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default InitialData
