// redux
import { createSlice } from "@reduxjs/toolkit"

//types
import { IFeatureStoreState } from "../../types"

// Initial State
const initialState: IFeatureStoreState = {
  showBook: {
    show: false,
    providerId: null,
    providerName: null,
    providerDescription: null,
    providerActive: false,
  },
  showFeature: {
    show: false,
    bookId: null,
    bookName: null,
    bookActive: false,
  },
  userFeatureList: [],
}

// Reducer
export const featureStoreSlice = createSlice({
  name: "featureStore",
  initialState,
  reducers: {
    resetShowBookFeature: () => initialState,
    resetShowBook: (state) => {
      return {
        ...state,
        showBook: initialState.showBook,
      }
    },
    resetShowFeature: (state) => {
      return {
        ...state,
        showFeature: initialState.showFeature,
      }
    },

    setShowBook: (state, action) => {
      return {
        ...state,
        showBook: action.payload,
      }
    },
    setShowFeature: (state, action) => {
      return {
        ...state,
        showFeature: action.payload,
      }
    },
    setUserFeatureList: (state, action) => {
      return {
        ...state,
        userFeatureList: action.payload,
      }
    },
  },
})

export const {
  resetShowBook,
  resetShowFeature,
  resetShowBookFeature,
  setShowBook,
  setShowFeature,
  setUserFeatureList,
} = featureStoreSlice.actions

export default featureStoreSlice.reducer
