import { IDataSelected, INodeProps } from "../../types"

type Props = {
  policyName: string | null
  policyGroupName: string | null
  datasetId: string | null
  dataSelected: IDataSelected | null
  nodesProps: INodeProps | null
}

export const checkPolicyOnSave = ({
  policyName,
  policyGroupName,
  datasetId,
  dataSelected,
  nodesProps,
}: Props) => {
  //check initial properties: name, description, dataset
  let checkInitialProps: boolean = false
  let checkNodes: boolean = false
  let checkDataSource: boolean = false

  if (policyName && policyGroupName) checkInitialProps = true

  let qtdDataSource: number = 0
  for (let key in dataSelected) {
    if (dataSelected[key].sourceStatus) qtdDataSource += 1
  }
  if (qtdDataSource > 0 || datasetId) checkDataSource = true

  //ckeck if it has at least one filled node
  let qtdNodes: number = 0
  for (let key in nodesProps) {
    qtdNodes += 1
  }
  if (qtdNodes > 1) checkNodes = true
  console.log(
    qtdDataSource,
    datasetId,
    checkInitialProps,
    checkDataSource,
    checkNodes
  )
  return {
    checkInitialProps: checkInitialProps,
    checkDataSource: checkDataSource,
    checkNodes: checkNodes,
  }
}
