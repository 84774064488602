//react
import { useState } from "react"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import {
  resetActiveStep,
  setActiveStep,
  setIsPolicySaved,
} from "../../redux-store/new-policy-reducer/active-step-slice"
import {
  resetNewPolicy,
  submitSelectedFeature,
  updateRules,
} from "../../redux-store/new-policy-reducer/new-policy-slice"
import { resetNode } from "../../redux-store/new-policy-reducer/node-slice"

//router
import { useNavigate } from "react-router-dom"

//antd
import { Button, Space } from "antd"

//components
import { savePolicyAlert } from "./save-proccess-policy-alert"

//utils & functions
import { savePolicy } from "../../functions/policy/save-policy"
import { processPolicy } from "../../functions/policy/process-policy"

//types
import { INodeRule } from "../../types"

type Props = {
  componentId: number
}

const SavePolicyBtn = ({ componentId }: Props) => {
  const dispatch = useAppDispatch()

  let navigate = useNavigate()

  const {
    policyName,
    description,
    datasetName,
    datasetId,
    datasetPath,
    rules,
    policy_id,
    created_at,
    created_by,
    policy_status,
    policy_record_id,
    metabasePath,
    datasetDecisionPath,
    dataSource,
    policyGroupName,
    policyGroupDescription,
    policyGroupFinalType,
    payloadIn,
    payloadOut,
    dataSelected,
  } = useAppSelector((state) => state.newPolicy)
  const { nodesProps } = useAppSelector((state) => state.node)
  const { user } = useAppSelector((state) => state.auth)

  const [loadingSavePolicy, setLoadingSavePolicy] = useState<boolean>(false)
  const [loadingProcessPolicy, setLoadingProcessPolicy] =
    useState<boolean>(false)

  const save = async () => {
    setLoadingSavePolicy(true)

    const status = await savePolicy({
      user: user?.email!,
      policyId: policy_id!,
      policyName: policyName!,
      description: description!,
      datasetName: datasetName!,
      datasetPath: datasetPath!,
      datasetId: datasetId!,
      createdAt: created_at!,
      createdBy: created_by!,
      policyStatus: policy_status!,
      policyRecordId: policy_record_id!,
      metabasePath: metabasePath!,
      datasetDecisionPath: datasetDecisionPath!,
      nodesProps: nodesProps,
      rules: rules,
      policyGroupName: policyGroupName!,
      policyGroupDescription: policyGroupDescription!,
      policyGroupFinalType: policyGroupFinalType!,
      payloadIn: payloadIn,
      payloadOut: payloadOut,
      dataSource: dataSource,
      dataSelected: dataSelected,
    })

    savePolicyAlert({ type: status.alertType, attribute: status.attribute })

    dispatch(
      submitSelectedFeature({
        name: null,
        dataType: null,
        allowedValues: null,
        source: null,
      })
    )

    setLoadingSavePolicy(false)

    return status.success
  }

  const process = async () => {
    setLoadingProcessPolicy(true)

    //save policy
    const statusSave = await savePolicy({
      user: user?.email!,
      policyId: policy_id!,
      policyName: policyName!,
      description: description!,
      datasetName: datasetName!,
      datasetPath: datasetPath!,
      datasetId: datasetId!,
      createdAt: created_at!,
      createdBy: created_by!,
      policyStatus: policy_status!,
      policyRecordId: policy_record_id!,
      metabasePath: metabasePath!,
      datasetDecisionPath: datasetDecisionPath!,
      nodesProps: nodesProps,
      rules: rules,
      policyGroupName: policyGroupName!,
      policyGroupDescription: policyGroupDescription!,
      policyGroupFinalType: policyGroupFinalType!,
      payloadIn: payloadIn,
      payloadOut: payloadOut,
      dataSource: dataSource,
      dataSelected: dataSelected,
    })

    savePolicyAlert({
      type: statusSave.alertType,
      attribute: statusSave.attribute,
    })

    if (statusSave.success) {
      //proccess
      const statusProccess = await processPolicy({
        policyId: policy_id!,
        policyName: policyName!,
        nodesProps: nodesProps,
        rules: rules,
        dispatchRules: (rules: { [nodeId: string]: INodeRule }) => {
          dispatch(updateRules(rules))
        },
      })

      savePolicyAlert({
        type: statusProccess.alertType,
        attribute: statusProccess.attribute,
      })
    }

    setLoadingProcessPolicy(false)
  }

  return (
    <Space size="large">
      {/*<Button
        size="large"
        type="primary"
        htmlType="submit"
        onClick={() => dispatch(setActiveStep(componentId + 1))}
      >
        Continue
  </Button>*/}

      <Button
        size="large"
        type="primary"
        onClick={save}
        loading={loadingSavePolicy}
      >
        Save Draft
      </Button>

      {dataSource[0].datasetSourceCheck && (
        <Button size="large" loading={loadingProcessPolicy} onClick={process}>
          Save & Process
        </Button>
      )}
    </Space>
  )
}

export default SavePolicyBtn
