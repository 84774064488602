//styles
import { Button, Collapse, CollapseProps, Divider, Input, Table, Tabs } from "antd"
import styles from "./connection.module.css"
import type { ColumnsType } from "antd/es/table"
import { sampleCatalog } from "./favorite-features"
import { bookList, providerList } from "../feature-store/providers-data"
import FavoriteFeatureTable from "../../components/providers/favorite-feature-table";

//get data:
type fetureList = {
	key: string
	providerName: string
	providerId: string
	bookId: string
	bookName: string
	featureList: {
		key: string
		name: string
		description: string
	}[]
}

// get providers and books:
const bookfeature: fetureList[] = []

for (let i in bookList) {
	const index = providerList.findIndex((object) => {
		return object.providerId === bookList[i].providerId
	})

	let features = []
	for (let j in sampleCatalog) {
		if (
			bookList[i].active === true &&
			bookList[i].bookId === sampleCatalog[j].bookId &&
			sampleCatalog[j].favorite === true
		) {
			features.push({
				key: sampleCatalog[j].featureId,
				//label: sampleCatalog[j].label,
				name: sampleCatalog[j].name,
				description: sampleCatalog[j].description,
				//favorite:sampleCatalog[j].favorite,
			})
		}
	}
	if (features.length > 1)
		bookfeature.push({
			key: bookList[i].bookId,
			providerName: providerList[index].providerName,
			providerId: bookList[i].providerId,
			bookId: bookList[i].bookId,
			bookName: bookList[i].bookName,
			featureList: features,
		})
}

console.log("teste", bookfeature)

const { TabPane } = Tabs

type Props = {}

const Connection = (props: Props) => {
	const { Search } = Input

	const columns: ColumnsType<fetureList> = [
		{
			title: "Provider",
			dataIndex: "providerName",
			key: "providerName",
			//filteredValue: [searchedName],
			//onFilter: (value: string | number | boolean, record: fetureList) =>
			//	String(record.name).toLowerCase().includes(String(value).toLowerCase()),
		},
		{
			title: "Catalogue (book)",
			dataIndex: "bookName",
			key: "bookName",
		},
	]

	return (
		<div className={`container-page ${styles.card_container}`}>
			<Tabs onChange={() => console.log("tab")} size="large">
				<TabPane tab="Catalogs" key="1">
					<div>
						<Table
							//className={styles.table}
							bordered
							columns={columns}
							dataSource={bookfeature}
							size="small"
							expandable={{
								expandedRowRender: (record) => (
									<div className={styles.connection_table}>
										<FavoriteFeatureTable
										  bookId={record.bookId}
											bookName={record.bookName}
											featureList={record.featureList}
											scrollBody={{}}
											styleProps={{searchWidth:"80%"}}
										/>
									</div>
								),
							}}
							//loading={loading}
							//pagination={true}
							//pagination={{ pageSize: 10 }}
						/>
					</div>
				</TabPane>
				<TabPane tab="Endpoints" key="2">
					Content of Tab Pane 2
				</TabPane>
			</Tabs>
		</div>
	)
}

export default Connection
