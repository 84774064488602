//active step
export type IActiveNode = {
  activeNode: {
    id: string | null
  }
}

export type INodeRule = {
  feature: string[]
  featureLabel: string[]
  operator: string | null
  threshold: string | null
  dataSourceType: "provider" | "database" | "custom" | "payload" | null
  isfinalNode: boolean
  decisionType: "status" | "value" | null
  decisionStatus: "approved" | "reproved" | "manual analysis" | null
  decisionValue: string | null
  isDecisionStatus: boolean
  isDecisionValue: boolean
  isDeleted: boolean
  volumetry: number | null
  decisionReason: string | null
}

export const nodeSize: {
  nodeWidth: number
  nodeHeight: number
  vSpace: number
  hSpace: number
} = {
  nodeWidth: 230,
  nodeHeight: 120,
  vSpace: 2 * 120, // 2*nodeHeight
  hSpace: 0.5 * 230 + 10, //0.5 * nodeWidth + 10
}
