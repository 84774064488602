//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"

//antd
import { Cascader, Form, Input, Select } from "antd"
import {
  selectedDataSource,
  setRuleFeatureSource,
  submitDataSource,
  submitFeatureLabel,
  submitSelectedFeature,
} from "../../redux-store/new-policy-reducer/new-policy-slice"

interface CascadeOption {
  value: string
  label: string
  children?: CascadeOption[]
  disabled?: boolean
}

const selectItemsOperator: { value: string; label: string }[] = [
  { value: ">", label: ">" },
  { value: ">=", label: ">=" },
  { value: "<", label: "<" },
  { value: "<=", label: "<=" },
  { value: "=", label: "=" },
  { value: "!=", label: "!=" },
  { value: "in", label: "IN" },
  { value: "not in", label: "NOT IN" },
  { value: "rf compare", label: "RF COMPARE" },
]

export const FeatureSetUp = ({
  required,
  id,
}: {
  required: boolean
  id: string
}) => {
  const dispatch = useAppDispatch()

  const { datasetName, dataSource, dataSelected, cascaderFeatureList } =
    useAppSelector((state) => state.newPolicy)

  const selectItemsFeature: CascadeOption[] = cascaderFeatureList

  return (
    <>
      <Form.Item
        label="Feature"
        name="feature"
        rules={[
          {
            required: required,
            message: "Please select a feature",
          },
        ]}
      >
        <Cascader
          className="nodrag"
          placeholder="Select"
          size="small"
          expandTrigger="hover"
          options={selectItemsFeature}
          getPopupContainer={(node) => node.parentNode}
          showSearch={{
            filter: (value, paths) =>
              paths.some(
                (option: any) =>
                  option.label.toLowerCase().indexOf(value.toLowerCase()) > -1
              ),
          }}
          onChange={(value: any, selectedOptions: any) => {
            const index: number = selectedOptions.length - 1

            if (index === 1) {
              dispatch(
                submitFeatureLabel({
                  featureLabel: [
                    selectedOptions[0].label,
                    selectedOptions[1].label,
                  ],
                  id: id,
                })
              )
            } else {
              dispatch(
                submitFeatureLabel({
                  featureLabel: [
                    selectedOptions[0].label,
                    selectedOptions[1].label,
                    selectedOptions[2].label,
                  ],
                  id: id,
                })
              )
            }

            dispatch(
              submitSelectedFeature({
                name: selectedOptions[index].label,
                dataType: selectedOptions[index].dataType,
                allowedValues: selectedOptions[index].allowedValues,
                source: selectedOptions[0].label,
              })
            )

            //console.log("selected", selectedOptions)
            if (!datasetName) {
              dispatch(
                selectedDataSource({
                  id: selectedOptions[0].key,
                  selected: {
                    sourceStatus:
                      dataSelected[selectedOptions[0].key].sourceStatus,
                    featureStatus: true,
                    type: dataSelected[selectedOptions[0].key].type,
                  },
                })
              )
            }

            dispatch(
              setRuleFeatureSource({
                id: id,
                type: selectedOptions[0].type,
              })
            )

            let datasetFeatureCheck: boolean = dataSource[0].datasetFeatureCheck
            let providerFeatureCheck: boolean =
              dataSource[1].providerFeatureCheck
            let databaseFeatureCheck: boolean =
              dataSource[2].databaseFeatureCheck
            let customFeatureCheck: boolean = dataSource[3].customFeatureCheck
            let payloadFeatureCheck: boolean = dataSource[4].payloadFeatureCheck

            if (datasetName) {
              datasetFeatureCheck = true
            } else if (
              dataSelected[selectedOptions[0].key].type === "provider"
            ) {
              providerFeatureCheck = true
            } else if (
              dataSelected[selectedOptions[0].key].type === "database"
            ) {
              databaseFeatureCheck = true
            } else if (dataSelected[selectedOptions[0].key].type === "custom") {
              customFeatureCheck = true
            } else if (
              dataSelected[selectedOptions[0].key].type === "payload"
            ) {
              payloadFeatureCheck = true
            }

            dispatch(
              submitDataSource([
                {
                  datasetSourceCheck: dataSource[0].datasetSourceCheck,
                  datasetFeatureCheck: datasetFeatureCheck,
                },
                {
                  providerSourceCheck: dataSource[1].providerSourceCheck,
                  providerFeatureCheck: providerFeatureCheck,
                },
                {
                  databaseSourceCheck: dataSource[2].databaseSourceCheck,
                  databaseFeatureCheck: databaseFeatureCheck,
                },
                {
                  customSourceCheck: dataSource[3].customSourceCheck,
                  customFeatureCheck: customFeatureCheck,
                },
                {
                  payloadSourceCheck: dataSource[4].payloadSourceCheck,
                  payloadFeatureCheck: payloadFeatureCheck,
                },
                dataSource[5],
              ])
            )
          }}
        />
      </Form.Item>

      <Form.Item
        label="Operator"
        name="operator"
        rules={[
          {
            required: required,
            message: "Please select an operator value",
          },
        ]}
      >
        <Select
          className="nodrag"
          placeholder="Select"
          size="small"
          options={selectItemsOperator}
          getPopupContainer={(node) => node.parentNode}
        />
      </Form.Item>

      <Form.Item
        label="Value"
        name="threshold"
        rules={[
          {
            required: required,
            message: "Please insert a value",
          },
        ]}
      >
        <Input
          placeholder="Insert here"
          size="small"
          style={{ width: "100%" }}
        />
      </Form.Item>
    </>
  )
}

export default FeatureSetUp
