// redux
import { createAsyncThunk } from "@reduxjs/toolkit"

//authentication server and types
import { IAuthModel, authServer } from "../../authentication/index"

// Logout a user
export const logout = createAsyncThunk("auth/logout", async () => {
  await authServer.logout();
});

// Refresh Token - used to refresh authenticated user session
export const refreshToken = createAsyncThunk("auth/refreshToken", async () => {
	const response: IAuthModel = await authServer.currentAuthUser()
	return response
})