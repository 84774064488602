export const newSampleCatalog = [
  {
    dataType: "provider",
    data: [
      {
        Id: "uii1",
        type: "provider",
        description: {
          key: "uii1",
          label: "Provider A",
          value: "provA",
          image: null,
          type: "provider",
          description:
            "description of provider A: Cras iaculis purus velit, non iaculis urna condimentum id. Fusce ac risus et leo eleifend dignissim id a massa. Integer vel neque nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec eu metus sit amet turpis molestie hendrerit.",
          children: [
            {
              key: "uuidb1001",
              value: "book1",
              label: "book number 1",
              children: [
                {
                  key: "uuidf1002",
                  value: "feat1",
                  label: "feature 1",
                  dataType: "STRING",
                  allowedValues: "A, B, c, D, E, F",
                },
                {
                  key: "uuidf2003",
                  value: "feat2",
                  label: "feature 2",
                  dataType: "STRING",
                  allowedValues: "A, B, c, D, E, F",
                },
              ],
            },
            {
              key: "uuidb2004",
              value: "book2",
              label: "book number 2",
              children: [
                {
                  key: "uuidf1005",
                  value: "feat1",
                  label: "feature 1",
                  dataType: "STRING",
                  allowedValues: "A, B, c, D, E, F",
                },
                {
                  key: "uuidf2006",
                  value: "feat2",
                  label: "feature 2",
                  dataType: "STRING",
                  allowedValues: "A, B, c, D, E, F",
                },
              ],
            },
          ],
        },
      },
      {
        Id: "uii2",
        type: "provider",
        description: {
          key: "uii2",
          label: "Provider B",
          value: "provB",
          image: null,
          description: "description of provider B",
          type: "provider",
          children: [
            {
              key: "uuidb1007",
              value: "book1",
              label: "book number 1",
              children: [
                {
                  key: "uuidf1009",
                  value: "feat1",
                  label: "feature 1",
                  dataType: "STRING",
                  allowedValues: "A, B, c, D, E, F",
                },
                {
                  key: "uuidf2010",
                  value: "feat2",
                  label: "feature 2",
                  dataType: "STRING",
                  allowedValues: "A, B, c, D, E, F",
                },
              ],
            },
            {
              key: "uuidb2011",
              value: "book2",
              label: "book number 2",
              children: [
                {
                  key: "uuidf1012",
                  value: "feat1",
                  label: "feature 1",
                  dataType: "STRING",
                  allowedValues: "A, B, c, D, E, F",
                },
                {
                  key: "uuidf2013",
                  value: "feat2",
                  label: "feature 2",
                  dataType: "STRING",
                  allowedValues: "A, B, c, D, E, F",
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    dataType: "custom",
    data: [
      {
        Id: "uii3",
        type: "custom",
        description: {
          label: "Group 1",
          value: "customG1",
          image: null,
          key: "uuid3",
          type: "custom",
          description: "description of group of custom features 1",
          children: [
            {
              key: "015",
              value: "feat1",
              label: "feature 1",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
            {
              key: "016",
              value: "feat2",
              label: "feature 2",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
          ],
        },
      },
      {
        Id: "uii4",
        type: "custom",
        description: {
          label: "Group 2",
          value: "customG2",
          image: null,
          key: "uuid4",
          type: "custom",
          description: "description of group of custom features 2",
          children: [
            {
              key: "uuidf10121",
              value: "feat1",
              label: "feature 1",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
            {
              key: "uuidf201022",
              value: "feat2",
              label: "feature 2",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
          ],
        },
      },
      {
        Id: "uii7",
        type: "custom",
        description: {
          label: "Group 3",
          value: "customG3",
          image: null,
          key: "uuid7",
          type: "custom",
          description: "description of group of custom features 3",
          children: [
            {
              key: "uuidf101027",
              value: "feat1",
              label: "feature 1",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
            {
              key: "uuidf201028",
              value: "feat2",
              label: "feature 2",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
          ],
        },
      },
      {
        Id: "uii8",
        type: "custom",
        description: {
          label: "Group 4",
          value: "customG4",
          image: null,
          key: "uuid8",
          description: "description of group of custom features 4",
          type: "custom",
          children: [
            {
              key: "uuidf101033",
              value: "feat1",
              label: "feature 1",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
            {
              key: "uuidf201034",
              value: "feat2",
              label: "feature 2",
              dataType: "STRING",
              allowedValues: "A, B, c, D, E, F",
            },
          ],
        },
      },
    ],
  },
  {
    dataType: "database",
    data: [
      {
        Id: "uii5",
        type: "database",
        description: {
          label: "Database 1",
          value: "database1",
          image: null,
          description: "description of database 1",
          key: "uuid5",
          type: "database",
          children: [
            {
              key: "uuidb1001",
              value: "dc_regiao",
              label: "Região",
              dataType: "STRING",
              allowedValues: "Centro-Oeste, Sudeste, Norte, Sul, Nordeste",
            },
            {
              key: "uuidb1002",
              value: "sexo",
              label: "Sexo",
              dataType: "STRING",
              allowedValues: "M, F",
            },
            {
              key: "uuidb1003",
              value: "fl_aplication",
              label: "Flag Aplication",
              dataType: "NUMBER",
              allowedValues: "0, 1",
            },
            {
              key: "uuidb1004",
              value: "RFM",
              label: "RFM",
              dataType: "STRING",
              allowedValues: "Em Risco, Campeão, ",
            },
            {
              key: "uuidb1005",
              value: "dc_est_civ",
              label: "Estado Civil",
              dataType: "STRING",
              allowedValues: "Solteiro, Casado, Divorciado, Viuvo",
            },
            {
              key: "uuidb1006",
              value: "dc_tp_cli",
              label: "Tipo Cliente",
              dataType: "STRING",
              allowedValues: "Já cliente, Novo",
            },
            {
              key: "uuidb1007",
              value: "nr_score",
              label: "Score",
              dataType: "NUMBER",
              allowedValues: "from 0 to 1000",
            },
            {
              key: "uuidb1008",
              value: "nr_idade",
              label: "Idade",
              dataType: "NUMBER",
              allowedValues: "any number",
            },
            {
              key: "uuidb1009",
              value: "vl_renda",
              label: "Valor Renda",
              dataType: "STRING",
              allowedValues: "number with format 0.000",
            },
            {
              key: "uuidb1010",
              value: "vl_tomado",
              label: "Valor Tomado",
              dataType: "STRING",
              allowedValues: "number with format 0.000",
            },
            {
              key: "uuidb1011",
              value: "fl_restritivo",
              label: "Flag Restrição",
              dataType: "NUMBER",
              allowedValues: "0, 1",
            },
            {
              key: "uuidb1012",
              value: "fl_atraso",
              label: "Flag Atraso",
              dataType: "NUMBER",
              allowedValues: "0, 1",
            },
            {
              key: "uuidb1013",
              value: "fl_app",
              label: "Flag App",
              dataType: "NUMBER",
              allowedValues: "0, 1",
            },
            {
              key: "uuidb1014",
              value: "UF_RG",
              label: "UF Documento (RG)",
              dataType: "NUMBER",
              allowedValues: "integer fom 1 to 9",
            },
            {
              key: "uuidb1015",
              value: "UF_Loja",
              label: "UF Loja",
              dataType: "STRING",
              allowedValues: "Brazillian UF",
            },
          ],
        },
      },
      {
        Id: "uii6",
        type: "database",
        description: {
          label: "Database 2",
          value: "database2",
          image: null,
          description: "description of database 2",
          children: [
            {
              key: "uuidf101040",
              value: "feat1",
              label: "feature 1",
              dataType: "STRING",
              allowedValues: "any",
            },
            {
              key: "uuidf201041",
              value: "feat2",
              label: "feature 2",
              dataType: "STRING",
              allowedValues: "any",
            },
          ],
        },
      },
      {
        Id: "uii9",
        type: "database",
        description: {
          label: "Database 3",
          value: "database3",
          image: null,
          description: "description of database 3",
          children: [
            {
              key: "uuidf101042",
              value: "feat1",
              label: "feature 1",
              dataType: "STRING",
              allowedValues: "any",
            },
            {
              key: "uuidf201043",
              value: "feat2",
              label: "feature 2",
              dataType: "STRING",
              allowedValues: "any",
            },
          ],
        },
      },
    ],
  },
]

export const sampleCatalog = [
  {
    value: "provider_0",
    label: "provider_0",
    children: [
      {
        value:
          "book_long_name_test_with_very_large_xxxxxxxxxxxxxxxxxxxxxxxx_0_0",
        label:
          "book_long_name_test_with_very_large_xxxxxxxxxxxxxxxxxxxxxxxx_0_0",
        children: [
          {
            value: "feature_0_0",
            label: "feature_0_0",
          },
          {
            value: "feature_0_1",
            label: "feature_0_1",
          },
          {
            value: "feature_0_2",
            label: "feature_0_2",
          },
          {
            value: "feature_0_3",
            label: "feature_0_3",
          },
          {
            value: "feature_0_4",
            label: "feature_0_4",
          },
          {
            value: "feature_0_5",
            label: "feature_0_5",
          },
          {
            value: "feature_0_6",
            label: "feature_0_6",
          },
          {
            value: "feature_0_7",
            label: "feature_0_7",
          },
          {
            value: "feature_0_8",
            label: "feature_0_8",
          },
          {
            value: "feature_0_9",
            label: "feature_0_9",
          },
        ],
      },
      {
        value:
          "book_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX_0_1",
        label:
          "book_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX_0_1",
        children: [
          {
            value: "feature_1_0",
            label: "feature_1_0",
          },
          {
            value: "feature_1_1",
            label: "feature_1_1",
          },
          {
            value: "feature_1_2",
            label: "feature_1_2",
          },
          {
            value: "feature_1_3",
            label: "feature_1_3",
          },
          {
            value: "feature_1_4",
            label: "feature_1_4",
          },
          {
            value: "feature_1_5",
            label: "feature_1_5",
          },
          {
            value: "feature_1_6",
            label: "feature_1_6",
          },
          {
            value: "feature_1_7",
            label: "feature_1_7",
          },
          {
            value: "feature_1_8",
            label: "feature_1_8",
          },
          {
            value: "feature_1_9",
            label: "feature_1_9",
          },
        ],
      },
      {
        value: "book_0_2",
        label: "book_0_2",
        children: [
          {
            value: "feature_2_0",
            label: "feature_2_0",
          },
          {
            value: "feature_2_1",
            label: "feature_2_1",
          },
          {
            value: "feature_2_2",
            label: "feature_2_2",
          },
          {
            value: "feature_2_3",
            label: "feature_2_3",
          },
          {
            value: "feature_2_4",
            label: "feature_2_4",
          },
          {
            value: "feature_2_5",
            label: "feature_2_5",
          },
          {
            value: "feature_2_6",
            label: "feature_2_6",
          },
          {
            value: "feature_2_7",
            label: "feature_2_7",
          },
          {
            value: "feature_2_8",
            label: "feature_2_8",
          },
          {
            value: "feature_2_9",
            label: "feature_2_9",
          },
        ],
      },
      {
        value: "book_0_3",
        label: "book_0_3",
        children: [
          {
            value: "feature_3_0",
            label: "feature_3_0",
          },
          {
            value: "feature_3_1",
            label: "feature_3_1",
          },
          {
            value: "feature_3_2",
            label: "feature_3_2",
          },
          {
            value: "feature_3_3",
            label: "feature_3_3",
          },
          {
            value: "feature_3_4",
            label: "feature_3_4",
          },
          {
            value: "feature_3_5",
            label: "feature_3_5",
          },
          {
            value: "feature_3_6",
            label: "feature_3_6",
          },
          {
            value: "feature_3_7",
            label: "feature_3_7",
          },
          {
            value: "feature_3_8",
            label: "feature_3_8",
          },
          {
            value: "feature_3_9",
            label: "feature_3_9",
          },
        ],
      },
      {
        value: "book_0_4",
        label: "book_0_4",
        children: [
          {
            value: "feature_4_0",
            label: "feature_4_0",
          },
          {
            value: "feature_4_1",
            label: "feature_4_1",
          },
          {
            value: "feature_4_2",
            label: "feature_4_2",
          },
          {
            value: "feature_4_3",
            label: "feature_4_3",
          },
          {
            value: "feature_4_4",
            label: "feature_4_4",
          },
          {
            value: "feature_4_5",
            label: "feature_4_5",
          },
          {
            value: "feature_4_6",
            label: "feature_4_6",
          },
          {
            value: "feature_4_7",
            label: "feature_4_7",
          },
          {
            value: "feature_4_8",
            label: "feature_4_8",
          },
          {
            value: "feature_4_9",
            label: "feature_4_9",
          },
        ],
      },
      {
        value: "book_0_5",
        label: "book_0_5",
        children: [
          {
            value: "feature_5_0",
            label: "feature_5_0",
          },
          {
            value: "feature_5_1",
            label: "feature_5_1",
          },
          {
            value: "feature_5_2",
            label: "feature_5_2",
          },
          {
            value: "feature_5_3",
            label: "feature_5_3",
          },
          {
            value: "feature_5_4",
            label: "feature_5_4",
          },
          {
            value: "feature_5_5",
            label: "feature_5_5",
          },
          {
            value: "feature_5_6",
            label: "feature_5_6",
          },
          {
            value: "feature_5_7",
            label: "feature_5_7",
          },
          {
            value: "feature_5_8",
            label: "feature_5_8",
          },
          {
            value: "feature_5_9",
            label: "feature_5_9",
          },
        ],
      },
      {
        value: "book_0_6",
        label: "book_0_6",
        children: [
          {
            value: "feature_6_0",
            label: "feature_6_0",
          },
          {
            value: "feature_6_1",
            label: "feature_6_1",
          },
          {
            value: "feature_6_2",
            label: "feature_6_2",
          },
          {
            value: "feature_6_3",
            label: "feature_6_3",
          },
          {
            value: "feature_6_4",
            label: "feature_6_4",
          },
          {
            value: "feature_6_5",
            label: "feature_6_5",
          },
          {
            value: "feature_6_6",
            label: "feature_6_6",
          },
          {
            value: "feature_6_7",
            label: "feature_6_7",
          },
          {
            value: "feature_6_8",
            label: "feature_6_8",
          },
          {
            value: "feature_6_9",
            label: "feature_6_9",
          },
        ],
      },
      {
        value: "book_0_7",
        label: "book_0_7",
        children: [
          {
            value: "feature_7_0",
            label: "feature_7_0",
          },
          {
            value: "feature_7_1",
            label: "feature_7_1",
          },
          {
            value: "feature_7_2",
            label: "feature_7_2",
          },
          {
            value: "feature_7_3",
            label: "feature_7_3",
          },
          {
            value: "feature_7_4",
            label: "feature_7_4",
          },
          {
            value: "feature_7_5",
            label: "feature_7_5",
          },
          {
            value: "feature_7_6",
            label: "feature_7_6",
          },
          {
            value: "feature_7_7",
            label: "feature_7_7",
          },
          {
            value: "feature_7_8",
            label: "feature_7_8",
          },
          {
            value: "feature_7_9",
            label: "feature_7_9",
          },
        ],
      },
      {
        value: "book_0_8",
        label: "book_0_8",
        children: [
          {
            value: "feature_8_0",
            label: "feature_8_0",
          },
          {
            value: "feature_8_1",
            label: "feature_8_1",
          },
          {
            value: "feature_8_2",
            label: "feature_8_2",
          },
          {
            value: "feature_8_3",
            label: "feature_8_3",
          },
          {
            value: "feature_8_4",
            label: "feature_8_4",
          },
          {
            value: "feature_8_5",
            label: "feature_8_5",
          },
          {
            value: "feature_8_6",
            label: "feature_8_6",
          },
          {
            value: "feature_8_7",
            label: "feature_8_7",
          },
          {
            value: "feature_8_8",
            label: "feature_8_8",
          },
          {
            value: "feature_8_9",
            label: "feature_8_9",
          },
        ],
      },
      {
        value: "book_0_9",
        label: "book_0_9",
        children: [
          {
            value: "feature_9_0",
            label: "feature_9_0",
          },
          {
            value: "feature_9_1",
            label: "feature_9_1",
          },
          {
            value: "feature_9_2",
            label: "feature_9_2",
          },
          {
            value: "feature_9_3",
            label: "feature_9_3",
          },
          {
            value: "feature_9_4",
            label: "feature_9_4",
          },
          {
            value: "feature_9_5",
            label: "feature_9_5",
          },
          {
            value: "feature_9_6",
            label: "feature_9_6",
          },
          {
            value: "feature_9_7",
            label: "feature_9_7",
          },
          {
            value: "feature_9_8",
            label: "feature_9_8",
          },
          {
            value: "feature_9_9",
            label: "feature_9_9",
          },
        ],
      },
    ],
  },
  {
    value: "provider_1",
    label: "provider_1",
    children: [
      {
        value: "book_1_0",
        label: "book_1_0",
        children: [
          {
            value: "feature_0_0",
            label: "feature_0_0",
          },
          {
            value: "feature_0_1",
            label: "feature_0_1",
          },
          {
            value: "feature_0_2",
            label: "feature_0_2",
          },
          {
            value: "feature_0_3",
            label: "feature_0_3",
          },
          {
            value: "feature_0_4",
            label: "feature_0_4",
          },
          {
            value: "feature_0_5",
            label: "feature_0_5",
          },
          {
            value: "feature_0_6",
            label: "feature_0_6",
          },
          {
            value: "feature_0_7",
            label: "feature_0_7",
          },
          {
            value: "feature_0_8",
            label: "feature_0_8",
          },
          {
            value: "feature_0_9",
            label: "feature_0_9",
          },
        ],
      },
      {
        value: "book_1_1",
        label: "book_1_1",
        children: [
          {
            value: "feature_1_0",
            label: "feature_1_0",
          },
          {
            value: "feature_1_1",
            label: "feature_1_1",
          },
          {
            value: "feature_1_2",
            label: "feature_1_2",
          },
          {
            value: "feature_1_3",
            label: "feature_1_3",
          },
          {
            value: "feature_1_4",
            label: "feature_1_4",
          },
          {
            value: "feature_1_5",
            label: "feature_1_5",
          },
          {
            value: "feature_1_6",
            label: "feature_1_6",
          },
          {
            value: "feature_1_7",
            label: "feature_1_7",
          },
          {
            value: "feature_1_8",
            label: "feature_1_8",
          },
          {
            value: "feature_1_9",
            label: "feature_1_9",
          },
        ],
      },
      {
        value: "book_1_2",
        label: "book_1_2",
        children: [
          {
            value: "feature_2_0",
            label: "feature_2_0",
          },
          {
            value: "feature_2_1",
            label: "feature_2_1",
          },
          {
            value: "feature_2_2",
            label: "feature_2_2",
          },
          {
            value: "feature_2_3",
            label: "feature_2_3",
          },
          {
            value: "feature_2_4",
            label: "feature_2_4",
          },
          {
            value: "feature_2_5",
            label: "feature_2_5",
          },
          {
            value: "feature_2_6",
            label: "feature_2_6",
          },
          {
            value: "feature_2_7",
            label: "feature_2_7",
          },
          {
            value: "feature_2_8",
            label: "feature_2_8",
          },
          {
            value: "feature_2_9",
            label: "feature_2_9",
          },
        ],
      },
      {
        value: "book_1_3",
        label: "book_1_3",
        children: [
          {
            value: "feature_3_0",
            label: "feature_3_0",
          },
          {
            value: "feature_3_1",
            label: "feature_3_1",
          },
          {
            value: "feature_3_2",
            label: "feature_3_2",
          },
          {
            value: "feature_3_3",
            label: "feature_3_3",
          },
          {
            value: "feature_3_4",
            label: "feature_3_4",
          },
          {
            value: "feature_3_5",
            label: "feature_3_5",
          },
          {
            value: "feature_3_6",
            label: "feature_3_6",
          },
          {
            value: "feature_3_7",
            label: "feature_3_7",
          },
          {
            value: "feature_3_8",
            label: "feature_3_8",
          },
          {
            value: "feature_3_9",
            label: "feature_3_9",
          },
        ],
      },
      {
        value: "book_1_4",
        label: "book_1_4",
        children: [
          {
            value: "feature_4_0",
            label: "feature_4_0",
          },
          {
            value: "feature_4_1",
            label: "feature_4_1",
          },
          {
            value: "feature_4_2",
            label: "feature_4_2",
          },
          {
            value: "feature_4_3",
            label: "feature_4_3",
          },
          {
            value: "feature_4_4",
            label: "feature_4_4",
          },
          {
            value: "feature_4_5",
            label: "feature_4_5",
          },
          {
            value: "feature_4_6",
            label: "feature_4_6",
          },
          {
            value: "feature_4_7",
            label: "feature_4_7",
          },
          {
            value: "feature_4_8",
            label: "feature_4_8",
          },
          {
            value: "feature_4_9",
            label: "feature_4_9",
          },
        ],
      },
      {
        value: "book_1_5",
        label: "book_1_5",
        children: [
          {
            value: "feature_5_0",
            label: "feature_5_0",
          },
          {
            value: "feature_5_1",
            label: "feature_5_1",
          },
          {
            value: "feature_5_2",
            label: "feature_5_2",
          },
          {
            value: "feature_5_3",
            label: "feature_5_3",
          },
          {
            value: "feature_5_4",
            label: "feature_5_4",
          },
          {
            value: "feature_5_5",
            label: "feature_5_5",
          },
          {
            value: "feature_5_6",
            label: "feature_5_6",
          },
          {
            value: "feature_5_7",
            label: "feature_5_7",
          },
          {
            value: "feature_5_8",
            label: "feature_5_8",
          },
          {
            value: "feature_5_9",
            label: "feature_5_9",
          },
        ],
      },
      {
        value: "book_1_6",
        label: "book_1_6",
        children: [
          {
            value: "feature_6_0",
            label: "feature_6_0",
          },
          {
            value: "feature_6_1",
            label: "feature_6_1",
          },
          {
            value: "feature_6_2",
            label: "feature_6_2",
          },
          {
            value: "feature_6_3",
            label: "feature_6_3",
          },
          {
            value: "feature_6_4",
            label: "feature_6_4",
          },
          {
            value: "feature_6_5",
            label: "feature_6_5",
          },
          {
            value: "feature_6_6",
            label: "feature_6_6",
          },
          {
            value: "feature_6_7",
            label: "feature_6_7",
          },
          {
            value: "feature_6_8",
            label: "feature_6_8",
          },
          {
            value: "feature_6_9",
            label: "feature_6_9",
          },
        ],
      },
      {
        value: "book_1_7",
        label: "book_1_7",
        children: [
          {
            value: "feature_7_0",
            label: "feature_7_0",
          },
          {
            value: "feature_7_1",
            label: "feature_7_1",
          },
          {
            value: "feature_7_2",
            label: "feature_7_2",
          },
          {
            value: "feature_7_3",
            label: "feature_7_3",
          },
          {
            value: "feature_7_4",
            label: "feature_7_4",
          },
          {
            value: "feature_7_5",
            label: "feature_7_5",
          },
          {
            value: "feature_7_6",
            label: "feature_7_6",
          },
          {
            value: "feature_7_7",
            label: "feature_7_7",
          },
          {
            value: "feature_7_8",
            label: "feature_7_8",
          },
          {
            value: "feature_7_9",
            label: "feature_7_9",
          },
        ],
      },
      {
        value: "book_1_8",
        label: "book_1_8",
        children: [
          {
            value: "feature_8_0",
            label: "feature_8_0",
          },
          {
            value: "feature_8_1",
            label: "feature_8_1",
          },
          {
            value: "feature_8_2",
            label: "feature_8_2",
          },
          {
            value: "feature_8_3",
            label: "feature_8_3",
          },
          {
            value: "feature_8_4",
            label: "feature_8_4",
          },
          {
            value: "feature_8_5",
            label: "feature_8_5",
          },
          {
            value: "feature_8_6",
            label: "feature_8_6",
          },
          {
            value: "feature_8_7",
            label: "feature_8_7",
          },
          {
            value: "feature_8_8",
            label: "feature_8_8",
          },
          {
            value: "feature_8_9",
            label: "feature_8_9",
          },
        ],
      },
      {
        value: "book_1_9",
        label: "book_1_9",
        children: [
          {
            value: "feature_9_0",
            label: "feature_9_0",
          },
          {
            value: "feature_9_1",
            label: "feature_9_1",
          },
          {
            value: "feature_9_2",
            label: "feature_9_2",
          },
          {
            value: "feature_9_3",
            label: "feature_9_3",
          },
          {
            value: "feature_9_4",
            label: "feature_9_4",
          },
          {
            value: "feature_9_5",
            label: "feature_9_5",
          },
          {
            value: "feature_9_6",
            label: "feature_9_6",
          },
          {
            value: "feature_9_7",
            label: "feature_9_7",
          },
          {
            value: "feature_9_8",
            label: "feature_9_8",
          },
          {
            value: "feature_9_9",
            label: "feature_9_9",
          },
        ],
      },
    ],
  },
  {
    value: "provider_2",
    label: "provider_2",
    children: [
      {
        value: "book_2_0",
        label: "book_2_0",
        children: [
          {
            value: "feature_0_0",
            label: "feature_0_0",
          },
          {
            value: "feature_0_1",
            label: "feature_0_1",
          },
          {
            value: "feature_0_2",
            label: "feature_0_2",
          },
          {
            value: "feature_0_3",
            label: "feature_0_3",
          },
          {
            value: "feature_0_4",
            label: "feature_0_4",
          },
          {
            value: "feature_0_5",
            label: "feature_0_5",
          },
          {
            value: "feature_0_6",
            label: "feature_0_6",
          },
          {
            value: "feature_0_7",
            label: "feature_0_7",
          },
          {
            value: "feature_0_8",
            label: "feature_0_8",
          },
          {
            value: "feature_0_9",
            label: "feature_0_9",
          },
        ],
      },
      {
        value: "book_2_1",
        label: "book_2_1",
        children: [
          {
            value: "feature_1_0",
            label: "feature_1_0",
          },
          {
            value: "feature_1_1",
            label: "feature_1_1",
          },
          {
            value: "feature_1_2",
            label: "feature_1_2",
          },
          {
            value: "feature_1_3",
            label: "feature_1_3",
          },
          {
            value: "feature_1_4",
            label: "feature_1_4",
          },
          {
            value: "feature_1_5",
            label: "feature_1_5",
          },
          {
            value: "feature_1_6",
            label: "feature_1_6",
          },
          {
            value: "feature_1_7",
            label: "feature_1_7",
          },
          {
            value: "feature_1_8",
            label: "feature_1_8",
          },
          {
            value: "feature_1_9",
            label: "feature_1_9",
          },
        ],
      },
      {
        value: "book_2_2",
        label: "book_2_2",
        children: [
          {
            value: "feature_2_0",
            label: "feature_2_0",
          },
          {
            value: "feature_2_1",
            label: "feature_2_1",
          },
          {
            value: "feature_2_2",
            label: "feature_2_2",
          },
          {
            value: "feature_2_3",
            label: "feature_2_3",
          },
          {
            value: "feature_2_4",
            label: "feature_2_4",
          },
          {
            value: "feature_2_5",
            label: "feature_2_5",
          },
          {
            value: "feature_2_6",
            label: "feature_2_6",
          },
          {
            value: "feature_2_7",
            label: "feature_2_7",
          },
          {
            value: "feature_2_8",
            label: "feature_2_8",
          },
          {
            value: "feature_2_9",
            label: "feature_2_9",
          },
        ],
      },
      {
        value: "book_2_3",
        label: "book_2_3",
        children: [
          {
            value: "feature_3_0",
            label: "feature_3_0",
          },
          {
            value: "feature_3_1",
            label: "feature_3_1",
          },
          {
            value: "feature_3_2",
            label: "feature_3_2",
          },
          {
            value: "feature_3_3",
            label: "feature_3_3",
          },
          {
            value: "feature_3_4",
            label: "feature_3_4",
          },
          {
            value: "feature_3_5",
            label: "feature_3_5",
          },
          {
            value: "feature_3_6",
            label: "feature_3_6",
          },
          {
            value: "feature_3_7",
            label: "feature_3_7",
          },
          {
            value: "feature_3_8",
            label: "feature_3_8",
          },
          {
            value: "feature_3_9",
            label: "feature_3_9",
          },
        ],
      },
      {
        value: "book_2_4",
        label: "book_2_4",
        children: [
          {
            value: "feature_4_0",
            label: "feature_4_0",
          },
          {
            value: "feature_4_1",
            label: "feature_4_1",
          },
          {
            value: "feature_4_2",
            label: "feature_4_2",
          },
          {
            value: "feature_4_3",
            label: "feature_4_3",
          },
          {
            value: "feature_4_4",
            label: "feature_4_4",
          },
          {
            value: "feature_4_5",
            label: "feature_4_5",
          },
          {
            value: "feature_4_6",
            label: "feature_4_6",
          },
          {
            value: "feature_4_7",
            label: "feature_4_7",
          },
          {
            value: "feature_4_8",
            label: "feature_4_8",
          },
          {
            value: "feature_4_9",
            label: "feature_4_9",
          },
        ],
      },
      {
        value: "book_2_5",
        label: "book_2_5",
        children: [
          {
            value: "feature_5_0",
            label: "feature_5_0",
          },
          {
            value: "feature_5_1",
            label: "feature_5_1",
          },
          {
            value: "feature_5_2",
            label: "feature_5_2",
          },
          {
            value: "feature_5_3",
            label: "feature_5_3",
          },
          {
            value: "feature_5_4",
            label: "feature_5_4",
          },
          {
            value: "feature_5_5",
            label: "feature_5_5",
          },
          {
            value: "feature_5_6",
            label: "feature_5_6",
          },
          {
            value: "feature_5_7",
            label: "feature_5_7",
          },
          {
            value: "feature_5_8",
            label: "feature_5_8",
          },
          {
            value: "feature_5_9",
            label: "feature_5_9",
          },
        ],
      },
      {
        value: "book_2_6",
        label: "book_2_6",
        children: [
          {
            value: "feature_6_0",
            label: "feature_6_0",
          },
          {
            value: "feature_6_1",
            label: "feature_6_1",
          },
          {
            value: "feature_6_2",
            label: "feature_6_2",
          },
          {
            value: "feature_6_3",
            label: "feature_6_3",
          },
          {
            value: "feature_6_4",
            label: "feature_6_4",
          },
          {
            value: "feature_6_5",
            label: "feature_6_5",
          },
          {
            value: "feature_6_6",
            label: "feature_6_6",
          },
          {
            value: "feature_6_7",
            label: "feature_6_7",
          },
          {
            value: "feature_6_8",
            label: "feature_6_8",
          },
          {
            value: "feature_6_9",
            label: "feature_6_9",
          },
        ],
      },
      {
        value: "book_2_7",
        label: "book_2_7",
        children: [
          {
            value: "feature_7_0",
            label: "feature_7_0",
          },
          {
            value: "feature_7_1",
            label: "feature_7_1",
          },
          {
            value: "feature_7_2",
            label: "feature_7_2",
          },
          {
            value: "feature_7_3",
            label: "feature_7_3",
          },
          {
            value: "feature_7_4",
            label: "feature_7_4",
          },
          {
            value: "feature_7_5",
            label: "feature_7_5",
          },
          {
            value: "feature_7_6",
            label: "feature_7_6",
          },
          {
            value: "feature_7_7",
            label: "feature_7_7",
          },
          {
            value: "feature_7_8",
            label: "feature_7_8",
          },
          {
            value: "feature_7_9",
            label: "feature_7_9",
          },
        ],
      },
      {
        value: "book_2_8",
        label: "book_2_8",
        children: [
          {
            value: "feature_8_0",
            label: "feature_8_0",
          },
          {
            value: "feature_8_1",
            label: "feature_8_1",
          },
          {
            value: "feature_8_2",
            label: "feature_8_2",
          },
          {
            value: "feature_8_3",
            label: "feature_8_3",
          },
          {
            value: "feature_8_4",
            label: "feature_8_4",
          },
          {
            value: "feature_8_5",
            label: "feature_8_5",
          },
          {
            value: "feature_8_6",
            label: "feature_8_6",
          },
          {
            value: "feature_8_7",
            label: "feature_8_7",
          },
          {
            value: "feature_8_8",
            label: "feature_8_8",
          },
          {
            value: "feature_8_9",
            label: "feature_8_9",
          },
        ],
      },
      {
        value: "book_2_9",
        label: "book_2_9",
        children: [
          {
            value: "feature_9_0",
            label: "feature_9_0",
          },
          {
            value: "feature_9_1",
            label: "feature_9_1",
          },
          {
            value: "feature_9_2",
            label: "feature_9_2",
          },
          {
            value: "feature_9_3",
            label: "feature_9_3",
          },
          {
            value: "feature_9_4",
            label: "feature_9_4",
          },
          {
            value: "feature_9_5",
            label: "feature_9_5",
          },
          {
            value: "feature_9_6",
            label: "feature_9_6",
          },
          {
            value: "feature_9_7",
            label: "feature_9_7",
          },
          {
            value: "feature_9_8",
            label: "feature_9_8",
          },
          {
            value: "feature_9_9",
            label: "feature_9_9",
          },
        ],
      },
    ],
  },
  {
    value: "provider_3",
    label: "provider_3",
    children: [
      {
        value: "book_3_0",
        label: "book_3_0",
        children: [
          {
            value: "feature_0_0",
            label: "feature_0_0",
          },
          {
            value: "feature_0_1",
            label: "feature_0_1",
          },
          {
            value: "feature_0_2",
            label: "feature_0_2",
          },
          {
            value: "feature_0_3",
            label: "feature_0_3",
          },
          {
            value: "feature_0_4",
            label: "feature_0_4",
          },
          {
            value: "feature_0_5",
            label: "feature_0_5",
          },
          {
            value: "feature_0_6",
            label: "feature_0_6",
          },
          {
            value: "feature_0_7",
            label: "feature_0_7",
          },
          {
            value: "feature_0_8",
            label: "feature_0_8",
          },
          {
            value: "feature_0_9",
            label: "feature_0_9",
          },
        ],
      },
      {
        value: "book_3_1",
        label: "book_3_1",
        children: [
          {
            value: "feature_1_0",
            label: "feature_1_0",
          },
          {
            value: "feature_1_1",
            label: "feature_1_1",
          },
          {
            value: "feature_1_2",
            label: "feature_1_2",
          },
          {
            value: "feature_1_3",
            label: "feature_1_3",
          },
          {
            value: "feature_1_4",
            label: "feature_1_4",
          },
          {
            value: "feature_1_5",
            label: "feature_1_5",
          },
          {
            value: "feature_1_6",
            label: "feature_1_6",
          },
          {
            value: "feature_1_7",
            label: "feature_1_7",
          },
          {
            value: "feature_1_8",
            label: "feature_1_8",
          },
          {
            value: "feature_1_9",
            label: "feature_1_9",
          },
        ],
      },
      {
        value: "book_3_2",
        label: "book_3_2",
        children: [
          {
            value: "feature_2_0",
            label: "feature_2_0",
          },
          {
            value: "feature_2_1",
            label: "feature_2_1",
          },
          {
            value: "feature_2_2",
            label: "feature_2_2",
          },
          {
            value: "feature_2_3",
            label: "feature_2_3",
          },
          {
            value: "feature_2_4",
            label: "feature_2_4",
          },
          {
            value: "feature_2_5",
            label: "feature_2_5",
          },
          {
            value: "feature_2_6",
            label: "feature_2_6",
          },
          {
            value: "feature_2_7",
            label: "feature_2_7",
          },
          {
            value: "feature_2_8",
            label: "feature_2_8",
          },
          {
            value: "feature_2_9",
            label: "feature_2_9",
          },
        ],
      },
      {
        value: "book_3_3",
        label: "book_3_3",
        children: [
          {
            value: "feature_3_0",
            label: "feature_3_0",
          },
          {
            value: "feature_3_1",
            label: "feature_3_1",
          },
          {
            value: "feature_3_2",
            label: "feature_3_2",
          },
          {
            value: "feature_3_3",
            label: "feature_3_3",
          },
          {
            value: "feature_3_4",
            label: "feature_3_4",
          },
          {
            value: "feature_3_5",
            label: "feature_3_5",
          },
          {
            value: "feature_3_6",
            label: "feature_3_6",
          },
          {
            value: "feature_3_7",
            label: "feature_3_7",
          },
          {
            value: "feature_3_8",
            label: "feature_3_8",
          },
          {
            value: "feature_3_9",
            label: "feature_3_9",
          },
        ],
      },
      {
        value: "book_3_4",
        label: "book_3_4",
        children: [
          {
            value: "feature_4_0",
            label: "feature_4_0",
          },
          {
            value: "feature_4_1",
            label: "feature_4_1",
          },
          {
            value: "feature_4_2",
            label: "feature_4_2",
          },
          {
            value: "feature_4_3",
            label: "feature_4_3",
          },
          {
            value: "feature_4_4",
            label: "feature_4_4",
          },
          {
            value: "feature_4_5",
            label: "feature_4_5",
          },
          {
            value: "feature_4_6",
            label: "feature_4_6",
          },
          {
            value: "feature_4_7",
            label: "feature_4_7",
          },
          {
            value: "feature_4_8",
            label: "feature_4_8",
          },
          {
            value: "feature_4_9",
            label: "feature_4_9",
          },
        ],
      },
      {
        value: "book_3_5",
        label: "book_3_5",
        children: [
          {
            value: "feature_5_0",
            label: "feature_5_0",
          },
          {
            value: "feature_5_1",
            label: "feature_5_1",
          },
          {
            value: "feature_5_2",
            label: "feature_5_2",
          },
          {
            value: "feature_5_3",
            label: "feature_5_3",
          },
          {
            value: "feature_5_4",
            label: "feature_5_4",
          },
          {
            value: "feature_5_5",
            label: "feature_5_5",
          },
          {
            value: "feature_5_6",
            label: "feature_5_6",
          },
          {
            value: "feature_5_7",
            label: "feature_5_7",
          },
          {
            value: "feature_5_8",
            label: "feature_5_8",
          },
          {
            value: "feature_5_9",
            label: "feature_5_9",
          },
        ],
      },
      {
        value: "book_3_6",
        label: "book_3_6",
        children: [
          {
            value: "feature_6_0",
            label: "feature_6_0",
          },
          {
            value: "feature_6_1",
            label: "feature_6_1",
          },
          {
            value: "feature_6_2",
            label: "feature_6_2",
          },
          {
            value: "feature_6_3",
            label: "feature_6_3",
          },
          {
            value: "feature_6_4",
            label: "feature_6_4",
          },
          {
            value: "feature_6_5",
            label: "feature_6_5",
          },
          {
            value: "feature_6_6",
            label: "feature_6_6",
          },
          {
            value: "feature_6_7",
            label: "feature_6_7",
          },
          {
            value: "feature_6_8",
            label: "feature_6_8",
          },
          {
            value: "feature_6_9",
            label: "feature_6_9",
          },
        ],
      },
      {
        value: "book_3_7",
        label: "book_3_7",
        children: [
          {
            value: "feature_7_0",
            label: "feature_7_0",
          },
          {
            value: "feature_7_1",
            label: "feature_7_1",
          },
          {
            value: "feature_7_2",
            label: "feature_7_2",
          },
          {
            value: "feature_7_3",
            label: "feature_7_3",
          },
          {
            value: "feature_7_4",
            label: "feature_7_4",
          },
          {
            value: "feature_7_5",
            label: "feature_7_5",
          },
          {
            value: "feature_7_6",
            label: "feature_7_6",
          },
          {
            value: "feature_7_7",
            label: "feature_7_7",
          },
          {
            value: "feature_7_8",
            label: "feature_7_8",
          },
          {
            value: "feature_7_9",
            label: "feature_7_9",
          },
        ],
      },
      {
        value: "book_3_8",
        label: "book_3_8",
        children: [
          {
            value: "feature_8_0",
            label: "feature_8_0",
          },
          {
            value: "feature_8_1",
            label: "feature_8_1",
          },
          {
            value: "feature_8_2",
            label: "feature_8_2",
          },
          {
            value: "feature_8_3",
            label: "feature_8_3",
          },
          {
            value: "feature_8_4",
            label: "feature_8_4",
          },
          {
            value: "feature_8_5",
            label: "feature_8_5",
          },
          {
            value: "feature_8_6",
            label: "feature_8_6",
          },
          {
            value: "feature_8_7",
            label: "feature_8_7",
          },
          {
            value: "feature_8_8",
            label: "feature_8_8",
          },
          {
            value: "feature_8_9",
            label: "feature_8_9",
          },
        ],
      },
      {
        value: "book_3_9",
        label: "book_3_9",
        children: [
          {
            value: "feature_9_0",
            label: "feature_9_0",
          },
          {
            value: "feature_9_1",
            label: "feature_9_1",
          },
          {
            value: "feature_9_2",
            label: "feature_9_2",
          },
          {
            value: "feature_9_3",
            label: "feature_9_3",
          },
          {
            value: "feature_9_4",
            label: "feature_9_4",
          },
          {
            value: "feature_9_5",
            label: "feature_9_5",
          },
          {
            value: "feature_9_6",
            label: "feature_9_6",
          },
          {
            value: "feature_9_7",
            label: "feature_9_7",
          },
          {
            value: "feature_9_8",
            label: "feature_9_8",
          },
          {
            value: "feature_9_9",
            label: "feature_9_9",
          },
        ],
      },
    ],
  },
  {
    value: "provider_4",
    label: "provider_4",
    children: [
      {
        value: "book_4_0",
        label: "book_4_0",
        children: [
          {
            value: "feature_0_0",
            label: "feature_0_0",
          },
          {
            value: "feature_0_1",
            label: "feature_0_1",
          },
          {
            value: "feature_0_2",
            label: "feature_0_2",
          },
          {
            value: "feature_0_3",
            label: "feature_0_3",
          },
          {
            value: "feature_0_4",
            label: "feature_0_4",
          },
          {
            value: "feature_0_5",
            label: "feature_0_5",
          },
          {
            value: "feature_0_6",
            label: "feature_0_6",
          },
          {
            value: "feature_0_7",
            label: "feature_0_7",
          },
          {
            value: "feature_0_8",
            label: "feature_0_8",
          },
          {
            value: "feature_0_9",
            label: "feature_0_9",
          },
        ],
      },
      {
        value: "book_4_1",
        label: "book_4_1",
        children: [
          {
            value: "feature_1_0",
            label: "feature_1_0",
          },
          {
            value: "feature_1_1",
            label: "feature_1_1",
          },
          {
            value: "feature_1_2",
            label: "feature_1_2",
          },
          {
            value: "feature_1_3",
            label: "feature_1_3",
          },
          {
            value: "feature_1_4",
            label: "feature_1_4",
          },
          {
            value: "feature_1_5",
            label: "feature_1_5",
          },
          {
            value: "feature_1_6",
            label: "feature_1_6",
          },
          {
            value: "feature_1_7",
            label: "feature_1_7",
          },
          {
            value: "feature_1_8",
            label: "feature_1_8",
          },
          {
            value: "feature_1_9",
            label: "feature_1_9",
          },
        ],
      },
      {
        value: "book_4_2",
        label: "book_4_2",
        children: [
          {
            value: "feature_2_0",
            label: "feature_2_0",
          },
          {
            value: "feature_2_1",
            label: "feature_2_1",
          },
          {
            value: "feature_2_2",
            label: "feature_2_2",
          },
          {
            value: "feature_2_3",
            label: "feature_2_3",
          },
          {
            value: "feature_2_4",
            label: "feature_2_4",
          },
          {
            value: "feature_2_5",
            label: "feature_2_5",
          },
          {
            value: "feature_2_6",
            label: "feature_2_6",
          },
          {
            value: "feature_2_7",
            label: "feature_2_7",
          },
          {
            value: "feature_2_8",
            label: "feature_2_8",
          },
          {
            value: "feature_2_9",
            label: "feature_2_9",
          },
        ],
      },
      {
        value: "book_4_3",
        label: "book_4_3",
        children: [
          {
            value: "feature_3_0",
            label: "feature_3_0",
          },
          {
            value: "feature_3_1",
            label: "feature_3_1",
          },
          {
            value: "feature_3_2",
            label: "feature_3_2",
          },
          {
            value: "feature_3_3",
            label: "feature_3_3",
          },
          {
            value: "feature_3_4",
            label: "feature_3_4",
          },
          {
            value: "feature_3_5",
            label: "feature_3_5",
          },
          {
            value: "feature_3_6",
            label: "feature_3_6",
          },
          {
            value: "feature_3_7",
            label: "feature_3_7",
          },
          {
            value: "feature_3_8",
            label: "feature_3_8",
          },
          {
            value: "feature_3_9",
            label: "feature_3_9",
          },
        ],
      },
      {
        value: "book_4_4",
        label: "book_4_4",
        children: [
          {
            value: "feature_4_0",
            label: "feature_4_0",
          },
          {
            value: "feature_4_1",
            label: "feature_4_1",
          },
          {
            value: "feature_4_2",
            label: "feature_4_2",
          },
          {
            value: "feature_4_3",
            label: "feature_4_3",
          },
          {
            value: "feature_4_4",
            label: "feature_4_4",
          },
          {
            value: "feature_4_5",
            label: "feature_4_5",
          },
          {
            value: "feature_4_6",
            label: "feature_4_6",
          },
          {
            value: "feature_4_7",
            label: "feature_4_7",
          },
          {
            value: "feature_4_8",
            label: "feature_4_8",
          },
          {
            value: "feature_4_9",
            label: "feature_4_9",
          },
        ],
      },
      {
        value: "book_4_5",
        label: "book_4_5",
        children: [
          {
            value: "feature_5_0",
            label: "feature_5_0",
          },
          {
            value: "feature_5_1",
            label: "feature_5_1",
          },
          {
            value: "feature_5_2",
            label: "feature_5_2",
          },
          {
            value: "feature_5_3",
            label: "feature_5_3",
          },
          {
            value: "feature_5_4",
            label: "feature_5_4",
          },
          {
            value: "feature_5_5",
            label: "feature_5_5",
          },
          {
            value: "feature_5_6",
            label: "feature_5_6",
          },
          {
            value: "feature_5_7",
            label: "feature_5_7",
          },
          {
            value: "feature_5_8",
            label: "feature_5_8",
          },
          {
            value: "feature_5_9",
            label: "feature_5_9",
          },
        ],
      },
      {
        value: "book_4_6",
        label: "book_4_6",
        children: [
          {
            value: "feature_6_0",
            label: "feature_6_0",
          },
          {
            value: "feature_6_1",
            label: "feature_6_1",
          },
          {
            value: "feature_6_2",
            label: "feature_6_2",
          },
          {
            value: "feature_6_3",
            label: "feature_6_3",
          },
          {
            value: "feature_6_4",
            label: "feature_6_4",
          },
          {
            value: "feature_6_5",
            label: "feature_6_5",
          },
          {
            value: "feature_6_6",
            label: "feature_6_6",
          },
          {
            value: "feature_6_7",
            label: "feature_6_7",
          },
          {
            value: "feature_6_8",
            label: "feature_6_8",
          },
          {
            value: "feature_6_9",
            label: "feature_6_9",
          },
        ],
      },
      {
        value: "book_4_7",
        label: "book_4_7",
        children: [
          {
            value: "feature_7_0",
            label: "feature_7_0",
          },
          {
            value: "feature_7_1",
            label: "feature_7_1",
          },
          {
            value: "feature_7_2",
            label: "feature_7_2",
          },
          {
            value: "feature_7_3",
            label: "feature_7_3",
          },
          {
            value: "feature_7_4",
            label: "feature_7_4",
          },
          {
            value: "feature_7_5",
            label: "feature_7_5",
          },
          {
            value: "feature_7_6",
            label: "feature_7_6",
          },
          {
            value: "feature_7_7",
            label: "feature_7_7",
          },
          {
            value: "feature_7_8",
            label: "feature_7_8",
          },
          {
            value: "feature_7_9",
            label: "feature_7_9",
          },
        ],
      },
      {
        value: "book_4_8",
        label: "book_4_8",
        children: [
          {
            value: "feature_8_0",
            label: "feature_8_0",
          },
          {
            value: "feature_8_1",
            label: "feature_8_1",
          },
          {
            value: "feature_8_2",
            label: "feature_8_2",
          },
          {
            value: "feature_8_3",
            label: "feature_8_3",
          },
          {
            value: "feature_8_4",
            label: "feature_8_4",
          },
          {
            value: "feature_8_5",
            label: "feature_8_5",
          },
          {
            value: "feature_8_6",
            label: "feature_8_6",
          },
          {
            value: "feature_8_7",
            label: "feature_8_7",
          },
          {
            value: "feature_8_8",
            label: "feature_8_8",
          },
          {
            value: "feature_8_9",
            label: "feature_8_9",
          },
        ],
      },
      {
        value: "book_4_9",
        label: "book_4_9",
        children: [
          {
            value: "feature_9_0",
            label: "feature_9_0",
          },
          {
            value: "feature_9_1",
            label: "feature_9_1",
          },
          {
            value: "feature_9_2",
            label: "feature_9_2",
          },
          {
            value: "feature_9_3",
            label: "feature_9_3",
          },
          {
            value: "feature_9_4",
            label: "feature_9_4",
          },
          {
            value: "feature_9_5",
            label: "feature_9_5",
          },
          {
            value: "feature_9_6",
            label: "feature_9_6",
          },
          {
            value: "feature_9_7",
            label: "feature_9_7",
          },
          {
            value: "feature_9_8",
            label: "feature_9_8",
          },
          {
            value: "feature_9_9",
            label: "feature_9_9",
          },
        ],
      },
    ],
  },
  {
    value: "provider_5",
    label: "provider_5",
    children: [
      {
        value: "book_5_0",
        label: "book_5_0",
        children: [
          {
            value: "feature_0_0",
            label: "feature_0_0",
          },
          {
            value: "feature_0_1",
            label: "feature_0_1",
          },
          {
            value: "feature_0_2",
            label: "feature_0_2",
          },
          {
            value: "feature_0_3",
            label: "feature_0_3",
          },
          {
            value: "feature_0_4",
            label: "feature_0_4",
          },
          {
            value: "feature_0_5",
            label: "feature_0_5",
          },
          {
            value: "feature_0_6",
            label: "feature_0_6",
          },
          {
            value: "feature_0_7",
            label: "feature_0_7",
          },
          {
            value: "feature_0_8",
            label: "feature_0_8",
          },
          {
            value: "feature_0_9",
            label: "feature_0_9",
          },
        ],
      },
      {
        value: "book_5_1",
        label: "book_5_1",
        children: [
          {
            value: "feature_1_0",
            label: "feature_1_0",
          },
          {
            value: "feature_1_1",
            label: "feature_1_1",
          },
          {
            value: "feature_1_2",
            label: "feature_1_2",
          },
          {
            value: "feature_1_3",
            label: "feature_1_3",
          },
          {
            value: "feature_1_4",
            label: "feature_1_4",
          },
          {
            value: "feature_1_5",
            label: "feature_1_5",
          },
          {
            value: "feature_1_6",
            label: "feature_1_6",
          },
          {
            value: "feature_1_7",
            label: "feature_1_7",
          },
          {
            value: "feature_1_8",
            label: "feature_1_8",
          },
          {
            value: "feature_1_9",
            label: "feature_1_9",
          },
        ],
      },
      {
        value: "book_5_2",
        label: "book_5_2",
        children: [
          {
            value: "feature_2_0",
            label: "feature_2_0",
          },
          {
            value: "feature_2_1",
            label: "feature_2_1",
          },
          {
            value: "feature_2_2",
            label: "feature_2_2",
          },
          {
            value: "feature_2_3",
            label: "feature_2_3",
          },
          {
            value: "feature_2_4",
            label: "feature_2_4",
          },
          {
            value: "feature_2_5",
            label: "feature_2_5",
          },
          {
            value: "feature_2_6",
            label: "feature_2_6",
          },
          {
            value: "feature_2_7",
            label: "feature_2_7",
          },
          {
            value: "feature_2_8",
            label: "feature_2_8",
          },
          {
            value: "feature_2_9",
            label: "feature_2_9",
          },
        ],
      },
      {
        value: "book_5_3",
        label: "book_5_3",
        children: [
          {
            value: "feature_3_0",
            label: "feature_3_0",
          },
          {
            value: "feature_3_1",
            label: "feature_3_1",
          },
          {
            value: "feature_3_2",
            label: "feature_3_2",
          },
          {
            value: "feature_3_3",
            label: "feature_3_3",
          },
          {
            value: "feature_3_4",
            label: "feature_3_4",
          },
          {
            value: "feature_3_5",
            label: "feature_3_5",
          },
          {
            value: "feature_3_6",
            label: "feature_3_6",
          },
          {
            value: "feature_3_7",
            label: "feature_3_7",
          },
          {
            value: "feature_3_8",
            label: "feature_3_8",
          },
          {
            value: "feature_3_9",
            label: "feature_3_9",
          },
        ],
      },
      {
        value: "book_5_4",
        label: "book_5_4",
        children: [
          {
            value: "feature_4_0",
            label: "feature_4_0",
          },
          {
            value: "feature_4_1",
            label: "feature_4_1",
          },
          {
            value: "feature_4_2",
            label: "feature_4_2",
          },
          {
            value: "feature_4_3",
            label: "feature_4_3",
          },
          {
            value: "feature_4_4",
            label: "feature_4_4",
          },
          {
            value: "feature_4_5",
            label: "feature_4_5",
          },
          {
            value: "feature_4_6",
            label: "feature_4_6",
          },
          {
            value: "feature_4_7",
            label: "feature_4_7",
          },
          {
            value: "feature_4_8",
            label: "feature_4_8",
          },
          {
            value: "feature_4_9",
            label: "feature_4_9",
          },
        ],
      },
      {
        value: "book_5_5",
        label: "book_5_5",
        children: [
          {
            value: "feature_5_0",
            label: "feature_5_0",
          },
          {
            value: "feature_5_1",
            label: "feature_5_1",
          },
          {
            value: "feature_5_2",
            label: "feature_5_2",
          },
          {
            value: "feature_5_3",
            label: "feature_5_3",
          },
          {
            value: "feature_5_4",
            label: "feature_5_4",
          },
          {
            value: "feature_5_5",
            label: "feature_5_5",
          },
          {
            value: "feature_5_6",
            label: "feature_5_6",
          },
          {
            value: "feature_5_7",
            label: "feature_5_7",
          },
          {
            value: "feature_5_8",
            label: "feature_5_8",
          },
          {
            value: "feature_5_9",
            label: "feature_5_9",
          },
        ],
      },
      {
        value: "book_5_6",
        label: "book_5_6",
        children: [
          {
            value: "feature_6_0",
            label: "feature_6_0",
          },
          {
            value: "feature_6_1",
            label: "feature_6_1",
          },
          {
            value: "feature_6_2",
            label: "feature_6_2",
          },
          {
            value: "feature_6_3",
            label: "feature_6_3",
          },
          {
            value: "feature_6_4",
            label: "feature_6_4",
          },
          {
            value: "feature_6_5",
            label: "feature_6_5",
          },
          {
            value: "feature_6_6",
            label: "feature_6_6",
          },
          {
            value: "feature_6_7",
            label: "feature_6_7",
          },
          {
            value: "feature_6_8",
            label: "feature_6_8",
          },
          {
            value: "feature_6_9",
            label: "feature_6_9",
          },
        ],
      },
      {
        value: "book_5_7",
        label: "book_5_7",
        children: [
          {
            value: "feature_7_0",
            label: "feature_7_0",
          },
          {
            value: "feature_7_1",
            label: "feature_7_1",
          },
          {
            value: "feature_7_2",
            label: "feature_7_2",
          },
          {
            value: "feature_7_3",
            label: "feature_7_3",
          },
          {
            value: "feature_7_4",
            label: "feature_7_4",
          },
          {
            value: "feature_7_5",
            label: "feature_7_5",
          },
          {
            value: "feature_7_6",
            label: "feature_7_6",
          },
          {
            value: "feature_7_7",
            label: "feature_7_7",
          },
          {
            value: "feature_7_8",
            label: "feature_7_8",
          },
          {
            value: "feature_7_9",
            label: "feature_7_9",
          },
        ],
      },
      {
        value: "book_5_8",
        label: "book_5_8",
        children: [
          {
            value: "feature_8_0",
            label: "feature_8_0",
          },
          {
            value: "feature_8_1",
            label: "feature_8_1",
          },
          {
            value: "feature_8_2",
            label: "feature_8_2",
          },
          {
            value: "feature_8_3",
            label: "feature_8_3",
          },
          {
            value: "feature_8_4",
            label: "feature_8_4",
          },
          {
            value: "feature_8_5",
            label: "feature_8_5",
          },
          {
            value: "feature_8_6",
            label: "feature_8_6",
          },
          {
            value: "feature_8_7",
            label: "feature_8_7",
          },
          {
            value: "feature_8_8",
            label: "feature_8_8",
          },
          {
            value: "feature_8_9",
            label: "feature_8_9",
          },
        ],
      },
      {
        value: "book_5_9",
        label: "book_5_9",
        children: [
          {
            value: "feature_9_0",
            label: "feature_9_0",
          },
          {
            value: "feature_9_1",
            label: "feature_9_1",
          },
          {
            value: "feature_9_2",
            label: "feature_9_2",
          },
          {
            value: "feature_9_3",
            label: "feature_9_3",
          },
          {
            value: "feature_9_4",
            label: "feature_9_4",
          },
          {
            value: "feature_9_5",
            label: "feature_9_5",
          },
          {
            value: "feature_9_6",
            label: "feature_9_6",
          },
          {
            value: "feature_9_7",
            label: "feature_9_7",
          },
          {
            value: "feature_9_8",
            label: "feature_9_8",
          },
          {
            value: "feature_9_9",
            label: "feature_9_9",
          },
        ],
      },
    ],
  },
  {
    value: "provider_6",
    label: "provider_6",
    children: [
      {
        value: "book_6_0",
        label: "book_6_0",
        children: [
          {
            value: "feature_0_0",
            label: "feature_0_0",
          },
          {
            value: "feature_0_1",
            label: "feature_0_1",
          },
          {
            value: "feature_0_2",
            label: "feature_0_2",
          },
          {
            value: "feature_0_3",
            label: "feature_0_3",
          },
          {
            value: "feature_0_4",
            label: "feature_0_4",
          },
          {
            value: "feature_0_5",
            label: "feature_0_5",
          },
          {
            value: "feature_0_6",
            label: "feature_0_6",
          },
          {
            value: "feature_0_7",
            label: "feature_0_7",
          },
          {
            value: "feature_0_8",
            label: "feature_0_8",
          },
          {
            value: "feature_0_9",
            label: "feature_0_9",
          },
        ],
      },
      {
        value: "book_6_1",
        label: "book_6_1",
        children: [
          {
            value: "feature_1_0",
            label: "feature_1_0",
          },
          {
            value: "feature_1_1",
            label: "feature_1_1",
          },
          {
            value: "feature_1_2",
            label: "feature_1_2",
          },
          {
            value: "feature_1_3",
            label: "feature_1_3",
          },
          {
            value: "feature_1_4",
            label: "feature_1_4",
          },
          {
            value: "feature_1_5",
            label: "feature_1_5",
          },
          {
            value: "feature_1_6",
            label: "feature_1_6",
          },
          {
            value: "feature_1_7",
            label: "feature_1_7",
          },
          {
            value: "feature_1_8",
            label: "feature_1_8",
          },
          {
            value: "feature_1_9",
            label: "feature_1_9",
          },
        ],
      },
      {
        value: "book_6_2",
        label: "book_6_2",
        children: [
          {
            value: "feature_2_0",
            label: "feature_2_0",
          },
          {
            value: "feature_2_1",
            label: "feature_2_1",
          },
          {
            value: "feature_2_2",
            label: "feature_2_2",
          },
          {
            value: "feature_2_3",
            label: "feature_2_3",
          },
          {
            value: "feature_2_4",
            label: "feature_2_4",
          },
          {
            value: "feature_2_5",
            label: "feature_2_5",
          },
          {
            value: "feature_2_6",
            label: "feature_2_6",
          },
          {
            value: "feature_2_7",
            label: "feature_2_7",
          },
          {
            value: "feature_2_8",
            label: "feature_2_8",
          },
          {
            value: "feature_2_9",
            label: "feature_2_9",
          },
        ],
      },
      {
        value: "book_6_3",
        label: "book_6_3",
        children: [
          {
            value: "feature_3_0",
            label: "feature_3_0",
          },
          {
            value: "feature_3_1",
            label: "feature_3_1",
          },
          {
            value: "feature_3_2",
            label: "feature_3_2",
          },
          {
            value: "feature_3_3",
            label: "feature_3_3",
          },
          {
            value: "feature_3_4",
            label: "feature_3_4",
          },
          {
            value: "feature_3_5",
            label: "feature_3_5",
          },
          {
            value: "feature_3_6",
            label: "feature_3_6",
          },
          {
            value: "feature_3_7",
            label: "feature_3_7",
          },
          {
            value: "feature_3_8",
            label: "feature_3_8",
          },
          {
            value: "feature_3_9",
            label: "feature_3_9",
          },
        ],
      },
      {
        value: "book_6_4",
        label: "book_6_4",
        children: [
          {
            value: "feature_4_0",
            label: "feature_4_0",
          },
          {
            value: "feature_4_1",
            label: "feature_4_1",
          },
          {
            value: "feature_4_2",
            label: "feature_4_2",
          },
          {
            value: "feature_4_3",
            label: "feature_4_3",
          },
          {
            value: "feature_4_4",
            label: "feature_4_4",
          },
          {
            value: "feature_4_5",
            label: "feature_4_5",
          },
          {
            value: "feature_4_6",
            label: "feature_4_6",
          },
          {
            value: "feature_4_7",
            label: "feature_4_7",
          },
          {
            value: "feature_4_8",
            label: "feature_4_8",
          },
          {
            value: "feature_4_9",
            label: "feature_4_9",
          },
        ],
      },
      {
        value: "book_6_5",
        label: "book_6_5",
        children: [
          {
            value: "feature_5_0",
            label: "feature_5_0",
          },
          {
            value: "feature_5_1",
            label: "feature_5_1",
          },
          {
            value: "feature_5_2",
            label: "feature_5_2",
          },
          {
            value: "feature_5_3",
            label: "feature_5_3",
          },
          {
            value: "feature_5_4",
            label: "feature_5_4",
          },
          {
            value: "feature_5_5",
            label: "feature_5_5",
          },
          {
            value: "feature_5_6",
            label: "feature_5_6",
          },
          {
            value: "feature_5_7",
            label: "feature_5_7",
          },
          {
            value: "feature_5_8",
            label: "feature_5_8",
          },
          {
            value: "feature_5_9",
            label: "feature_5_9",
          },
        ],
      },
      {
        value: "book_6_6",
        label: "book_6_6",
        children: [
          {
            value: "feature_6_0",
            label: "feature_6_0",
          },
          {
            value: "feature_6_1",
            label: "feature_6_1",
          },
          {
            value: "feature_6_2",
            label: "feature_6_2",
          },
          {
            value: "feature_6_3",
            label: "feature_6_3",
          },
          {
            value: "feature_6_4",
            label: "feature_6_4",
          },
          {
            value: "feature_6_5",
            label: "feature_6_5",
          },
          {
            value: "feature_6_6",
            label: "feature_6_6",
          },
          {
            value: "feature_6_7",
            label: "feature_6_7",
          },
          {
            value: "feature_6_8",
            label: "feature_6_8",
          },
          {
            value: "feature_6_9",
            label: "feature_6_9",
          },
        ],
      },
      {
        value: "book_6_7",
        label: "book_6_7",
        children: [
          {
            value: "feature_7_0",
            label: "feature_7_0",
          },
          {
            value: "feature_7_1",
            label: "feature_7_1",
          },
          {
            value: "feature_7_2",
            label: "feature_7_2",
          },
          {
            value: "feature_7_3",
            label: "feature_7_3",
          },
          {
            value: "feature_7_4",
            label: "feature_7_4",
          },
          {
            value: "feature_7_5",
            label: "feature_7_5",
          },
          {
            value: "feature_7_6",
            label: "feature_7_6",
          },
          {
            value: "feature_7_7",
            label: "feature_7_7",
          },
          {
            value: "feature_7_8",
            label: "feature_7_8",
          },
          {
            value: "feature_7_9",
            label: "feature_7_9",
          },
        ],
      },
      {
        value: "book_6_8",
        label: "book_6_8",
        children: [
          {
            value: "feature_8_0",
            label: "feature_8_0",
          },
          {
            value: "feature_8_1",
            label: "feature_8_1",
          },
          {
            value: "feature_8_2",
            label: "feature_8_2",
          },
          {
            value: "feature_8_3",
            label: "feature_8_3",
          },
          {
            value: "feature_8_4",
            label: "feature_8_4",
          },
          {
            value: "feature_8_5",
            label: "feature_8_5",
          },
          {
            value: "feature_8_6",
            label: "feature_8_6",
          },
          {
            value: "feature_8_7",
            label: "feature_8_7",
          },
          {
            value: "feature_8_8",
            label: "feature_8_8",
          },
          {
            value: "feature_8_9",
            label: "feature_8_9",
          },
        ],
      },
      {
        value: "book_6_9",
        label: "book_6_9",
        children: [
          {
            value: "feature_9_0",
            label: "feature_9_0",
          },
          {
            value: "feature_9_1",
            label: "feature_9_1",
          },
          {
            value: "feature_9_2",
            label: "feature_9_2",
          },
          {
            value: "feature_9_3",
            label: "feature_9_3",
          },
          {
            value: "feature_9_4",
            label: "feature_9_4",
          },
          {
            value: "feature_9_5",
            label: "feature_9_5",
          },
          {
            value: "feature_9_6",
            label: "feature_9_6",
          },
          {
            value: "feature_9_7",
            label: "feature_9_7",
          },
          {
            value: "feature_9_8",
            label: "feature_9_8",
          },
          {
            value: "feature_9_9",
            label: "feature_9_9",
          },
        ],
      },
    ],
  },
  {
    value: "provider_7",
    label: "provider_7",
    children: [
      {
        value: "book_7_0",
        label: "book_7_0",
        children: [
          {
            value: "feature_0_0",
            label: "feature_0_0",
          },
          {
            value: "feature_0_1",
            label: "feature_0_1",
          },
          {
            value: "feature_0_2",
            label: "feature_0_2",
          },
          {
            value: "feature_0_3",
            label: "feature_0_3",
          },
          {
            value: "feature_0_4",
            label: "feature_0_4",
          },
          {
            value: "feature_0_5",
            label: "feature_0_5",
          },
          {
            value: "feature_0_6",
            label: "feature_0_6",
          },
          {
            value: "feature_0_7",
            label: "feature_0_7",
          },
          {
            value: "feature_0_8",
            label: "feature_0_8",
          },
          {
            value: "feature_0_9",
            label: "feature_0_9",
          },
        ],
      },
      {
        value: "book_7_1",
        label: "book_7_1",
        children: [
          {
            value: "feature_1_0",
            label: "feature_1_0",
          },
          {
            value: "feature_1_1",
            label: "feature_1_1",
          },
          {
            value: "feature_1_2",
            label: "feature_1_2",
          },
          {
            value: "feature_1_3",
            label: "feature_1_3",
          },
          {
            value: "feature_1_4",
            label: "feature_1_4",
          },
          {
            value: "feature_1_5",
            label: "feature_1_5",
          },
          {
            value: "feature_1_6",
            label: "feature_1_6",
          },
          {
            value: "feature_1_7",
            label: "feature_1_7",
          },
          {
            value: "feature_1_8",
            label: "feature_1_8",
          },
          {
            value: "feature_1_9",
            label: "feature_1_9",
          },
        ],
      },
      {
        value: "book_7_2",
        label: "book_7_2",
        children: [
          {
            value: "feature_2_0",
            label: "feature_2_0",
          },
          {
            value: "feature_2_1",
            label: "feature_2_1",
          },
          {
            value: "feature_2_2",
            label: "feature_2_2",
          },
          {
            value: "feature_2_3",
            label: "feature_2_3",
          },
          {
            value: "feature_2_4",
            label: "feature_2_4",
          },
          {
            value: "feature_2_5",
            label: "feature_2_5",
          },
          {
            value: "feature_2_6",
            label: "feature_2_6",
          },
          {
            value: "feature_2_7",
            label: "feature_2_7",
          },
          {
            value: "feature_2_8",
            label: "feature_2_8",
          },
          {
            value: "feature_2_9",
            label: "feature_2_9",
          },
        ],
      },
      {
        value: "book_7_3",
        label: "book_7_3",
        children: [
          {
            value: "feature_3_0",
            label: "feature_3_0",
          },
          {
            value: "feature_3_1",
            label: "feature_3_1",
          },
          {
            value: "feature_3_2",
            label: "feature_3_2",
          },
          {
            value: "feature_3_3",
            label: "feature_3_3",
          },
          {
            value: "feature_3_4",
            label: "feature_3_4",
          },
          {
            value: "feature_3_5",
            label: "feature_3_5",
          },
          {
            value: "feature_3_6",
            label: "feature_3_6",
          },
          {
            value: "feature_3_7",
            label: "feature_3_7",
          },
          {
            value: "feature_3_8",
            label: "feature_3_8",
          },
          {
            value: "feature_3_9",
            label: "feature_3_9",
          },
        ],
      },
      {
        value: "book_7_4",
        label: "book_7_4",
        children: [
          {
            value: "feature_4_0",
            label: "feature_4_0",
          },
          {
            value: "feature_4_1",
            label: "feature_4_1",
          },
          {
            value: "feature_4_2",
            label: "feature_4_2",
          },
          {
            value: "feature_4_3",
            label: "feature_4_3",
          },
          {
            value: "feature_4_4",
            label: "feature_4_4",
          },
          {
            value: "feature_4_5",
            label: "feature_4_5",
          },
          {
            value: "feature_4_6",
            label: "feature_4_6",
          },
          {
            value: "feature_4_7",
            label: "feature_4_7",
          },
          {
            value: "feature_4_8",
            label: "feature_4_8",
          },
          {
            value: "feature_4_9",
            label: "feature_4_9",
          },
        ],
      },
      {
        value: "book_7_5",
        label: "book_7_5",
        children: [
          {
            value: "feature_5_0",
            label: "feature_5_0",
          },
          {
            value: "feature_5_1",
            label: "feature_5_1",
          },
          {
            value: "feature_5_2",
            label: "feature_5_2",
          },
          {
            value: "feature_5_3",
            label: "feature_5_3",
          },
          {
            value: "feature_5_4",
            label: "feature_5_4",
          },
          {
            value: "feature_5_5",
            label: "feature_5_5",
          },
          {
            value: "feature_5_6",
            label: "feature_5_6",
          },
          {
            value: "feature_5_7",
            label: "feature_5_7",
          },
          {
            value: "feature_5_8",
            label: "feature_5_8",
          },
          {
            value: "feature_5_9",
            label: "feature_5_9",
          },
        ],
      },
      {
        value: "book_7_6",
        label: "book_7_6",
        children: [
          {
            value: "feature_6_0",
            label: "feature_6_0",
          },
          {
            value: "feature_6_1",
            label: "feature_6_1",
          },
          {
            value: "feature_6_2",
            label: "feature_6_2",
          },
          {
            value: "feature_6_3",
            label: "feature_6_3",
          },
          {
            value: "feature_6_4",
            label: "feature_6_4",
          },
          {
            value: "feature_6_5",
            label: "feature_6_5",
          },
          {
            value: "feature_6_6",
            label: "feature_6_6",
          },
          {
            value: "feature_6_7",
            label: "feature_6_7",
          },
          {
            value: "feature_6_8",
            label: "feature_6_8",
          },
          {
            value: "feature_6_9",
            label: "feature_6_9",
          },
        ],
      },
      {
        value: "book_7_7",
        label: "book_7_7",
        children: [
          {
            value: "feature_7_0",
            label: "feature_7_0",
          },
          {
            value: "feature_7_1",
            label: "feature_7_1",
          },
          {
            value: "feature_7_2",
            label: "feature_7_2",
          },
          {
            value: "feature_7_3",
            label: "feature_7_3",
          },
          {
            value: "feature_7_4",
            label: "feature_7_4",
          },
          {
            value: "feature_7_5",
            label: "feature_7_5",
          },
          {
            value: "feature_7_6",
            label: "feature_7_6",
          },
          {
            value: "feature_7_7",
            label: "feature_7_7",
          },
          {
            value: "feature_7_8",
            label: "feature_7_8",
          },
          {
            value: "feature_7_9",
            label: "feature_7_9",
          },
        ],
      },
      {
        value: "book_7_8",
        label: "book_7_8",
        children: [
          {
            value: "feature_8_0",
            label: "feature_8_0",
          },
          {
            value: "feature_8_1",
            label: "feature_8_1",
          },
          {
            value: "feature_8_2",
            label: "feature_8_2",
          },
          {
            value: "feature_8_3",
            label: "feature_8_3",
          },
          {
            value: "feature_8_4",
            label: "feature_8_4",
          },
          {
            value: "feature_8_5",
            label: "feature_8_5",
          },
          {
            value: "feature_8_6",
            label: "feature_8_6",
          },
          {
            value: "feature_8_7",
            label: "feature_8_7",
          },
          {
            value: "feature_8_8",
            label: "feature_8_8",
          },
          {
            value: "feature_8_9",
            label: "feature_8_9",
          },
        ],
      },
      {
        value: "book_7_9",
        label: "book_7_9",
        children: [
          {
            value: "feature_9_0",
            label: "feature_9_0",
          },
          {
            value: "feature_9_1",
            label: "feature_9_1",
          },
          {
            value: "feature_9_2",
            label: "feature_9_2",
          },
          {
            value: "feature_9_3",
            label: "feature_9_3",
          },
          {
            value: "feature_9_4",
            label: "feature_9_4",
          },
          {
            value: "feature_9_5",
            label: "feature_9_5",
          },
          {
            value: "feature_9_6",
            label: "feature_9_6",
          },
          {
            value: "feature_9_7",
            label: "feature_9_7",
          },
          {
            value: "feature_9_8",
            label: "feature_9_8",
          },
          {
            value: "feature_9_9",
            label: "feature_9_9",
          },
        ],
      },
    ],
  },
  {
    value: "provider_8",
    label: "provider_8",
    children: [
      {
        value: "book_8_0",
        label: "book_8_0",
        children: [
          {
            value: "feature_0_0",
            label: "feature_0_0",
          },
          {
            value: "feature_0_1",
            label: "feature_0_1",
          },
          {
            value: "feature_0_2",
            label: "feature_0_2",
          },
          {
            value: "feature_0_3",
            label: "feature_0_3",
          },
          {
            value: "feature_0_4",
            label: "feature_0_4",
          },
          {
            value: "feature_0_5",
            label: "feature_0_5",
          },
          {
            value: "feature_0_6",
            label: "feature_0_6",
          },
          {
            value: "feature_0_7",
            label: "feature_0_7",
          },
          {
            value: "feature_0_8",
            label: "feature_0_8",
          },
          {
            value: "feature_0_9",
            label: "feature_0_9",
          },
        ],
      },
      {
        value: "book_8_1",
        label: "book_8_1",
        children: [
          {
            value: "feature_1_0",
            label: "feature_1_0",
          },
          {
            value: "feature_1_1",
            label: "feature_1_1",
          },
          {
            value: "feature_1_2",
            label: "feature_1_2",
          },
          {
            value: "feature_1_3",
            label: "feature_1_3",
          },
          {
            value: "feature_1_4",
            label: "feature_1_4",
          },
          {
            value: "feature_1_5",
            label: "feature_1_5",
          },
          {
            value: "feature_1_6",
            label: "feature_1_6",
          },
          {
            value: "feature_1_7",
            label: "feature_1_7",
          },
          {
            value: "feature_1_8",
            label: "feature_1_8",
          },
          {
            value: "feature_1_9",
            label: "feature_1_9",
          },
        ],
      },
      {
        value: "book_8_2",
        label: "book_8_2",
        children: [
          {
            value: "feature_2_0",
            label: "feature_2_0",
          },
          {
            value: "feature_2_1",
            label: "feature_2_1",
          },
          {
            value: "feature_2_2",
            label: "feature_2_2",
          },
          {
            value: "feature_2_3",
            label: "feature_2_3",
          },
          {
            value: "feature_2_4",
            label: "feature_2_4",
          },
          {
            value: "feature_2_5",
            label: "feature_2_5",
          },
          {
            value: "feature_2_6",
            label: "feature_2_6",
          },
          {
            value: "feature_2_7",
            label: "feature_2_7",
          },
          {
            value: "feature_2_8",
            label: "feature_2_8",
          },
          {
            value: "feature_2_9",
            label: "feature_2_9",
          },
        ],
      },
      {
        value: "book_8_3",
        label: "book_8_3",
        children: [
          {
            value: "feature_3_0",
            label: "feature_3_0",
          },
          {
            value: "feature_3_1",
            label: "feature_3_1",
          },
          {
            value: "feature_3_2",
            label: "feature_3_2",
          },
          {
            value: "feature_3_3",
            label: "feature_3_3",
          },
          {
            value: "feature_3_4",
            label: "feature_3_4",
          },
          {
            value: "feature_3_5",
            label: "feature_3_5",
          },
          {
            value: "feature_3_6",
            label: "feature_3_6",
          },
          {
            value: "feature_3_7",
            label: "feature_3_7",
          },
          {
            value: "feature_3_8",
            label: "feature_3_8",
          },
          {
            value: "feature_3_9",
            label: "feature_3_9",
          },
        ],
      },
      {
        value: "book_8_4",
        label: "book_8_4",
        children: [
          {
            value: "feature_4_0",
            label: "feature_4_0",
          },
          {
            value: "feature_4_1",
            label: "feature_4_1",
          },
          {
            value: "feature_4_2",
            label: "feature_4_2",
          },
          {
            value: "feature_4_3",
            label: "feature_4_3",
          },
          {
            value: "feature_4_4",
            label: "feature_4_4",
          },
          {
            value: "feature_4_5",
            label: "feature_4_5",
          },
          {
            value: "feature_4_6",
            label: "feature_4_6",
          },
          {
            value: "feature_4_7",
            label: "feature_4_7",
          },
          {
            value: "feature_4_8",
            label: "feature_4_8",
          },
          {
            value: "feature_4_9",
            label: "feature_4_9",
          },
        ],
      },
      {
        value: "book_8_5",
        label: "book_8_5",
        children: [
          {
            value: "feature_5_0",
            label: "feature_5_0",
          },
          {
            value: "feature_5_1",
            label: "feature_5_1",
          },
          {
            value: "feature_5_2",
            label: "feature_5_2",
          },
          {
            value: "feature_5_3",
            label: "feature_5_3",
          },
          {
            value: "feature_5_4",
            label: "feature_5_4",
          },
          {
            value: "feature_5_5",
            label: "feature_5_5",
          },
          {
            value: "feature_5_6",
            label: "feature_5_6",
          },
          {
            value: "feature_5_7",
            label: "feature_5_7",
          },
          {
            value: "feature_5_8",
            label: "feature_5_8",
          },
          {
            value: "feature_5_9",
            label: "feature_5_9",
          },
        ],
      },
      {
        value: "book_8_6",
        label: "book_8_6",
        children: [
          {
            value: "feature_6_0",
            label: "feature_6_0",
          },
          {
            value: "feature_6_1",
            label: "feature_6_1",
          },
          {
            value: "feature_6_2",
            label: "feature_6_2",
          },
          {
            value: "feature_6_3",
            label: "feature_6_3",
          },
          {
            value: "feature_6_4",
            label: "feature_6_4",
          },
          {
            value: "feature_6_5",
            label: "feature_6_5",
          },
          {
            value: "feature_6_6",
            label: "feature_6_6",
          },
          {
            value: "feature_6_7",
            label: "feature_6_7",
          },
          {
            value: "feature_6_8",
            label: "feature_6_8",
          },
          {
            value: "feature_6_9",
            label: "feature_6_9",
          },
        ],
      },
      {
        value: "book_8_7",
        label: "book_8_7",
        children: [
          {
            value: "feature_7_0",
            label: "feature_7_0",
          },
          {
            value: "feature_7_1",
            label: "feature_7_1",
          },
          {
            value: "feature_7_2",
            label: "feature_7_2",
          },
          {
            value: "feature_7_3",
            label: "feature_7_3",
          },
          {
            value: "feature_7_4",
            label: "feature_7_4",
          },
          {
            value: "feature_7_5",
            label: "feature_7_5",
          },
          {
            value: "feature_7_6",
            label: "feature_7_6",
          },
          {
            value: "feature_7_7",
            label: "feature_7_7",
          },
          {
            value: "feature_7_8",
            label: "feature_7_8",
          },
          {
            value: "feature_7_9",
            label: "feature_7_9",
          },
        ],
      },
      {
        value: "book_8_8",
        label: "book_8_8",
        children: [
          {
            value: "feature_8_0",
            label: "feature_8_0",
          },
          {
            value: "feature_8_1",
            label: "feature_8_1",
          },
          {
            value: "feature_8_2",
            label: "feature_8_2",
          },
          {
            value: "feature_8_3",
            label: "feature_8_3",
          },
          {
            value: "feature_8_4",
            label: "feature_8_4",
          },
          {
            value: "feature_8_5",
            label: "feature_8_5",
          },
          {
            value: "feature_8_6",
            label: "feature_8_6",
          },
          {
            value: "feature_8_7",
            label: "feature_8_7",
          },
          {
            value: "feature_8_8",
            label: "feature_8_8",
          },
          {
            value: "feature_8_9",
            label: "feature_8_9",
          },
        ],
      },
      {
        value: "book_8_9",
        label: "book_8_9",
        children: [
          {
            value: "feature_9_0",
            label: "feature_9_0",
          },
          {
            value: "feature_9_1",
            label: "feature_9_1",
          },
          {
            value: "feature_9_2",
            label: "feature_9_2",
          },
          {
            value: "feature_9_3",
            label: "feature_9_3",
          },
          {
            value: "feature_9_4",
            label: "feature_9_4",
          },
          {
            value: "feature_9_5",
            label: "feature_9_5",
          },
          {
            value: "feature_9_6",
            label: "feature_9_6",
          },
          {
            value: "feature_9_7",
            label: "feature_9_7",
          },
          {
            value: "feature_9_8",
            label: "feature_9_8",
          },
          {
            value: "feature_9_9",
            label: "feature_9_9",
          },
        ],
      },
    ],
  },
  {
    value: "provider_9",
    label: "provider_9",
    children: [
      {
        value: "book_9_0",
        label: "book_9_0",
        children: [
          {
            value: "feature_0_0",
            label: "feature_0_0",
          },
          {
            value: "feature_0_1",
            label: "feature_0_1",
          },
          {
            value: "feature_0_2",
            label: "feature_0_2",
          },
          {
            value: "feature_0_3",
            label: "feature_0_3",
          },
          {
            value: "feature_0_4",
            label: "feature_0_4",
          },
          {
            value: "feature_0_5",
            label: "feature_0_5",
          },
          {
            value: "feature_0_6",
            label: "feature_0_6",
          },
          {
            value: "feature_0_7",
            label: "feature_0_7",
          },
          {
            value: "feature_0_8",
            label: "feature_0_8",
          },
          {
            value: "feature_0_9",
            label: "feature_0_9",
          },
        ],
      },
      {
        value: "book_9_1",
        label: "book_9_1",
        children: [
          {
            value: "feature_1_0",
            label: "feature_1_0",
          },
          {
            value: "feature_1_1",
            label: "feature_1_1",
          },
          {
            value: "feature_1_2",
            label: "feature_1_2",
          },
          {
            value: "feature_1_3",
            label: "feature_1_3",
          },
          {
            value: "feature_1_4",
            label: "feature_1_4",
          },
          {
            value: "feature_1_5",
            label: "feature_1_5",
          },
          {
            value: "feature_1_6",
            label: "feature_1_6",
          },
          {
            value: "feature_1_7",
            label: "feature_1_7",
          },
          {
            value: "feature_1_8",
            label: "feature_1_8",
          },
          {
            value: "feature_1_9",
            label: "feature_1_9",
          },
        ],
      },
      {
        value: "book_9_2",
        label: "book_9_2",
        children: [
          {
            value: "feature_2_0",
            label: "feature_2_0",
          },
          {
            value: "feature_2_1",
            label: "feature_2_1",
          },
          {
            value: "feature_2_2",
            label: "feature_2_2",
          },
          {
            value: "feature_2_3",
            label: "feature_2_3",
          },
          {
            value: "feature_2_4",
            label: "feature_2_4",
          },
          {
            value: "feature_2_5",
            label: "feature_2_5",
          },
          {
            value: "feature_2_6",
            label: "feature_2_6",
          },
          {
            value: "feature_2_7",
            label: "feature_2_7",
          },
          {
            value: "feature_2_8",
            label: "feature_2_8",
          },
          {
            value: "feature_2_9",
            label: "feature_2_9",
          },
        ],
      },
      {
        value: "book_9_3",
        label: "book_9_3",
        children: [
          {
            value: "feature_3_0",
            label: "feature_3_0",
          },
          {
            value: "feature_3_1",
            label: "feature_3_1",
          },
          {
            value: "feature_3_2",
            label: "feature_3_2",
          },
          {
            value: "feature_3_3",
            label: "feature_3_3",
          },
          {
            value: "feature_3_4",
            label: "feature_3_4",
          },
          {
            value: "feature_3_5",
            label: "feature_3_5",
          },
          {
            value: "feature_3_6",
            label: "feature_3_6",
          },
          {
            value: "feature_3_7",
            label: "feature_3_7",
          },
          {
            value: "feature_3_8",
            label: "feature_3_8",
          },
          {
            value: "feature_3_9",
            label: "feature_3_9",
          },
        ],
      },
      {
        value: "book_9_4",
        label: "book_9_4",
        children: [
          {
            value: "feature_4_0",
            label: "feature_4_0",
          },
          {
            value: "feature_4_1",
            label: "feature_4_1",
          },
          {
            value: "feature_4_2",
            label: "feature_4_2",
          },
          {
            value: "feature_4_3",
            label: "feature_4_3",
          },
          {
            value: "feature_4_4",
            label: "feature_4_4",
          },
          {
            value: "feature_4_5",
            label: "feature_4_5",
          },
          {
            value: "feature_4_6",
            label: "feature_4_6",
          },
          {
            value: "feature_4_7",
            label: "feature_4_7",
          },
          {
            value: "feature_4_8",
            label: "feature_4_8",
          },
          {
            value: "feature_4_9",
            label: "feature_4_9",
          },
        ],
      },
      {
        value: "book_9_5",
        label: "book_9_5",
        children: [
          {
            value: "feature_5_0",
            label: "feature_5_0",
          },
          {
            value: "feature_5_1",
            label: "feature_5_1",
          },
          {
            value: "feature_5_2",
            label: "feature_5_2",
          },
          {
            value: "feature_5_3",
            label: "feature_5_3",
          },
          {
            value: "feature_5_4",
            label: "feature_5_4",
          },
          {
            value: "feature_5_5",
            label: "feature_5_5",
          },
          {
            value: "feature_5_6",
            label: "feature_5_6",
          },
          {
            value: "feature_5_7",
            label: "feature_5_7",
          },
          {
            value: "feature_5_8",
            label: "feature_5_8",
          },
          {
            value: "feature_5_9",
            label: "feature_5_9",
          },
        ],
      },
      {
        value: "book_9_6",
        label: "book_9_6",
        children: [
          {
            value: "feature_6_0",
            label: "feature_6_0",
          },
          {
            value: "feature_6_1",
            label: "feature_6_1",
          },
          {
            value: "feature_6_2",
            label: "feature_6_2",
          },
          {
            value: "feature_6_3",
            label: "feature_6_3",
          },
          {
            value: "feature_6_4",
            label: "feature_6_4",
          },
          {
            value: "feature_6_5",
            label: "feature_6_5",
          },
          {
            value: "feature_6_6",
            label: "feature_6_6",
          },
          {
            value: "feature_6_7",
            label: "feature_6_7",
          },
          {
            value: "feature_6_8",
            label: "feature_6_8",
          },
          {
            value: "feature_6_9",
            label: "feature_6_9",
          },
        ],
      },
      {
        value: "book_9_7",
        label: "book_9_7",
        children: [
          {
            value: "feature_7_0",
            label: "feature_7_0",
          },
          {
            value: "feature_7_1",
            label: "feature_7_1",
          },
          {
            value: "feature_7_2",
            label: "feature_7_2",
          },
          {
            value: "feature_7_3",
            label: "feature_7_3",
          },
          {
            value: "feature_7_4",
            label: "feature_7_4",
          },
          {
            value: "feature_7_5",
            label: "feature_7_5",
          },
          {
            value: "feature_7_6",
            label: "feature_7_6",
          },
          {
            value: "feature_7_7",
            label: "feature_7_7",
          },
          {
            value: "feature_7_8",
            label: "feature_7_8",
          },
          {
            value: "feature_7_9",
            label: "feature_7_9",
          },
        ],
      },
      {
        value: "book_9_8",
        label: "book_9_8",
        children: [
          {
            value: "feature_8_0",
            label: "feature_8_0",
          },
          {
            value: "feature_8_1",
            label: "feature_8_1",
          },
          {
            value: "feature_8_2",
            label: "feature_8_2",
          },
          {
            value: "feature_8_3",
            label: "feature_8_3",
          },
          {
            value: "feature_8_4",
            label: "feature_8_4",
          },
          {
            value: "feature_8_5",
            label: "feature_8_5",
          },
          {
            value: "feature_8_6",
            label: "feature_8_6",
          },
          {
            value: "feature_8_7",
            label: "feature_8_7",
          },
          {
            value: "feature_8_8",
            label: "feature_8_8",
          },
          {
            value: "feature_8_9",
            label: "feature_8_9",
          },
        ],
      },
      {
        value: "book_9_9",
        label: "book_9_9",
        children: [
          {
            value: "feature_9_0",
            label: "feature_9_0",
          },
          {
            value: "feature_9_1",
            label: "feature_9_1",
          },
          {
            value: "feature_9_2",
            label: "feature_9_2",
          },
          {
            value: "feature_9_3",
            label: "feature_9_3",
          },
          {
            value: "feature_9_4",
            label: "feature_9_4",
          },
          {
            value: "feature_9_5",
            label: "feature_9_5",
          },
          {
            value: "feature_9_6",
            label: "feature_9_6",
          },
          {
            value: "feature_9_7",
            label: "feature_9_7",
          },
          {
            value: "feature_9_8",
            label: "feature_9_8",
          },
          {
            value: "feature_9_9",
            label: "feature_9_9",
          },
        ],
      },
    ],
  },
]
