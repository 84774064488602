import awsconfig from "../../aws-exports"

export * from "./s3"

export const bucketName = awsconfig.aws_user_files_s3_bucket
export const storagePath: string = `s3://${bucketName}/public/`
export const storageBacktestDoneDir: string = "backtestDone/"
export const storageBacktestInputDir: string = "backtestInput/"

//export const storagePath: string ="s3://datasetsbucket/public/"

