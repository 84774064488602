// redux
import { createSlice } from "@reduxjs/toolkit"

//types
import { datasetDb } from "../../types"

// Initial State
const initialState: { datasetProps: datasetDb } = {
  datasetProps: {
    datasetId: null,
    uploadBy: null,
    uploadAt: null,
    updatedBy: null,
    updatedAt: null,
    datasetName: null,
    fileName: null,
    storagePath: null,
    policies: null,
    description: null,
    size: null,
    qtdRows: null,
    sample: null,
    featureList: [],
    providerBookList: [],
    providerFeatureList: [],
  },
}

// Reducer
export const datasetPropsSlice = createSlice({
  name: "datasetProps",
  initialState,
  reducers: {
    resetDatasetProps: () => initialState,
    setDatasetProps: (state, action) => {
      return {
        ...state,
        datasetProps: action.payload,
      }
    },
  },
})

export const { resetDatasetProps, setDatasetProps } = datasetPropsSlice.actions

export default datasetPropsSlice.reducer
