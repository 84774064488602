//amplify
import { API } from "aws-amplify"

export const deletePolicyById = async (id:string) => {
	try {

		console.log('call',`/policies/object/${id}`)
		const result = await API.del(
			"policiesAPI",
			`/policies/object/${id}`,
			{}
		)
console.log('api',result)
		return true

	} catch (error) {
		console.log(error)
		return false
	}
}