//css
import styles from "./page-header.module.css"

//ant
import { Layout, Space, message, Col, Row  } from "antd"

//components
import UserDropDown from "./components/user-drop-down"
import NotificationPanel from "./components/notification-panel"
import ProgressPanel from "./components/progress-panel"
import ProductsMenu from "./components/products-menu";

const { Header } = Layout

const PageHeader = () => {
	return (
		<Header className={styles.header}>
			<Row>
				<Col span={12} className={styles.header_normal}>
					<Space size="large" align="center">
					</Space>
				</Col>
				<Col span={12} className={styles.header_reverse}>
					<Space size="large" >
						<NotificationPanel />
						<ProductsMenu />
						<UserDropDown />
					</Space>
				</Col>
			</Row>
		</Header>
	)
}

export default PageHeader
