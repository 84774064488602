//amplify
import { API } from "aws-amplify"

export const deleteDatasetById = async (id:string) => {
	try {
		const result = await API.del(
			"datasetAPI",
			`/dataset/object/${id}`,
			{}
		)

		return result

	} catch (error) {
		console.log(error)
		return error
	}
}