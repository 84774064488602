//amplify
import { API } from "aws-amplify"

export const getDatasetList = async () => {
  try {
    const response = await API.get("datasetAPI", "/dataset", {})
    const responseBody = JSON.parse(response.body)
    return responseBody
  } catch (error) {
    console.log(error)
    return error
  }
}
