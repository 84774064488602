//css
import styles from "./payload.module.css"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setActiveStep } from "../../redux-store/new-policy-reducer/active-step-slice"
import { submmitPayloadIn } from "../../redux-store/new-policy-reducer/new-policy-slice"

//antd
import { Input, Button, Form, Space, Select, Row, Col } from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"

const selectItemsDataType: { value: string; label: string }[] = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
]

type Props = {
  componentId: number
}

const uuid = require("uuid")

const PayloadIn = ({ componentId }: Props) => {
  const dispatch = useAppDispatch()
  const { payloadIn } = useAppSelector((state) => state.newPolicy)

  const onFinish = (values: any) => {
    const children: {
      key: string
      value: string
      label: string
      dataType: string
      allowedValues: string
    }[] = []
    for (let i in values.requestPayload) {
      children.push({
        key: i,
        value: values.requestPayload[i].field,
        label: values.requestPayload[i].field,
        dataType: values.requestPayload[i].dataType,
        allowedValues: values.requestPayload[i].allowedValues,
      })
    }

    const cascaderList: {
      key: string
      label: string
      value: string
      children: {
        key: string
        value: string
        label: string
        dataType: string
        allowedValues: string
      }[]
    }[] = [
      {
        key: payloadIn.cascaderPayloadList[0]
          ? payloadIn.cascaderPayloadList[0].key
          : String(uuid.v1()),
        label: "Input Data",
        value: "payload",
        children: children,
      },
    ]

    dispatch(
      submmitPayloadIn({
        requestPayload: values.requestPayload,
        cascaderPayloadList: cascaderList,
      })
    )

    dispatch(setActiveStep(componentId + 1))
  }

  return (
    <div className={styles.container_form}>
      {
        <Form
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ requestPayload: payloadIn.requestPayload }}
        >
          <Form.Item
            label="Insert here input data fields that will be send at the policy
              request url:"
            tooltip="Use this form to register input fields that can be used to create the policy rules. Provide a description, field name, data type (string, number) and allowed values."
          ></Form.Item>

          <Form.List name="requestPayload">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Row
                      gutter={[8, 8]}
                      key={key}
                      justify="space-around"
                      align="top"
                    >
                      <Col key={key + "1"} span={7}>
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          rules={[
                            {
                              required: true,
                              message: "Please insert a field description",
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Description" />
                        </Form.Item>
                      </Col>
                      <Col key={key + "2"} span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, "field"]}
                          rules={[
                            {
                              required: true,
                              message: "Please insert field name",
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Field Name" />
                        </Form.Item>
                      </Col>
                      <Col key={key + "3"} span={3}>
                        <Form.Item
                          {...restField}
                          name={[name, "dataType"]}
                          rules={[
                            {
                              required: true,
                              message: "Please choose a data type",
                            },
                          ]}
                        >
                          <Select
                            className="nodrag"
                            placeholder="Data type"
                            size="large"
                            options={selectItemsDataType}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col key={key + "4"} span={7}>
                        <Form.Item
                          {...restField}
                          name={[name, "allowedValues"]}
                          rules={[
                            {
                              required: true,
                              message: "Please insert the allowed values",
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Allowed values" />
                        </Form.Item>
                      </Col>
                      <Col key={key + "5"} span={1}>
                        <Form.Item>
                          <MinusCircleOutlined
                            className={styles.minus_button}
                            onClick={() => remove(name)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    {/* </Space>*/}
                  </>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    size="large"
                    style={{ width: "100%" }}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <div className={styles.container_button}>
            <Space size="large">
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  Continue
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  size="large"
                  onClick={() => dispatch(setActiveStep(componentId - 1))}
                >
                  Back
                </Button>
              </Form.Item>
            </Space>
          </div>
        </Form>
      }
    </div>
  )
}

export default PayloadIn
