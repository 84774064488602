import { putDataset } from "../../services/dataset-database-service"
import { createProviderList } from "./create-provider-List"
import { modalError } from "../../components/dataset/modal-error"

//types
import { datasetDb } from "../../types"

type Props = {
  datasetProps: datasetDb
}

export const saveDataset = async ({ datasetProps }: Props) => {
  //create provider feature and book list
  const providerList = createProviderList(datasetProps.featureList)

  // update table with providers configuration
  const newDataset: datasetDb = {
    datasetId: datasetProps.datasetId,
    uploadBy: datasetProps.uploadBy,
    uploadAt: datasetProps.uploadAt,
    updatedAt: datasetProps.updatedAt,
    updatedBy: datasetProps.updatedBy,
    datasetName: datasetProps.datasetName,
    fileName: datasetProps.fileName,
    storagePath: datasetProps.storagePath,
    policies: datasetProps.policies,
    description: datasetProps.description,
    size: datasetProps.size,
    qtdRows: datasetProps.qtdRows,
    sample: datasetProps.sample,
    featureList: datasetProps.featureList,
    providerBookList: providerList.providerBookList,
    providerFeatureList: providerList.providerFeatureList,
  }

  try {
    // update database with new file info
    const result = await putDataset(newDataset)
    return true
  } catch (error: unknown) {
    if (error instanceof Error) {
      modalError({
        name: datasetProps.datasetName!,
        type: "general",
        okAction: () => {
          return false
        },
        closeAction: () => {},
      })
    }
    return false
  }
}
