//css
import styles from "./create-rules.module.css"

//react
import { useState } from "react"

//react router dom
import { useNavigate } from "react-router-dom"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { submitSelectedFeature } from "../../redux-store/new-policy-reducer/new-policy-slice"
import { setActiveStep } from "../../redux-store/new-policy-reducer/active-step-slice"

//antd
import { Alert, Button, Space } from "antd"

//components
import NodeChart from "../../components/node-rules/node-chart"
import SavePolicyBtn from "../../components/policy/save-policy-btn"



type Props = {
  componentId: number
}

const CreateRules = ({ componentId }: Props) => {
  const dispatch = useAppDispatch()

  let navigate = useNavigate()

  const { selectedFeature } =
    useAppSelector((state) => state.newPolicy)
  const { nodes, edges } = useAppSelector((state) => state.node)

  return (
    <div className={styles.container}>
      {selectedFeature.name && (
        <Alert
          message={`${selectedFeature.name.toUpperCase()} (${
            selectedFeature.source
          }) is of type ${
            selectedFeature.dataType
          } and can assume the following values: ${
            selectedFeature.allowedValues
          } `}
          showIcon
          closable
          type="warning"
          className={styles.alert}
        />
      )}
      <div className={styles.container_node}>
        <NodeChart nodes={nodes} edges={edges} />
      </div>

      <>
        <div className={` ${styles.action_btn_container}`}>
          <Space size="large">
            <SavePolicyBtn componentId={componentId} />

            <Button
              size="large"
              onClick={() => {
                dispatch(
                  submitSelectedFeature({
                    name: null,
                    dataType: null,
                    allowedValues: null,
                    source: null,
                  })
                )

                dispatch(setActiveStep(componentId - 1))
              }}
            >
              Back
            </Button>

            <Button size="large" onClick={() => navigate("/policies")}>
              Back to Policies
            </Button>
          </Space>
        </div>
      </>
    </div>
  )
}

export default CreateRules
