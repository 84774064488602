//styles
import styles from "./book-list-drawer.module.css"

//react
import { useState } from "react"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import {
	setShowFeature,
	resetShowBook,
	setUserFeatureList,
} from "../../redux-store/feature-store/feature-store-slice"

//antd
import { Card, Drawer, Space, Divider, Button } from "antd"

//components
import FeatureListDrawer from "./feature-list-drawer"

//services

import { bookList, sampleCatalog } from "../../pages/feature-store/providers-data"

//types
type bookList = {
	bookId: string
	providerId: string
	bookName: string
	bookDescription: string
	active: boolean
}[]

const BookListDrawer = () => {
	const dispatch = useAppDispatch()

	//redux states
	const { showBook } = useAppSelector((state) => state.featureStore)

	//states
	const [loading, setLoading] = useState<boolean>(true)

	// get dataset list from db and set redux states
	//const getData = async () => {
	//	setLoading(true)

	//try {
	const data: bookList = []
	//get data from database
	for (let i in bookList) {
		if (bookList[i].providerId === showBook.providerId) {
			data.push(bookList[i])
		}
	}

	//return bookList
	/*} catch (error: unknown) {
			if (error instanceof Error) {
				const saveError = new Error(error.message)
				console.log(saveError)
			}
		}*/
	//setLoading(false)
	//}

	//useEffect(() => {
	//	getData()
	//}, [showBook])

	let providerOpacity: number = 0.7
	if (showBook.providerActive) providerOpacity = 1.0

	return (
		<Drawer
			title={showBook.providerName}
			width={"50%"}
			closable={false}
			maskClosable={false}
			onClose={() => dispatch(resetShowBook())}
			open={showBook.show}
			extra={
				<Space>
					<Button type="primary" onClick={() => dispatch(resetShowBook())}>
						Close
					</Button>
				</Space>
			}
		>
			<div
				className={styles.drawer_container}
				style={{ opacity: providerOpacity }}
			>
				<p>{showBook.providerDescription}</p>

				<div style={{ margin: 0, padding: 0 }}>
					<Divider orientation="left" orientationMargin="0">
						<h6>Features Catalogs (Books)</h6>
					</Divider>
				</div>

				<Space direction="vertical">
					{data.map((book) => {
						let bookOpacity: number = 0.7
						if (book.active || !showBook.providerActive) bookOpacity = 1.0
						return (
							<Card
								className={styles.card_container}
								style={{ opacity: bookOpacity }}
								key={book.bookId}
								size="small"
								bordered={true}
								hoverable={true}
								onClick={() => {
									// call api of feature list by bookId
									const featureList = []
									for (let i in sampleCatalog) {
										if (sampleCatalog[i].bookId === book.bookId) {
											featureList.push({
												key: sampleCatalog[i].featureId,
												label: sampleCatalog[i].label,
												name: sampleCatalog[i].name,
												description: sampleCatalog[i].description,
												favorite: sampleCatalog[i].favorite,
											})
										}
									}
									dispatch(setUserFeatureList(featureList))

									dispatch(
										setShowFeature({
											show: true,
											bookId: book.bookId,
											bookName: book.bookName,
											bookActive: book.active,
										})
									)
								}}
							>
								<h6 style={{ margin: 0 }}>{book.bookName}</h6>
								<p> {book.bookDescription}</p>
							</Card>
						)
					})}
				</Space>
			</div>
			<FeatureListDrawer />
		</Drawer>
	)
}

export default BookListDrawer
