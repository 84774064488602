//styles
import styles from "./dataset.module.css"

//react
import { useEffect, useState } from "react"

//router
import { Link, useNavigate } from "react-router-dom"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setShowModal } from "../../redux-store/upload-dataset/upload-dataset-slice"
import {
  resetDatasetProps,
  setDatasetProps,
} from "../../redux-store/upload-dataset/dataset-setup-slice"
import {
  resetActiveStep,
  setIsPolicySaved,
} from "../../redux-store/new-policy-reducer/active-step-slice"
import { resetNewPolicy } from "../../redux-store/new-policy-reducer/new-policy-slice"
import { resetNode } from "../../redux-store/new-policy-reducer/node-slice"

//antd
import {
  Button,
  Input,
  Table,
  Row,
  Col,
  Space,
  Tag,
  Select,
  SelectProps,
  Tooltip,
} from "antd"

import {
  BarChartOutlined,
  SettingOutlined,
  DeleteOutlined,
} from "@ant-design/icons"

import type { ColumnsType } from "antd/es/table"

//utils
import { formatDateUnix } from "../../utils/string-unixtime"
import { distinctOptionsList } from "../../utils/distinct-option-list"

//components
import UploadDataset from "../../components/upload-modal/upload-dataset"
import { deleteById } from "../../components/delete-confirmation-modal/delete-confirmation-modal-by-id"
import ConnectionBookTable from "../../components/providers/connection-book-table"
import { modalError } from "../../components/dataset/modal-error"

//call dataset database service
import {
  callDeleteDatasetService,
  callGetDatasetList,
} from "../../services/dataset-database-service/call-dataset-service"

//types
import { datasetDb } from "../../types"

const { Search } = Input
const uuid = require("uuid")

const Dataset = () => {
  const dispatch = useAppDispatch()

  let navigate = useNavigate()

  const { showModal } = useAppSelector((state) => state.uploadModal)

  const [data, setData] = useState<datasetDb[] | undefined>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [searchedName, setSearchedName] = useState<string>("")
  const [selectedUser, setSelectedUser] = useState<string[]>([])

  const [userList, setUserList] = useState<SelectProps["options"]>([])

  const columns: ColumnsType<datasetDb> = [
    {
      title: "Dataset name",
      dataIndex: "datasetName",
      key: "datasetName",
      filteredValue: [searchedName],
      onFilter: (value: string | number | boolean, record: datasetDb) =>
        String(record.datasetName)
          .toLowerCase()
          .includes(String(value).toLowerCase()),
    },
    {
      title: "Data connections",
      key: "providerBookList",
      dataIndex: "providerBookList",
      render: (_, { providerBookList }) => (
        <>
          {providerBookList?.map((prov) => {
            let color = "geekblue"
            let providerName: string = prov.provider ?? ""
            return (
              <Tag
                color={color}
                key={providerName}
                style={{ marginTop: "2px", marginBottom: "2px" }}
              >
                {providerName.toUpperCase()}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Records amount",
      dataIndex: "qtdRows",
      key: "qtdRows",
    },
    {
      title: "Policies amount",
      dataIndex: "policiesAmount",
      key: "policiesAmount",
      render: (_, { policies }) => policies?.length ?? 0,
    },
    {
      title: "User",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (_, { updatedBy }) => (
        <Space direction="horizontal">
          <div className={styles.circle}>
            <p> {updatedBy?.substring(0, 1).toUpperCase()} </p>
          </div>
        </Space>
      ),
      align: "center",
      filteredValue: selectedUser,
      onFilter: (value: string | number | boolean, record: datasetDb) =>
        String(record.updatedBy)
          .toLowerCase()
          .includes(String(value).toLowerCase()),
    },

    {
      title: "Last update",
      dataIndex: "updatedAt",
      key: "updateddAt",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        formatDateUnix(a.updatedAt!) - formatDateUnix(b.updatedAt!),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Tooltip title="Preview">
            <Button
              type="link"
              size="small"
              shape="circle"
              icon={<BarChartOutlined />}
              onClick={(e) => {
                dispatch(resetDatasetProps())
                navigate(`/dataset-preview/${record.datasetId}`)
              }}
            />
          </Tooltip>

          <Tooltip title="Setup">
            <Button
              type="link"
              size="small"
              shape="circle"
              icon={<SettingOutlined />}
              onClick={(e) => {
                dispatch(resetDatasetProps())
                navigate(`/dataset-setup/${record.datasetId}`)
              }}
            />
          </Tooltip>

          <Tooltip title="Delete">
            <Button
              type="link"
              size="small"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={(e) => {
                if ((record.policies?.length ?? 0) > 0) {
                  modalError({
                    name: record.datasetName!,
                    type: "containPolicy",
                    okAction: () => {
                      return false
                    },
                    closeAction: () => {},
                  })
                } else {
                  deleteById({
                    body: { id: record.datasetId!, file: record.fileName! },
                    title: `Are you sure you want to delete ${record.datasetName!}`,
                    content: `You will not be able to recover file ${record.fileName!.replace(
                      record.datasetId! + "_",
                      ""
                    )}`,
                    successMesssage: `${record.datasetName!} was successfully deleted.`,
                    action: callDeleteDatasetService,
                    onCloseMessage: getData,
                  })
                }
              }}
            />
          </Tooltip>
        </Space>
      ),
      align: "center",
    },
  ]

  const getData = async () => {
    setLoading(true)
    try {
      const response = await callGetDatasetList()
      setData(response!.data)
      setUserList(distinctOptionsList(response!.userList))
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    dispatch(setShowModal(false))
  }, [])

  useEffect(() => {
    getData()
  }, [showModal])

  const newUpload = () => {
    dispatch(setShowModal(true))

    const newDataset: datasetDb = {
      datasetId: uuid.v1(),
      uploadBy: null,
      uploadAt: null,
      updatedBy: null,
      updatedAt: null,
      datasetName: null,
      fileName: null,
      storagePath: null,
      policies: null,
      description: null,
      size: null,
      qtdRows: null,
      sample: null,
      featureList: [],
      providerBookList: [],
      providerFeatureList: [],
    }
    dispatch(setDatasetProps(newDataset))
  }

  return (
    <div className="container-page">
      <div className={styles.table_container}>
        <div className={styles.button_container}>
          <Row>
            <Col flex={3} className={styles.container_normal}>
              <Search
                placeholder="Search dataset name"
                allowClear
                onSearch={
                  //datasetSearch
                  (value) => setSearchedName(value)
                }
                style={{ width: "60%", padding: "0px 30px 0px 0px" }}
                size="large"
              />

              <Select
                mode="multiple"
                allowClear
                style={{ width: "60%" }}
                placeholder="All users "
                onChange={(value: string[]) => setSelectedUser(value)}
                options={userList}
                size="large"
              />
            </Col>
            <Col flex={2} className={styles.container_reverse}>
              <Button size="large" type="primary" onClick={newUpload}>
                Upload New Dataset
              </Button>
            </Col>
          </Row>
        </div>
        <div>
          <Table
            className={styles.table}
            bordered
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.datasetId!}
            expandable={{
              expandedRowRender: (record) => (
                <>
                  <p>
                    <strong>Created by: </strong> {record.uploadBy} at{" "}
                    {record.uploadAt!.substring(0, 10)}
                  </p>
                  <p style={{ margin: 0 }}>
                    <strong>Description: </strong>

                    {(record.description?.length ?? 0) > 0
                      ? record.description
                      : "No description available."}
                  </p>
                  <p>
                    <strong>Policies: </strong>
                    {record.policies?.map((policy) => {
                      return (
                        <Tag color="geekblue" key={policy.id}>
                          <Link
                            to={`/policy-edit/${policy.id}`}
                            rel="noopener noreferrer"
                            onClick={() => {
                              dispatch(setIsPolicySaved(false))
                              dispatch(resetActiveStep())
                              dispatch(resetNewPolicy())
                              dispatch(resetNode())
                            }}
                          >
                            {policy.name.toUpperCase()}
                          </Link>
                        </Tag>
                      )
                    })}
                  </p>
                  <p>
                    <strong>Connections: </strong>
                  </p>
                  <div className={styles.connection_table}>
                    <ConnectionBookTable
                      connectionBookList={record.providerBookList}
                      scrollBody={{}}
                    />
                  </div>
                </>
              ),
            }}
            size="small"
            loading={loading}
            pagination={{ pageSize: 10 }}
          />
        </div>
      </div>

      <>
        <UploadDataset />
      </>
    </div>
  )
}

export default Dataset
