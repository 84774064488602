//amplify
import { API } from "aws-amplify"

export const getPolicyById = async (id: string) => {
  try {
    const response = await API.get("policiesAPI", `/policies/${id}`, {})
    const responseBody = JSON.parse(response.body)[0]
    return responseBody
  } catch (error: any) {
    return error.message
  }
}
