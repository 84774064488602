//css
import styles from "./choose-dataset.module.css"

//react
import { useEffect, useState } from "react"

//react-router-dom
import { Link } from "react-router-dom"

//redux
import { useAppDispatch, useAppSelector } from "../../redux-store/hooks"
import { setActiveStep } from "../../redux-store/new-policy-reducer/active-step-slice"
import { submitDataset } from "../../redux-store/new-policy-reducer/new-policy-slice"
import { setShowModal } from "../../redux-store/upload-dataset/upload-dataset-slice"
import { setDatasetProps } from "../../redux-store/upload-dataset/dataset-setup-slice"

//antd
import { Alert, Button, Form, Select, Space, Spin, Tag } from "antd"

//services
import { getDatasetList } from "../../services/dataset-database-service"

//components
import UploadDataset from "../upload-modal/upload-dataset"
import { modalError } from "../dataset/modal-error"

//utils
import {
  checkDatasetLayout,
  checkDatasetFeatureList,
} from "../../functions/dataset"

//types
import { datasetDb, providerFeatureList } from "../../types"

const uuid = require("uuid")

type Props = {
  componentId: number
}

const ChooseDataset = ({ componentId }: Props) => {
  const dispatch = useAppDispatch()

  const { datasetName, datasetId, datasetPath, sample, cascaderFeatureList } =
    useAppSelector((state) => state.newPolicy)
  const { edges } = useAppSelector((state) => state.node)
  const { showModal } = useAppSelector((state) => state.uploadModal)

  const [datasetList, setDatasetList] = useState([])
  const [loading, setLoading] = useState<boolean>(true)
  const [providerFeatureList, setProviderFeatureList] =
    useState<providerFeatureList[]>(cascaderFeatureList)

  //get dataset list
  const getData = async () => {
    setLoading(true)
    try {
      const response = await getDatasetList()
      setDatasetList(response)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData()
    setProviderFeatureList(cascaderFeatureList)
  }, [showModal])

  const onFinish = () => {
    dispatch(setActiveStep(componentId + 1))
  }

  const confirmChangeDataset = (selectedOptions: any) => {
    const checkDataset: boolean = checkDatasetFeatureList({
      //checkDatasetLayout({
      providerFeatureListSelected: cascaderFeatureList,
      providerFeatureListAvailable: selectedOptions.providerFeatureList,
      //sampleSelected: sample,
      //sampleAvailable: selectedOptions.sample,
    })

    if (checkDataset) {
      dispatch(
        submitDataset({
          datasetName: selectedOptions.datasetName,
          datasetId: selectedOptions.datasetId,
          datasetPath: selectedOptions.storagePath,
          cascaderFeatureList: selectedOptions.providerFeatureList,
          sample: selectedOptions.sample,
        })
      )
      setProviderFeatureList(selectedOptions.providerFeatureList)
    } else {
      setProviderFeatureList([])
      modalError({
        name: "",
        type: "checkDataset",
        okAction: () => {
          dispatch(
            submitDataset({
              datasetName: datasetName,
              datasetId: datasetId,
              datasetPath: datasetPath,
              cascaderFeatureList: cascaderFeatureList,
              sample: sample,
            })
          )
          return false
        },
        closeAction: () => {},
      })
    }
  }

  const newUpload = () => {
    dispatch(setShowModal(true))

    const newDataset: datasetDb = {
      datasetId: uuid.v1(),
      uploadBy: null,
      uploadAt: null,
      updatedBy: null,
      updatedAt: null,
      datasetName: null,
      fileName: null,
      storagePath: null,
      policies: null,
      description: null,
      size: null,
      qtdRows: null,
      sample: null,
      featureList: [],
      providerBookList: [],
      providerFeatureList: [],
    }
    dispatch(setDatasetProps(newDataset))
  }

  return (
    <div className={styles.container_form}>
      {/*showBtn && <h5> Dataset </h5>*/}

      <Spin size="large" tip="Loading..." spinning={loading}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          requiredMark={false}
          initialValues={{
            datasetName: datasetName,
          }}
        >
          <Form.Item
            label="Dataset"
            name="datasetName"
            rules={[
              {
                required: true,
                message: "Please select a dataset or upload a new one",
              },
            ]}
          >
            <Select
              loading={loading}
              showSearch
              placeholder="Search by dataset name"
              allowClear
              size="large"
              optionFilterProp="children"
              options={datasetList}
              fieldNames={{ label: "datasetName", value: "datasetId" }}
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onChange={(value: any, selectedOptions: any) => {
                if (selectedOptions.providerFeatureList.length > 0) {
                  if (edges.length > 0) {
                    confirmChangeDataset(selectedOptions)
                  } else {
                    dispatch(
                      submitDataset({
                        datasetName: selectedOptions.datasetName,
                        datasetId: selectedOptions.datasetId,
                        datasetPath: selectedOptions.storagePath,
                        cascaderFeatureList:
                          selectedOptions.providerFeatureList,
                        sample: selectedOptions.sample,
                      })
                    )
                    setProviderFeatureList(selectedOptions.providerFeatureList)
                  }
                } else {
                  setProviderFeatureList([])
                  modalError({
                    name: "",
                    type: "setupDataset",
                    okAction: () => {
                      return false
                    },
                    closeAction: () => {},
                  })
                }
              }}
            />
          </Form.Item>

          <Form.Item>
            <span>If you want to upload a new dataset,</span>
            <Button
              type="link"
              onClick={newUpload}
              className={styles.link_button}
            >
              click here.
            </Button>
          </Form.Item>

          {providerFeatureList.length > 0 && (
            <div className={styles.sample_table}>
              <h6>Available features: </h6>
              <Alert
                message={
                  <p>
                    This list contains only features that were assigned to a
                    data source in the dataset feature setup step. If you are
                    missing any feature please check it's status at the{" "}
                    <Link to={`/dataset-setup/${datasetId}`} target="_blank">
                      dataset setup page
                    </Link>
                    .
                  </p>
                }
                showIcon
                closable
                type="warning"
                className={styles.error_alert}
              />
              {providerFeatureList?.map((prov) => {
                let color = "geekblue"

                if (prov.label) {
                  return prov.children.map((feat) => {
                    return (
                      <Tag
                        color={color}
                        key={feat.label}
                        style={{ marginTop: "4px", marginBottom: "4px" }}
                      >
                        {feat.label.toUpperCase()}
                      </Tag>
                    )
                  })
                }
              })}
            </div>
          )}

            <div className={styles.container_button}>
              <Space size="large">
                <Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
                    Continue
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    size="large"
                    onClick={() => dispatch(setActiveStep(componentId - 1))}
                  >
                    Back
                  </Button>
                </Form.Item>
              </Space>
            </div>
        </Form>
      </Spin>

      <>
        <UploadDataset />
      </>
    </div>
  )
}

export default ChooseDataset
