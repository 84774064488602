//services
import { deleteDatasetById, getDatasetById, getDatasetList } from "."
import { removeFile } from "../storage-service"

const uuid = require("uuid")

//Remove dataset by Id
export const callDeleteDatasetService = async ({
  id,
  file,
}: {
  id: string
  file: string
}) => {
  try {
    //remove dataset from db
    const response = await deleteDatasetById(id)

    //remove file
    const responseS3 = await removeFile(file)

    return true
  } catch (error: unknown) {
    if (error instanceof Error) {
      const saveError = new Error(error.message)
      console.log("Fail to remove id:", id, saveError)
    }
    return false
  }
}

// Get dataset list
export const callGetDatasetList = async () => {
  try {
    //get data
    const response = JSON.parse(JSON.stringify(await getDatasetList()))

    const options = []
    for (let i in response) {
      let username: string | null | undefined
      if (response[Number(i)].updatedBy!.includes("@")) {
        const index: number | null | undefined =
          response[Number(i)].updatedBy!.indexOf("@")
        username = response[Number(i)].updatedBy!.substring(0, index)
      } else {
        username = response[Number(i)].updatedBy
      }
      options.push({
        label: username!.toLowerCase(),
        value: response[Number(i)].updatedBy,
      })
    }

    return { data: response, userList: options }
  } catch (error: unknown) {
    if (error instanceof Error) {
      const saveError = new Error(error.message)
      console.log(saveError)
    }
  }
}

// Get dataset list
export const callGetDatasetId = async (id: string) => {
  try {
    //get data
    const response = JSON.parse(JSON.stringify(await getDatasetById(id)))

    const datasetList = {
      //key: response.datasetId!,
      datasetId: response.datasetId,
      uploadBy: response.uploadBy,
      uploadAt: response.uploadAt,
      updatedBy: response.updatedBy,
      updatedAt: response.updatedAt,
      datasetName: response.datasetName,
      fileName: response.fileName,
      storagePath: response.storagePath,
      policies: response.policies,
      policiesAmount: response.policies?.length ?? 0,
      dataProviders: response.providerBookList,
      description: response.description,
      size: response.size,
      qtdRows: response.qtdRows,
      sample: response.sample,
      featureList: response.featureList,
      cascaderFeatureList: response.providerFeatureList,
    }

    return datasetList
  } catch (error: unknown) {
    if (error instanceof Error) {
      const saveError = new Error(error.message)
      console.log(saveError)
    }
  }
}
