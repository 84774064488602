//services
import { BacktestPolicy } from "../../services/policy-database-service/backtest-policy"

//type
import { INodeProps, INodeRule } from "../../types"

//utils & functions
import { checkPolicyOnProcess } from "."

let alertType:
  | "errorDefault"
  | "errorIncomplete"
  | "errorFinalNode"
  | "successSave"
  | "successProccess" = "errorDefault"
let attribute: string | null = null
let isSuccess: boolean = false

type Props = {
  policyId: string
  policyName: string
  nodesProps: INodeProps
  rules: { [nodeId: string]: INodeRule }
  dispatchRules: (rules: { [nodeId: string]: INodeRule }) => void
}

export const processPolicy = async ({
  policyId,
  policyName,
  nodesProps,
  rules,
  dispatchRules,
}: Props) => {
  //validate policy nodes
  const validatePolicy = checkPolicyOnProcess({
    nodesProps: nodesProps,
    rules: rules,
  })
  if (validatePolicy.status) {
    //process policy

    try {
      const response = await BacktestPolicy({
        policyId: policyId,
      })

      dispatchRules(response.nodes.nodeRules)

      alertType = "successProccess"
      attribute = policyName
      isSuccess = true
    } catch (error) {
      console.log(error)
      alertType = "errorDefault"
      attribute = null
      isSuccess = false
    }
  } else {
    alertType = "errorFinalNode"
    attribute = String(validatePolicy.qtdError)
    isSuccess = false
  }

  return {
    alertType: alertType,
    attribute: attribute,
    success: isSuccess,
  }
}
